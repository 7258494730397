/* eslint-disable default-case */
import React from 'react';
import { DashboardContext } from './revenueUpliftContexts';
import { METRIC_TO_GRAPH_TYPE, MONTH_NAMES, WEEKDAY_NAMES } from './performanceConstants';
import { MONTH, QUARTER, WEEK, YEAR, getRanges, parseDateAsLocal } from './relativeDates';
import { DateType } from '../../../util/format';
import { getQuarterOfDate } from '../../../../utils/date-handling';

// This regular expression allows:
// - A prefix (type) followed by an underscore.
// - A date with format: YYYY-M or YYYY-MM, optionally followed by -D or -DD.
const DATE_REGEX = /^(.+)_(\d{4}-\d{1,2}(?:-\d{1,2})?)$/;

const PeriodType = {
  CURRENT: 'current',
  LAST: 'last',
  STLY: 'stly',
};

const lastDayOfMonth = date => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

const relativeToday = relativeDates => {
  const today = new Date(relativeDates.yesterday);

  today.setDate(today.getDate() + 1);

  return today;
};

/**
 * Accumulates values by date and type extracted from the key.
 * The key must follow the format "type_YYYY-M" or "type_YYYY-MM" for monthly data,
 * or "type_YYYY-M-D" or "type_YYYY-MM-DD" for daily data.
 * An optional dateFilter function can be provided to exclude certain dates.
 *
 * @param {Array<Object>} data - Array of objects with keys in format type_date
 * @param {Function} [dateFilter=() => true] - A predicate function that receives a date string
 * and returns true if it should be included.
 * @returns {Object} Object with the shape { "date": { type1: total, type2: total, ... } }
 */
const accumulateByDateAndType = (data, dateFilter = () => true) =>
  data.reduce((acc, item) => {
    Object.entries(item).forEach(([key, value]) => {
      const match = key.match(DATE_REGEX);
      if (match) {
        const [, type, date] = match;
        if (!dateFilter(date)) {
          return;
        }
        if (!acc[date]) {
          acc[date] = {};
        }
        if (typeof value === 'number') {
          acc[date][type] = (acc[date][type] || 0) + value;
        }
      }
    });
    return acc;
  }, {});

/**
 * Parses a date key string based on the specified granularity.
 * For 'day' granularity, expects "YYYY-M-D" (or "YYYY-MM-DD") and returns a Date with that day.
 * For 'month' granularity, expects "YYYY-M" (or "YYYY-MM") and returns a Date set to the first day of that month.
 *
 * @param {string} dateKey - The date key string.
 * @param {string} granularity - The granularity level ('day' or 'month').
 * @returns {Date} The parsed Date object.
 */
const parseDateKey = (dateKey, granularity) => {
  const parts = dateKey.split('-').map(Number);
  return granularity === 'day' ? new Date(parts[0], parts[1] - 1, parts[2] || 1) : new Date(parts[0], parts[1] - 1, 1);
};

/**
 * Creates a date filter function that checks if a date (parsed from a date key) falls within the specified range.
 *
 * @param {Date} start - The start date of the range.
 * @param {Date} end - The end date of the range.
 * @param {string} granularity - The granularity for parsing the date key ('day' or 'month').
 * @returns {Function} A function that takes a date key and returns true
 * if the corresponding date is between start and end.
 */
const createDateFilter = (start, end, granularity) => dateKey => {
  const d = parseDateKey(dateKey, granularity);
  return d >= start && d <= end;
};

const determineWeekRange = (accumulated, relativeDates) => {
  const { weekStart, weekEnd, weekLastStart, weekLastEnd, stlyWeekStart, stlyWeekEnd } = relativeDates;

  const accumulatedDates = Object.keys(accumulated)
    .filter(key => key.split('-').length === 3)
    .map(key => parseDateAsLocal(key));

  if (accumulatedDates.some(date => date >= weekStart && date <= weekEnd)) {
    return { start: weekStart, end: weekEnd };
  }

  if (accumulatedDates.some(date => date >= weekLastStart && date <= weekLastEnd)) {
    return { start: weekLastStart, end: weekLastEnd };
  }

  if (accumulatedDates.some(date => date >= stlyWeekStart && date <= stlyWeekEnd)) {
    return { start: stlyWeekStart, end: stlyWeekEnd };
  }

  return { start: weekStart, end: weekEnd };
};

/**
 * Converts accumulated daily data for a WEEK period into series.
 * Aggregates amounts by weekday (Monday to Sunday) for dates within the week determined
 * from the minimum accumulated key date.
 *
 * @param {Object} accumulated - The accumulated data with keys in "YYYY-M-D" format.
 * @param {Object} relativeDates - The relative dates object.
 * @returns {Array} Array of series objects.
 */
const convertDaySeriesForWeek = (accumulated, relativeDates) => {
  const { start: weekStart, end: weekEnd } = determineWeekRange(accumulated, relativeDates);

  // Aggregate amounts by type for dates within that week.
  const aggregatedByType = {};
  Object.entries(accumulated).forEach(([key, value]) => {
    const forecastDate = parseDateAsLocal(key);
    if (forecastDate >= weekStart && forecastDate <= weekEnd) {
      Object.entries(value).forEach(([type, amount]) => {
        if (!aggregatedByType[type]) {
          aggregatedByType[type] = Array(7).fill(0);
        }
        // Adjust getDay so Monday=0, Sunday=6.
        const weekdayIndex = (forecastDate.getDay() + 6) % 7;
        aggregatedByType[type][weekdayIndex] += amount;
      });
    }
  });

  // Build series per type using weekday names as x values.
  const series = [];
  Object.entries(aggregatedByType).forEach(([type, weekdayData]) => {
    const data = [];
    for (let i = 0; i < 7; i++) {
      const currentDayDate = new Date(weekStart.getTime() + i * 86400000);
      data.push({
        x: WEEKDAY_NAMES[i],
        y: weekdayData[i] || 0,
        dashed: currentDayDate > relativeDates.yesterday,
        date: currentDayDate,
      });
    }
    series.push({ type, data });
  });
  return series;
};

/**
 * Converts accumulated daily data (non-WEEK period, e.g. MONTH) into series.
 * Groups data by year-month and then builds a series per type with 31 day values.
 *
 * @param {Object} accumulated - The accumulated data with keys in "YYYY-M-D" format.
 * @param {Object} relativeDates - The relative dates object.
 * @returns {Array} Array of series objects.
 */
const convertDaySeriesForNonWeek = (accumulated, relativeDates) => {
  const groups = {};
  Object.entries(accumulated).forEach(([key, value]) => {
    const parts = key.split('-');
    if (parts.length === 3) {
      const [year, month, day] = parts;
      const groupKey = `${year}-${parseInt(month, 10)}`;
      if (!groups[groupKey]) {
        groups[groupKey] = {};
      }
      Object.entries(value).forEach(([type, amount]) => {
        if (!groups[groupKey][type]) {
          groups[groupKey][type] = {};
        }
        groups[groupKey][type][parseInt(day, 10)] = amount;
      });
    }
  });

  const series = [];
  Object.entries(groups).forEach(([groupKey, typesObj]) => {
    const [year, month] = groupKey.split('-');
    const monthNumber = parseInt(month, 10);
    const lastDay = new Date(parseInt(year, 10), monthNumber, 0).getDate();
    Object.entries(typesObj).forEach(([type, dayData]) => {
      const data = [];
      for (let day = 1; day <= lastDay; day++) {
        const fullDate = new Date(parseInt(year, 10), monthNumber - 1, day);
        data.push({
          x: day,
          y: dayData[day] || 0,
          dashed: fullDate > relativeDates.yesterday,
          date: fullDate,
        });
      }
      series.push({ type, data });
    });
  });
  return series;
};

/**
 * Converts accumulated monthly data for a QUARTER period into series.
 * It aggregates data for the current quarter (from yesterday), previous quarter,
 * and the same quarter last year, then remaps the original month numbers to a fixed 3-label x-axis.
 *
 * @param {Object} accumulated - The accumulated data with keys in "YYYY-M" format.
 * @param {Object} relativeDates - The relative dates object.
 * @returns {Array} Array of series objects.
 */
const convertMonthSeriesForQuarter = (accumulated, relativeDates) => {
  const series = [];
  const currentYear = relativeDates.yesterday.getFullYear();
  const currentQuarter = getQuarterOfDate(relativeDates.yesterday); // returns 1-4
  const currentQuarterMonths = [
    (currentQuarter - 1) * 3 + 1,
    (currentQuarter - 1) * 3 + 2,
    (currentQuarter - 1) * 3 + 3,
  ];
  // x-axis labels are fixed based on the current quarter's positions (always 3 labels)
  const quarterXLabels = currentQuarterMonths.map(m => MONTH_NAMES[m - 1]);

  // Determine previous quarter info.
  let prevQuarter;
  let prevYear;
  if (currentQuarter === 1) {
    prevQuarter = 4;
    prevYear = currentYear - 1;
  } else {
    prevQuarter = currentQuarter - 1;
    prevYear = currentYear;
  }
  const prevQuarterMonths = [(prevQuarter - 1) * 3 + 1, (prevQuarter - 1) * 3 + 2, (prevQuarter - 1) * 3 + 3];

  // Aggregate data for current quarter (only keys for currentYear with months in currentQuarterMonths)
  const aggregatedCurrent = {};
  Object.entries(accumulated).forEach(([key, value]) => {
    const parts = key.split('-');
    if (parts.length === 2) {
      const [yearStr, monthStr] = parts;
      const year = parseInt(yearStr, 10);
      const month = parseInt(monthStr, 10);
      if (year === currentYear && currentQuarterMonths.includes(month)) {
        Object.entries(value).forEach(([type, amount]) => {
          if (!aggregatedCurrent[type]) {
            aggregatedCurrent[type] = {};
          }
          aggregatedCurrent[type][month] = (aggregatedCurrent[type][month] || 0) + amount;
        });
      }
    }
  });

  // Aggregate data for previous quarter (keys for prevYear with months in prevQuarterMonths)
  const aggregatedPrev = {};
  Object.entries(accumulated).forEach(([key, value]) => {
    const parts = key.split('-');
    if (parts.length === 2) {
      const [yearStr, monthStr] = parts;
      const year = parseInt(yearStr, 10);
      const month = parseInt(monthStr, 10);
      if (year === prevYear && prevQuarterMonths.includes(month)) {
        Object.entries(value).forEach(([type, amount]) => {
          if (!aggregatedPrev[type]) {
            aggregatedPrev[type] = {};
          }
          aggregatedPrev[type][month] = (aggregatedPrev[type][month] || 0) + amount;
        });
      }
    }
  });

  // Aggregate data for the same quarter last year (using currentQuarterMonths)
  const aggregatedLastYear = {};
  Object.entries(accumulated).forEach(([key, value]) => {
    const parts = key.split('-');
    if (parts.length === 2) {
      const [yearStr, monthStr] = parts;
      const year = parseInt(yearStr, 10);
      const month = parseInt(monthStr, 10);
      if (year === currentYear - 1 && currentQuarterMonths.includes(month)) {
        Object.entries(value).forEach(([type, amount]) => {
          if (!aggregatedLastYear[type]) {
            aggregatedLastYear[type] = {};
          }
          aggregatedLastYear[type][month] = (aggregatedLastYear[type][month] || 0) + amount;
        });
      }
    }
  });

  // Build series for current quarter by remapping each original month to quarterXLabels.
  Object.entries(aggregatedCurrent).forEach(([type, monthData]) => {
    const data = [];
    currentQuarterMonths.forEach((origMonth, idx) => {
      const fullDate = new Date(currentYear, origMonth - 1, 1);
      data.push({
        x: quarterXLabels[idx],
        y: monthData[origMonth] || 0,
        dashed: fullDate >= relativeDates.yesterday,
        date: fullDate,
      });
    });
    series.push({ legend: 'Current Quarter', type, data });
  });

  // Build series for previous quarter by remapping its months to current quarter's x positions.
  Object.entries(aggregatedPrev).forEach(([type, monthData]) => {
    const data = [];
    prevQuarterMonths.forEach((origMonth, idx) => {
      const fullDate = new Date(prevYear, origMonth - 1, 1);
      data.push({
        x: quarterXLabels[idx],
        y: monthData[origMonth] || 0,
        dashed: fullDate >= relativeDates.yesterday,
        tooltip: MONTH_NAMES[origMonth - 1],
        date: fullDate,
      });
    });
    series.push({ legend: 'Previous Quarter', type, data });
  });

  // Build series for same quarter last year by remapping its months similarly.
  Object.entries(aggregatedLastYear).forEach(([type, monthData]) => {
    const data = [];
    currentQuarterMonths.forEach((origMonth, idx) => {
      const fullDate = new Date(currentYear - 1, origMonth - 1, 1);
      data.push({
        x: quarterXLabels[idx],
        y: monthData[origMonth] || 0,
        dashed: fullDate >= relativeDates.yesterday,
        date: fullDate,
      });
    });
    series.push({ legend: 'Same Quarter Last Year', type, data });
  });
  return series;
};

/**
 * Converts accumulated monthly data for non-QUARTER periods (e.g. YEAR) into series.
 * It groups data by year and then builds a series per type with 12 month values.
 *
 * @param {Object} accumulated - The accumulated data with keys in "YYYY-M" format.
 * @param {Object} relativeDates - The relative dates object.
 * @returns {Array} Array of series objects.
 */
const convertMonthSeriesForNonQuarter = (accumulated, relativeDates) => {
  const groups = {};
  Object.entries(accumulated).forEach(([key, value]) => {
    const parts = key.split('-');
    if (parts.length === 2) {
      const [year, month] = parts;
      if (!groups[year]) {
        groups[year] = {};
      }
      Object.entries(value).forEach(([type, amount]) => {
        if (!groups[year][type]) {
          groups[year][type] = {};
        }
        groups[year][type][parseInt(month, 10)] = amount;
      });
    }
  });
  const series = [];
  Object.entries(groups).forEach(([year, typesObj]) => {
    Object.entries(typesObj).forEach(([type, monthData]) => {
      const data = [];
      for (let m = 1; m <= 12; m++) {
        const fullDate = new Date(parseInt(year, 10), m - 1, 1);
        data.push({
          x: MONTH_NAMES[m - 1],
          y: monthData[m] || 0,
          dashed: fullDate >= relativeDates.yesterday,
          date: fullDate,
        });
      }
      series.push({ legend: year, type, data });
    });
  });
  return series;
};

/**
 * Main function to convert accumulated data into series for graphing.
 * It delegates the conversion to smaller helper functions based on granularity and period.
 *
 * @param {Object} accumulated - The accumulated data.
 * @param {string} granularity - "day" or "month".
 * @param {string} graphType - The type (e.g., "revenue" or "demand") to filter on.
 * @param {string} period - The period (e.g., WEEK, QUARTER, etc.).
 * @param {Object} relativeDates - The relative dates object.
 * @returns {Array} Filtered series array (only series of the specified graphType).
 */
const convertAccumulatedToSeries = (accumulated, granularity, graphType, period, relativeDates) => {
  let seriesArray = [];
  if (granularity === 'day') {
    seriesArray =
      period === WEEK
        ? convertDaySeriesForWeek(accumulated, relativeDates)
        : convertDaySeriesForNonWeek(accumulated, relativeDates);
  } else if (granularity === 'month') {
    seriesArray =
      period === QUARTER
        ? convertMonthSeriesForQuarter(accumulated, relativeDates)
        : convertMonthSeriesForNonQuarter(accumulated, relativeDates);
  }

  // Fallback: if no series were generated, return a default series with zero values.
  if (seriesArray.length === 0) {
    let data = [];
    if (granularity === 'day') {
      data =
        period === WEEK
          ? Array.from({ length: 7 }, (_, i) => ({ x: WEEKDAY_NAMES[i], y: 0 }))
          : Array.from({ length: 31 }, (_, i) => ({ x: i + 1, y: 0 }));
    } else if (granularity === 'month') {
      data = Array.from({ length: 12 }, (_, i) => ({ x: MONTH_NAMES[i], y: 0 }));
    }
    seriesArray.push({ type: graphType, data });
  }
  // Filter the series to only return those matching the specified graphType.
  return seriesArray.filter(series => series.type.includes(graphType));
};

const combineAccumulatedData = (accumulatedHistoryData, accumulatedForecastData, operation) => {
  const combinedAccumulatedData = { ...accumulatedHistoryData, ...accumulatedForecastData };

  Object.keys(combinedAccumulatedData).forEach(key => {
    if (
      Object.prototype.hasOwnProperty.call(accumulatedHistoryData, key) &&
      Object.prototype.hasOwnProperty.call(accumulatedForecastData, key)
    ) {
      combinedAccumulatedData[key] = operation(accumulatedHistoryData[key], accumulatedForecastData[key]);
    }
  });

  return combinedAccumulatedData;
};

const combine = (history, forecast) => {
  const combinedData = {};

  Object.entries(forecast).forEach(([key, value]) => {
    combinedData[key] = value + (history[key] || 0);
    combinedData[`${key}_forecast`] = value;
  });

  return combinedData;
};

/**
 * Returns the legend string for the specified series type, period.
 *
 * @param {PeriodType} seriesType - The series type (current, previous, stly).
 * @param {String} period - The selected period (WEEK, MONTH, QUARTER, or YEAR).
 * @param {Object} relativeDates - The relative dates object containing weekStart, weekEnd, etc.
 * @param {Function} dateFormatter - Function that can format a single date or date range
 * @returns {String} The legend string.
 */
const getLineGraphLegend = ({ seriesType, period, relativeDates, dateFormatter }) => {
  let legendDateString = '';

  switch (seriesType) {
    case PeriodType.CURRENT:
      switch (period) {
        case WEEK: {
          legendDateString = dateFormatter(relativeDates.weekStart, { endDate: relativeDates.weekEnd });
          break;
        }
        case MONTH: {
          legendDateString = dateFormatter(relativeDates.monthStart, { type: DateType.MONTH });
          break;
        }
        case QUARTER: {
          const startMonth = dateFormatter(relativeDates.quarterStart, { type: DateType.MONTH });
          const endMonth = dateFormatter(relativeDates.quarterEnd, { type: DateType.QUARTER });
          legendDateString = `${startMonth} - ${endMonth}`;
          break;
        }
        case YEAR: {
          legendDateString = dateFormatter(relativeDates.yearStart, { type: DateType.YEAR });
          break;
        }
      }
      break;
    case PeriodType.LAST:
      switch (period) {
        case WEEK: {
          legendDateString = dateFormatter(relativeDates.weekLastStart, { endDate: relativeDates.weekLastEnd });
          break;
        }
        case MONTH: {
          legendDateString = dateFormatter(relativeDates.monthLastStart, { type: DateType.MONTH });
          break;
        }
        case QUARTER: {
          const startMonth = dateFormatter(relativeDates.quarterLastStart, { type: DateType.MONTH });
          const endMonth = dateFormatter(relativeDates.quarterLastEnd, { type: DateType.QUARTER });
          legendDateString = `${startMonth} - ${endMonth}`;
          break;
        }
        case YEAR: {
          legendDateString = dateFormatter(relativeDates.stlyYearStart, { type: DateType.YEAR });
          break;
        }
      }
      break;
    case PeriodType.STLY:
      switch (period) {
        case WEEK: {
          legendDateString = dateFormatter(relativeDates.stlyWeekStart, { endDate: relativeDates.stlyWeekEnd });
          break;
        }
        case MONTH: {
          legendDateString = dateFormatter(relativeDates.stlyMonthStart, { type: DateType.MONTH });
          break;
        }
        case QUARTER: {
          const startMonth = dateFormatter(relativeDates.stlyQuarterStart, { type: DateType.MONTH });
          const endMonth = dateFormatter(relativeDates.stlyQuarterEnd, { type: DateType.QUARTER });
          legendDateString = `${startMonth} - ${endMonth}`;
          break;
        }
      }
  }
  return `${legendDateString} Actuals`;
};

const getLineGraphData = (
  period,
  metric,
  dailyHistory,
  dailyForecast,
  monthlyHistory,
  monthlyForecast,
  relativeDates,
  dateFormatter,
) => {
  const { granularity, currentRange, lastRange, stlyRange } = getRanges(period, relativeDates);

  // For current period, combine forecast and history; for others, use history only
  const currentHistoryData = period === WEEK || period === MONTH ? dailyHistory : monthlyHistory;
  const currentForecastData = period === WEEK || period === MONTH ? dailyForecast : monthlyForecast;
  const lastData = period === WEEK || period === MONTH ? dailyHistory : monthlyHistory;
  const stlyData = period === WEEK || period === MONTH ? dailyHistory : monthlyHistory;

  const currentHistoryAccumulated = accumulateByDateAndType(
    currentHistoryData,
    createDateFilter(currentRange.start, currentRange.end, granularity),
  );
  const currentForecastAccumulated = accumulateByDateAndType(
    currentForecastData,
    createDateFilter(currentRange.start, currentRange.end, granularity),
  );
  const currentAccumulated = combineAccumulatedData(currentHistoryAccumulated, currentForecastAccumulated, combine);

  const lastAccumulated = accumulateByDateAndType(
    lastData,
    createDateFilter(lastRange.start, lastRange.end, granularity),
  );
  const stlyAccumulated = stlyRange
    ? accumulateByDateAndType(stlyData, createDateFilter(stlyRange.start, stlyRange.end, granularity))
    : {};

  const graphType = METRIC_TO_GRAPH_TYPE[metric];

  const currentSeries = convertAccumulatedToSeries(currentAccumulated, granularity, graphType, period, relativeDates);
  const lastSeries = convertAccumulatedToSeries(lastAccumulated, granularity, graphType, period, relativeDates);
  const stlySeries = stlyRange
    ? convertAccumulatedToSeries(stlyAccumulated, granularity, graphType, period, relativeDates)
    : [];

  const finalSeries = [];
  if (currentSeries.length > 0) {
    finalSeries.push({
      periodType: PeriodType.CURRENT,
      ...currentSeries[0],
      legend: getLineGraphLegend({
        seriesType: PeriodType.CURRENT,
        period,
        relativeDates,
        dateFormatter,
      }),
    });
  }

  // This is a bit of a kludge...
  //
  // For monthly data there could be an overlap of history and forecast.
  // If so, then the other series will contain 1 month of non-zero "y" value which represents the portion of
  // the month that is forecast. Note that the currentSeries[0] "y" value is history and forecast combined.
  // This extra attribute will be stored in the overlapping month as "y_forecast" and will be used in
  // getBarChartValues()
  const forecastData =
    currentSeries.length > 1 && currentSeries[1].type.includes('_forecast') ? currentSeries[1].data : null;
  if (forecastData) {
    Object.entries(forecastData).forEach(([key, value]) => {
      if (value.y > 0) {
        currentSeries[0].data[key].y_forecast = value.y;
      }
    });
  }
  // End of kludge

  if (lastSeries.length > 0) {
    finalSeries.push({
      periodType: PeriodType.LAST,
      ...lastSeries[0],
      legend: getLineGraphLegend({
        seriesType: PeriodType.LAST,
        period,
        relativeDates,
        dateFormatter,
      }),
    });
  }

  if (stlySeries.length > 0 && period !== YEAR) {
    finalSeries.push({
      periodType: PeriodType.STLY,
      ...stlySeries[0],
      legend: getLineGraphLegend({
        seriesType: PeriodType.STLY,
        period,
        relativeDates,
        dateFormatter,
      }),
    });
  }

  return finalSeries;
};

const getBarLabels = (periodType, period, barChartDate, relativeDates, dateFormatter) => {
  const emptyLabels = { cumulativeBarLabel: '', barLabel: '' };

  switch (periodType) {
    case PeriodType.CURRENT:
      switch (period) {
        case WEEK: {
          const cumulativeBarLabel = dateFormatter(relativeDates.weekStart, { endDate: barChartDate });
          const barLabel = dateFormatter(barChartDate, { type: DateType.DAY_YEAR });
          return { cumulativeBarLabel, barLabel };
        }
        case MONTH: {
          const cumulativeBarLabel = dateFormatter(relativeDates.monthStart, { endDate: barChartDate });
          const barLabel = dateFormatter(barChartDate, { type: DateType.DAY_YEAR });
          return { cumulativeBarLabel, barLabel };
        }
        case QUARTER: {
          const endDate = lastDayOfMonth(barChartDate);
          const cumulativeBarLabel = dateFormatter(relativeDates.quarterStart, { endDate });
          const barLabel = dateFormatter(barChartDate, { endDate });
          return { cumulativeBarLabel, barLabel };
        }
        case YEAR: {
          const endDate = lastDayOfMonth(barChartDate);
          const cumulativeBarLabel = dateFormatter(relativeDates.yearStart, { endDate });
          const barLabel = dateFormatter(barChartDate, { endDate });
          return { cumulativeBarLabel, barLabel };
        }
        default:
          return emptyLabels;
      }
    case PeriodType.LAST:
      switch (period) {
        case WEEK: {
          const sameDayLastWeek = new Date(barChartDate);
          sameDayLastWeek.setDate(sameDayLastWeek.getDate() - 7);

          const cumulativeBarLabel = dateFormatter(relativeDates.weekLastStart, { endDate: sameDayLastWeek });
          const barLabel = dateFormatter(barChartDate, { type: DateType.DAY_YEAR });
          return { cumulativeBarLabel, barLabel };
        }
        case MONTH: {
          const sameDayLastMonth = new Date(barChartDate);
          sameDayLastMonth.setMonth(sameDayLastMonth.getMonth() - 1);

          const lastDay = sameDayLastMonth > relativeDates.monthLastEnd ? relativeDates.monthLastEnd : sameDayLastMonth;
          const cumulativeBarLabel = dateFormatter(relativeDates.monthLastStart, { endDate: lastDay });
          const barLabel = dateFormatter(barChartDate, { type: DateType.DAY_YEAR });
          return { cumulativeBarLabel, barLabel };
        }
        case QUARTER: {
          const lastQuarterMonth = new Date(barChartDate);
          lastQuarterMonth.setMonth(lastQuarterMonth.getMonth() - 3);

          const cumulativeBarLabel = dateFormatter(relativeDates.quarterLastStart, {
            endDate: lastDayOfMonth(lastQuarterMonth),
          });
          const endDate = lastDayOfMonth(barChartDate);
          const barLabel = dateFormatter(barChartDate, { endDate });
          return { cumulativeBarLabel, barLabel };
        }
        case YEAR: {
          const lastYearMonth = new Date(barChartDate);
          lastYearMonth.setFullYear(lastYearMonth.getFullYear() - 1);

          const cumulativeBarLabel = dateFormatter(relativeDates.stlyYearStart, {
            endDate: lastDayOfMonth(lastYearMonth),
          });
          const endDate = lastDayOfMonth(barChartDate);
          const barLabel = dateFormatter(barChartDate, { endDate });
          return { cumulativeBarLabel, barLabel };
        }
        default:
          return emptyLabels;
      }
    case PeriodType.STLY:
      switch (period) {
        case WEEK: {
          const sameDayLastYear = new Date(relativeDates.stlyWeekStart);
          const offsetDays = (barChartDate.getDay() + 6) % 7;
          sameDayLastYear.setDate(sameDayLastYear.getDate() + offsetDays);

          const cumulativeBarLabel = dateFormatter(relativeDates.stlyWeekStart, { endDate: sameDayLastYear });
          const barLabel = dateFormatter(barChartDate, { type: DateType.DAY_YEAR });
          return { cumulativeBarLabel, barLabel };
        }
        case MONTH: {
          const sameDayLastYear = new Date(barChartDate);
          sameDayLastYear.setFullYear(sameDayLastYear.getFullYear() - 1);

          const lastDay = sameDayLastYear > relativeDates.stlyMonthEnd ? relativeDates.stlyMonthEnd : sameDayLastYear;
          const cumulativeBarLabel = dateFormatter(relativeDates.stlyMonthStart, { endDate: lastDay });
          const barLabel = dateFormatter(barChartDate, { type: DateType.DAY_YEAR });
          return { cumulativeBarLabel, barLabel };
        }
        case QUARTER: {
          const sameMonthLastYear = new Date(barChartDate);
          sameMonthLastYear.setFullYear(sameMonthLastYear.getFullYear() - 1);

          const cumulativeBarLabel = dateFormatter(relativeDates.stlyQuarterStart, {
            endDate: lastDayOfMonth(sameMonthLastYear),
          });
          const endDate = lastDayOfMonth(barChartDate);
          const barLabel = dateFormatter(barChartDate, { endDate });
          return { cumulativeBarLabel, barLabel };
        }
        default:
          return emptyLabels;
      }
    default:
      return emptyLabels;
  }
};

const getBarActualTooltips = (periodType, period, barChartDate, relativeDates, amount, dateFormatter) => {
  let tooltipDatePart;
  const tooltipAmountPart = `Actuals: ${amount}`;

  if (periodType === PeriodType.CURRENT) {
    switch (period) {
      case WEEK: {
        const endDate = barChartDate >= relativeDates.yesterday ? relativeDates.yesterday : barChartDate;
        tooltipDatePart = dateFormatter(relativeDates.weekStart, { endDate });
        break;
      }
      case MONTH: {
        const endDate = barChartDate >= relativeDates.yesterday ? relativeDates.yesterday : barChartDate;
        tooltipDatePart = dateFormatter(relativeDates.monthStart, { endDate });
        break;
      }
      case QUARTER: {
        const endDate =
          barChartDate >= relativeDates.yesterday ? relativeDates.yesterday : lastDayOfMonth(barChartDate);
        tooltipDatePart = dateFormatter(relativeDates.quarterStart, { endDate });
        break;
      }
      case YEAR: {
        tooltipDatePart = dateFormatter(relativeDates.yearStart, { endDate: relativeDates.yesterday });
        break;
      }
      default:
        tooltipDatePart = null;
    }
  }

  return tooltipDatePart ? [tooltipDatePart, tooltipAmountPart] : tooltipAmountPart;
};

const getBarForecastTooltips = (periodType, period, barChartDate, relativeDates, amount, dateFormatter) => {
  let tooltipDatePart;
  const tooltipAmountPart = `Forecast: ${amount}`;

  if (periodType === PeriodType.CURRENT) {
    switch (period) {
      case WEEK:
      case MONTH: {
        tooltipDatePart = dateFormatter(relativeToday(relativeDates), { endDate: barChartDate });
        break;
      }
      case QUARTER:
      case YEAR: {
        const endDate = lastDayOfMonth(barChartDate);
        tooltipDatePart = dateFormatter(relativeToday(relativeDates), { endDate });
        break;
      }
      default:
        tooltipDatePart = null;
    }
  }

  return tooltipDatePart ? [tooltipDatePart, tooltipAmountPart] : tooltipAmountPart;
};

const getTargetValue = (selectedPeriod, barChartDate) => {
  if (selectedPeriod === WEEK) {
    // x values are weekday names
    const dayName = WEEKDAY_NAMES[(barChartDate.getDay() + 6) % 7];
    return dayName;
  }
  if (selectedPeriod === MONTH) {
    // x values are day numbers
    return barChartDate.getDate();
  }
  if (selectedPeriod === QUARTER || selectedPeriod === YEAR) {
    // x values are month names
    return MONTH_NAMES[barChartDate.getMonth()];
  }
  return barChartDate.getDate(); // fallback
};

const getBarChartValues = (
  series,
  selectedPeriod,
  barChartDate,
  numberFormatter,
  dateFormatter,
  relativeDates,
  { cumulative } = {},
) => {
  const targetValue = getTargetValue(selectedPeriod, barChartDate);
  let current = 0;
  let forecast = 0;
  let currentTooltip = '';
  let forecastTooltip = '';
  let label = '';
  let available = true; // Whether the target value is available in the series (non cumulative)

  if (!cumulative) {
    // Finds the point that exactly matches the target
    const point = series.data.find(p => p.x === targetValue);
    const dashedBar = barChartDate > relativeDates.yesterday;

    if (point && point.date) {
      const { barLabel } = getBarLabels(series.periodType, selectedPeriod, point.date, relativeDates, dateFormatter);
      label = barLabel;

      if (point.y_forecast) {
        forecast = point.y_forecast;
        current = point.y - point.y_forecast;
      } else if (point.dashed && dashedBar) {
        forecast = point.y;
      } else {
        current = point.y;
      }
      forecastTooltip = `Forecast: ${numberFormatter(forecast)}`;
      currentTooltip = `Actuals: ${numberFormatter(current)}`;
    } else {
      available = false;
    }
  } else {
    // Accumulates all points up to the target, according to the period
    const { cumulativeBarLabel } = getBarLabels(
      series.periodType,
      selectedPeriod,
      barChartDate,
      relativeDates,
      dateFormatter,
    );
    label = cumulativeBarLabel;
    switch (selectedPeriod) {
      case WEEK: {
        const targetIndex = WEEKDAY_NAMES.indexOf(targetValue);
        const forecastIndex = (relativeDates.yesterday.getDay() + 6) % 7;
        const dashedBar = barChartDate > relativeDates.yesterday;
        series.data.forEach(point => {
          const pointIndex = WEEKDAY_NAMES.indexOf(point.x);
          if (pointIndex !== -1 && pointIndex <= targetIndex) {
            if (forecastIndex !== -1 && pointIndex > forecastIndex) {
              if (point.dashed && dashedBar) {
                forecast += point.y;
              } else {
                current += point.y;
              }
            } else {
              current += point.y;
            }
          }
        });
        forecastTooltip = getBarForecastTooltips(
          series.periodType,
          WEEK,
          barChartDate,
          relativeDates,
          numberFormatter(forecast),
          dateFormatter,
        );
        currentTooltip = getBarActualTooltips(
          series.periodType,
          WEEK,
          barChartDate,
          relativeDates,
          numberFormatter(current),
          dateFormatter,
        );
        break;
      }
      case MONTH: {
        const dashedBar = barChartDate > relativeDates.yesterday;
        series.data.forEach(point => {
          if (typeof point.x === 'number' && point.x <= targetValue) {
            if (point.dashed && dashedBar) {
              forecast += point.y;
            } else {
              current += point.y;
            }
          }
        });
        forecastTooltip = getBarForecastTooltips(
          series.periodType,
          MONTH,
          barChartDate,
          relativeDates,
          numberFormatter(forecast),
          dateFormatter,
        );
        currentTooltip = getBarActualTooltips(
          series.periodType,
          MONTH,
          barChartDate,
          relativeDates,
          numberFormatter(current),
          dateFormatter,
        );
        break;
      }
      case QUARTER:
      case YEAR: {
        const targetIndex = MONTH_NAMES.indexOf(targetValue);
        const yesterdayMonth = relativeDates.yesterday.getMonth();
        const forecastIndex =
          relativeDates.monthLastEnd.getMonth() === yesterdayMonth ? yesterdayMonth + 1 : yesterdayMonth;
        const dashedBar = barChartDate > relativeDates.yesterday;
        series.data.forEach(point => {
          const pointIndex = MONTH_NAMES.indexOf(point.x);
          if (pointIndex !== -1 && pointIndex <= targetIndex) {
            if (forecastIndex !== -1 && pointIndex >= forecastIndex) {
              if (point.y_forecast) {
                forecast += point.y_forecast;
                current += point.y - point.y_forecast;
              } else if (point.dashed && dashedBar) {
                forecast += point.y;
              } else {
                current += point.y;
              }
            } else {
              current += point.y;
            }
          }
        });
        forecastTooltip = getBarForecastTooltips(
          series.periodType,
          selectedPeriod,
          barChartDate,
          relativeDates,
          numberFormatter(forecast),
          dateFormatter,
        );
        currentTooltip = getBarActualTooltips(
          series.periodType,
          selectedPeriod,
          barChartDate,
          relativeDates,
          numberFormatter(current),
          dateFormatter,
        );
        break;
      }
      default:
        break;
    }
  }
  return { label, current, forecast, currentTooltip, forecastTooltip, available };
};

const usePerformanceData = (
  selectedPeriod,
  selectedMetric,
  barChartDate,
  relativeDates,
  numberFormatter,
  dateFormatter,
) => {
  const {
    dailyPerformanceForecast,
    monthlyPerformanceForecast,
    dailyPerformanceHistory,
    monthlyPerformanceHistory,
  } = React.useContext(DashboardContext);

  const lineChartData = React.useMemo(() => {
    const data = getLineGraphData(
      selectedPeriod,
      selectedMetric,
      dailyPerformanceHistory,
      dailyPerformanceForecast,
      monthlyPerformanceHistory,
      monthlyPerformanceForecast,
      relativeDates,
      dateFormatter,
    );
    return data;
  }, [
    dailyPerformanceForecast,
    dailyPerformanceHistory,
    dateFormatter,
    monthlyPerformanceForecast,
    monthlyPerformanceHistory,
    relativeDates,
    selectedMetric,
    selectedPeriod,
  ]);

  const cumulativeLineChartData = React.useMemo(() => {
    return lineChartData.map(series => {
      let cumulativeSum = 0;
      const cumulativeData = series.data.map(point => {
        cumulativeSum += point.y;
        return { ...point, y: cumulativeSum };
      });
      return { ...series, data: cumulativeData };
    });
  }, [lineChartData]);

  const barChartData = React.useMemo(() => {
    return lineChartData
      .map(series =>
        getBarChartValues(series, selectedPeriod, barChartDate, numberFormatter, dateFormatter, relativeDates),
      )
      .filter(({ available }) => available); // Filter out values with no data
  }, [lineChartData, selectedPeriod, barChartDate, numberFormatter, dateFormatter, relativeDates]);

  const cumulativeBarChartData = React.useMemo(() => {
    return lineChartData.map(series =>
      getBarChartValues(series, selectedPeriod, barChartDate, numberFormatter, dateFormatter, relativeDates, {
        cumulative: true,
      }),
    );
  }, [lineChartData, selectedPeriod, barChartDate, numberFormatter, dateFormatter, relativeDates]);

  return {
    lineChartData,
    cumulativeLineChartData,
    barChartData,
    cumulativeBarChartData,
  };
};

export default usePerformanceData;
