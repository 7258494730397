export const TABS = {
  AT_A_GLANCE: 'AT_A_GLANCE',
  PRICING: 'PRICING',
  STAFFING: 'STAFFING',
  HOURS: 'HOURS',
  INVENTORY: 'INVENTORY',
  PERFORMANCE: 'PERFORMANCE',
};

export const TAB_TO_LABEL = {
  [TABS.AT_A_GLANCE]: 'At a Glance',
  [TABS.PRICING]: 'Pricing',
  [TABS.STAFFING]: 'Staffing',
  [TABS.HOURS]: 'Hours of Operation',
  [TABS.INVENTORY]: 'Products & Inventory',
  [TABS.PERFORMANCE]: 'Performance & Pacing',
};

export const PARAM_TO_TAB = {
  'at-a-glance': TABS.AT_A_GLANCE,
  pricing: TABS.PRICING,
  staffing: TABS.STAFFING,
  hours: TABS.HOURS,
  inventory: TABS.INVENTORY,
  performance: TABS.PERFORMANCE,
};

export const TAB_TO_PARAM = {
  [TABS.AT_A_GLANCE]: 'at-a-glance',
  [TABS.PRICING]: 'pricing',
  [TABS.STAFFING]: 'staffing',
  [TABS.HOURS]: 'hours',
  [TABS.INVENTORY]: 'inventory',
  [TABS.PERFORMANCE]: 'performance',
};
