import React from 'react';
import { DataSource, INTEGRATION_DATASOURCES } from './dataSourceConstants';
import DatabaseDataSourceRow from './DatabaseDataSourceRow';
import { DataSourceBase } from './DataSourceRowCommon';
import ManualDataSourceRow from './ManualDataSourceRow';
import PartnerDataSourceRow from './PartnerDataSourceRow';
import ShopifyDataSourceRow from './ShopifyDataSourceRow';
import LoadingIndicator from '../accountSettings/common/LoadingIndicator';

export const LoadingDataSource = () => (
  <DataSourceBase label={<LoadingIndicator />} timestamp={<LoadingIndicator />} actions={null} />
);

const IntegrationDataSourceRow = ({ datasetConfig, datasets }) => {
  const { partner } = datasetConfig;

  if (INTEGRATION_DATASOURCES.includes(partner)) {
    return <PartnerDataSourceRow datasetConfig={datasetConfig} datasets={datasets} />;
  }

  if (partner === DataSource.SHOPIFY) {
    return <ShopifyDataSourceRow datasetConfig={datasetConfig} datasets={datasets} />;
  }

  return null;
};

const DataSourceRow = props => {
  const { datasetConfig, datasets } = props;
  const { partner } = datasetConfig;
  const isDBSource = datasets.filter(dataset => dataset.location_url_type === 'DB').length > 0;

  // For integrations
  if (partner != null) {
    return <IntegrationDataSourceRow datasetConfig={datasetConfig} datasets={datasets} />;
  }

  // For database sources (Snowflake, etc)
  if (isDBSource) {
    return <DatabaseDataSourceRow datasetConfig={datasetConfig} datasets={datasets} />;
  }

  // For CSV/XLSX files
  return <ManualDataSourceRow datasetConfig={datasetConfig} datasets={datasets} />;
};

export default DataSourceRow;
