/* eslint-disable import/prefer-default-export */

export const WEEK = 'WEEK';
export const MONTH = 'MONTH';
export const QUARTER = 'QUARTER';
export const YEAR = 'YEAR';

/**
 * Parses a date string in the format "YYYY-M-D" (or "YYYY-MM-DD") and returns a Date object.
 * The returned Date represents a local date (ignoring time zones).
 *
 * @param {string} dateStr - A date string formatted as "YYYY-M-D".
 * @returns {Date} A local Date object corresponding to the input date string.
 */
export const parseDateAsLocal = dateStr => {
  const [year, month, day] = dateStr.split('-').map(Number);
  return new Date(year, month - 1, day);
};

/**
 * This is an example extract of the meta from usePerformanceForecastMeta().
 * Note that each is "relative" to "as_of_date"
 * 
  "as_of_date": "2025-03-25",
  "relative_dates": {
    "yesterday": "2025-03-24",
    "next_7_start": "2025-03-25",
    "next_7_end": "2025-03-31",
    "next_30_start": "2025-03-25",
    "next_30_end": "2025-04-23",
    "next_90_start": "2025-03-25",
    "next_90_end": "2025-06-22",
    "next_365_start": "2025-03-25",
    "next_365_end": "2026-03-24",
    "week_start": "2025-03-24",
    "week_end": "2025-03-30",
    "week_last_start": "2025-03-17",
    "week_last_end": "2025-03-23",
    "week_last_4_start": "2025-02-25",
    "week_last_4_end": "2025-03-24",
    "week_prior_start": "2025-03-10",
    "week_prior_end": "2025-03-16",
    "month_start": "2025-03-01",
    "month_end": "2025-03-31",
    "month_last_start": "2025-02-01",
    "month_last_end": "2025-02-28",
    "month_prior_start": "2025-01-01",
    "month_prior_end": "2025-01-31",
    "month_next_start": "2025-04-01",
    "month_next_end": "2025-04-30",
    "quarter_start": "2025-01-01",
    "quarter_end": "2025-03-31",
    "quarter_last_start": "2024-10-01",
    "quarter_last_end": "2024-12-31",
    "quarter_prior_start": "2024-07-01",
    "quarter_prior_end": "2024-09-30",
    "quarter_next_start": "2025-04-01",
    "quarter_next_end": "2025-06-30",
    "year_start": "2025-01-01",
    "year_end": "2025-12-31",
    "year_next_start": "2026-01-01",
    "year_next_end": "2026-12-31",
    "sdlw_yesterday": "2025-03-17",
    "sdly_yesterday": "2024-03-25",
    "stly_next_7_start": "2024-03-25",
    "stly_next_7_end": "2024-03-31",
    "stly_next_30_start": "2024-03-25",
    "stly_next_30_end": "2024-04-23",
    "stly_next_90_start": "2024-03-25",
    "stly_next_90_end": "2024-06-22",
    "stly_next_365_start": "2024-03-25",
    "stly_next_365_end": "2025-03-24",
    "stly_week_start": "2024-03-25",
    "stly_week_end": "2024-03-31",
    "stly_week_last_start": "2024-03-18",
    "stly_week_last_end": "2024-03-24",
    "stly_week_last_4_start": "2024-02-27",
    "stly_week_last_4_end": "2024-03-25",
    "stly_week_prior_start": "2024-03-11",
    "stly_week_prior_end": "2024-03-17",
    "stly_month_start": "2024-03-01",
    "stly_month_end": "2024-03-31",
    "stly_month_last_start": "2024-02-01",
    "stly_month_last_end": "2024-02-29",
    "stly_month_prior_start": "2024-01-01",
    "stly_month_prior_end": "2024-01-31",
    "stly_month_next_start": "2024-04-01",
    "stly_month_next_end": "2024-04-30",
    "stly_month_to_date_start": "2024-03-01",
    "stly_month_to_date_end": "2024-03-25",
    "stly_quarter_start": "2024-01-01",
    "stly_quarter_end": "2024-03-31",
    "stly_quarter_last_start": "2023-10-01",
    "stly_quarter_last_end": "2023-12-31",
    "stly_quarter_next_start": "2024-04-01",
    "stly_quarter_next_end": "2024-06-30",
    "stly_quarter_to_date_start": "2024-01-01",
    "stly_quarter_to_date_end": "2024-03-25",
    "stly_year_start": "2024-01-01",
    "stly_year_end": "2024-12-31",
    "stly_year_prior_start": "2023-01-01",
    "stly_year_prior_end": "2023-12-31",
    "stly_year_to_date_start": "2024-01-01",
    "stly_year_to_date_end": "2024-03-25"
  }
 */

/**
 * Converts each relative date string into a Date. Note that not every possible entry is converted.
 * Simply add missing dates as needed.
 *
 * @param {Object} relativeDates - the relative_dates object from usePerformanceForecastMeta()
 * @returns {Object} An object whose attributes are Date objects for each relative date
 */
export const parseRelativeDates = relativeDates => {
  const {
    yesterday,

    week_start: weekStart,
    week_end: weekEnd,
    week_last_start: weekLastStart,
    week_last_end: weekLastEnd,
    stly_week_start: stlyWeekStart,
    stly_week_end: stlyWeekEnd,

    month_start: monthStart,
    month_end: monthEnd,
    month_last_start: monthLastStart,
    month_last_end: monthLastEnd,
    stly_month_start: stlyMonthStart,
    stly_month_end: stlyMonthEnd,

    quarter_start: quarterStart,
    quarter_end: quarterEnd,
    quarter_last_start: quarterLastStart,
    quarter_last_end: quarterLastEnd,
    stly_quarter_start: stlyQuarterStart,
    stly_quarter_end: stlyQuarterEnd,
    // missing stly_quarter_prior_start/stly_quarter_prior_end ??

    year_start: yearStart,
    year_end: yearEnd,
    stly_year_start: stlyYearStart,
    stly_year_end: stlyYearEnd,
  } = relativeDates;

  return {
    yesterday: parseDateAsLocal(yesterday),

    weekStart: parseDateAsLocal(weekStart),
    weekEnd: parseDateAsLocal(weekEnd),
    weekLastStart: parseDateAsLocal(weekLastStart),
    weekLastEnd: parseDateAsLocal(weekLastEnd),
    stlyWeekStart: parseDateAsLocal(stlyWeekStart),
    stlyWeekEnd: parseDateAsLocal(stlyWeekEnd),

    monthStart: parseDateAsLocal(monthStart),
    monthEnd: parseDateAsLocal(monthEnd),
    monthLastStart: parseDateAsLocal(monthLastStart),
    monthLastEnd: parseDateAsLocal(monthLastEnd),
    stlyMonthStart: parseDateAsLocal(stlyMonthStart),
    stlyMonthEnd: parseDateAsLocal(stlyMonthEnd),

    quarterStart: parseDateAsLocal(quarterStart),
    quarterEnd: parseDateAsLocal(quarterEnd),
    quarterLastStart: parseDateAsLocal(quarterLastStart),
    quarterLastEnd: parseDateAsLocal(quarterLastEnd),
    stlyQuarterStart: parseDateAsLocal(stlyQuarterStart),
    stlyQuarterEnd: parseDateAsLocal(stlyQuarterEnd),

    yearStart: parseDateAsLocal(yearStart),
    yearEnd: parseDateAsLocal(yearEnd),
    stlyYearStart: parseDateAsLocal(stlyYearStart),
    stlyYearEnd: parseDateAsLocal(stlyYearEnd),
  };
};

export const getRanges = (period, relativeDates) => {
  const {
    weekStart,
    weekEnd,
    weekLastStart,
    weekLastEnd,
    stlyWeekStart,
    stlyWeekEnd,
    monthStart,
    monthEnd,
    monthLastStart,
    monthLastEnd,
    stlyMonthStart,
    stlyMonthEnd,
    quarterStart,
    quarterEnd,
    quarterLastStart,
    quarterLastEnd,
    stlyQuarterStart,
    stlyQuarterEnd,
    yearStart,
    yearEnd,
    stlyYearStart,
    stlyYearEnd,
  } = relativeDates;

  let granularity;
  let currentRange;
  let lastRange;
  let stlyRange;

  switch (period) {
    case WEEK: {
      granularity = 'day';
      currentRange = { start: weekStart, end: weekEnd };
      lastRange = { start: weekLastStart, end: weekLastEnd };
      stlyRange = { start: stlyWeekStart, end: stlyWeekEnd };
      break;
    }
    case MONTH: {
      granularity = 'day';
      currentRange = { start: monthStart, end: monthEnd };
      lastRange = { start: monthLastStart, end: monthLastEnd };
      stlyRange = { start: stlyMonthStart, end: stlyMonthEnd };
      break;
    }
    case QUARTER: {
      granularity = 'month';
      currentRange = { start: quarterStart, end: quarterEnd };
      lastRange = { start: quarterLastStart, end: quarterLastEnd };
      stlyRange = { start: stlyQuarterStart, end: stlyQuarterEnd };
      break;
    }
    case YEAR: {
      granularity = 'month';
      currentRange = { start: yearStart, end: yearEnd };
      lastRange = { start: stlyYearStart, end: stlyYearEnd };
      stlyRange = null;
      break;
    }
    default:
      break;
  }

  return { granularity, currentRange, lastRange, stlyRange };
};
