import axios from 'axios';
import camelcase from 'camelcase';
import { useQueries, useQuery } from 'react-query';
import { readString } from 'react-papaparse';
import { convertFrequencyToColumnName } from '../../components/dashboards/eo/revenueUplift/priceChangeFrequencyUtil';
import { PRICE_LIKE_COLS } from '../../components/dashboards/eo/revenueUplift/pricingTabConstants';
import { PriceChangeFrequency } from '../../components/workflow/workflowConstants';
import { KA_API_URL } from '../../config/baseUrl';
import { logExecutionTime } from '../../utils/performanceMonitoring';

const finalName = (originalColumnName, defaultFunc) => {
  let finalColumnName;

  switch (originalColumnName) {
    case 'customer_entity_id':
      finalColumnName = 'locationId';
      break;
    case 'weekday':
    case 'Weekday':
      finalColumnName = convertFrequencyToColumnName(PriceChangeFrequency.DOW);
      break;
    case 'date':
    case 'Date':
      finalColumnName = convertFrequencyToColumnName(PriceChangeFrequency.DAY);
      break;
    default:
      finalColumnName = defaultFunc(originalColumnName);
  }

  return finalColumnName;
};

const transformHeader = columnName =>
  finalName(columnName, name => {
    return camelcase(name);
  });

const transformKaizenScenarioHeader = columnName =>
  finalName(columnName, name => {
    if (PRICE_LIKE_COLS.some(col => name.includes(col))) {
      return camelcase(`new-${columnName}`);
    }

    return camelcase(name);
  });

const transformRecentScenarioHeader = columnName =>
  finalName(columnName, name => {
    if (PRICE_LIKE_COLS.some(col => name.includes(col))) {
      return camelcase(`baseline-${name}`);
    }

    return camelcase(name);
  });

const transformPerformanceHeader = columnName => {
  // We look for a date with the format "YYYY-M" or "YYYY-MM" optionally followed by "-D" or "-DD"
  const dateRegex = /^(.+)_(\d{4}-\d{1,2}(?:-\d{1,2})?)$/;
  const match = columnName.match(dateRegex);

  if (match) {
    const prefix = match[1]; // The prefix before the underscore
    const datePart = match[2]; // The date part
    // Apply camelCase to the prefix and concatenate it with the date part without an underscore
    return `${camelcase(prefix)}_${datePart}`;
  }

  if (columnName === 'customer_entity_id') {
    return 'locationId';
  }

  return camelcase(columnName);
};

const productDimIdColumnRegex = /^productDim[0-9]+Id$/;
const digitRegex = /^\d+$/;

const transformPriceStatsValues = (value, header) => {
  let finalValue;

  switch (header) {
    case 'uplift':
    case 'kaizenRevenue':
    case 'currentRevenue':
    case 'kaizenProfit':
    case 'currentProfit':
    case 'kaizenPrice':
    case 'currentPrice':
    case 'currentDemand':
    case 'kaizenDemand':
    case 'kaizenPriceWeight':
    case 'currentPriceWeight':
      finalValue = parseFloat(value);
      break;
    case 'productId':
    case 'locationId':
      finalValue = parseInt(value, 10);
      break;
    case 'weeksSinceRelease':
      finalValue = parseInt(value, 10);
      break;
    default:
      if (productDimIdColumnRegex.test(header)) {
        finalValue = parseInt(value, 10);
      } else {
        finalValue = value;
      }
      break;
  }

  return finalValue;
};

const transformPaceStatsValues = (value, header) => {
  let finalValue;

  switch (header) {
    case 'monthDemand':
    case 'monthRevenue':
    case 'monthProfit':
    case 'quarterDemand':
    case 'quarterRevenue':
    case 'quarterProfit':
    case 'yearDemand':
    case 'yearRevenue':
    case 'yearProfit':
      finalValue = parseFloat(value);
      break;
    case 'productId':
    case 'locationId':
      finalValue = parseInt(value, 10);
      break;
    case 'weeksSinceRelease':
      finalValue = parseInt(value, 10);
      break;
    default:
      if (productDimIdColumnRegex.test(header)) {
        finalValue = parseInt(value, 10);
      } else {
        finalValue = value;
      }
      break;
  }

  return finalValue;
};

const transformProductCatalogValues = (value, header) => {
  const floatHeaders = [
    'next7Revenue',
    'next7Demand',
    'next30Revenue',
    'next30Demand',
    'next90Revenue',
    'next90Demand',
    'next365Revenue',
    'next365Demand',
    'monthRevenue',
    'monthDemand',
    'quarterRevenue',
    'quarterDemand',
    'yearRevenue',
    'yearDemand',
    'monthNextRevenue',
    'monthNextDemand',
    'quarterNextRevenue',
    'quarterNextDemand',
    'yearNextRevenue',
    'yearNextDemand',
    'currentRevenue',
    'currentDemand',
    'price',
    'cost',
    'next365Price',
    'next7Gpp',
    'next30Gpp',
    'next90Gpp',
    'next365Gpp',
    'monthGpp',
    'quarterGpp',
    'yearGpp',
    'monthNextGpp',
    'quarterNextGpp',
    'yearNextGpp',
  ];

  const intHeaders = ['locationId', 'quantity'];

  if (floatHeaders.includes(header)) {
    return parseFloat(value);
  }

  if (intHeaders.includes(header) || productDimIdColumnRegex.test(header)) {
    return parseInt(value, 10);
  }

  return value;
};

const transformPerformanceValues = (value, header) => {
  const intHeaders = ['locationId', 'productId'];

  if (intHeaders.includes(header) || productDimIdColumnRegex.test(header)) {
    return parseInt(value, 10);
  }

  return parseFloat(value);
};

const transformPricingScenarioValues = (value, header) => {
  let finalValue;

  if (header.includes('Demand') || header.includes('Price')) {
    finalValue = parseFloat(value);
  } else if (header !== 'pk' && digitRegex.test(value)) {
    finalValue = parseInt(value, 10);
  } else {
    finalValue = value;
  }

  return finalValue;
};

const transformAverageProductRevenueValues = (value, header) => {
  let finalValue;

  if (header.includes('Revenue') || digitRegex.test(value)) {
    finalValue = parseInt(value, 10);
  } else {
    finalValue = value;
  }

  return finalValue;
};

const transformResponse = (data, transformheader, transformValues, logMessage) => {
  const start = performance.now();

  // Convert raw bytes to a string
  const csvData = new TextDecoder('utf-8').decode(data);

  // Parse the data
  const { data: rows, errors } = readString(csvData, {
    header: true,
    transformHeader: transformheader,
    transform: transformValues,
  });

  // Remove any rows that contained too many or two few columns
  errors.forEach(error => rows.splice(error.row, 1));

  logExecutionTime(logMessage, start, performance.now());

  return rows;
};

export const GET_PRICE_STATS_META_BASE = `${KA_API_URL}/stats/price/meta`;

const fetchMetaPriceStats = async (workflowId, output) =>
  axios.get(GET_PRICE_STATS_META_BASE, {
    params: { workflow_id: workflowId, output },
  });

const fetchPriceStats = async (workflowId, output) =>
  axios.get(`${KA_API_URL}/stats/price/raw`, {
    params: { workflow_id: workflowId, output },
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/octet-stream, application/json',
    },
  });

const PRICE_STATS_SUMMARY_OUTPUT = 'summary';
const PACE_FORECAST_STATS_OUTPUT = 'agg_summary';
const DAILY_FORECAST_STATS_OUTPUT = 'daily_forecast';
const PRICING_RECOMMENDATION_STATS_OUTPUT = 'price_recs';
const KAIZEN_PRICING_SCENARIO_OUTPUT = 'pricing_scenario_kaizen';
const RECENT_PRICING_SCENARIO_OUTPUT = 'pricing_scenario_recent';
const AVERAGE_PRODUCT_REVENUE_FORECAST_OUTPUT = 'forecast_avg_revenue';
const PRODUCT_CATALOG_OUTPUT = 'product_catalog';
const DAILY_PERFORMANCE_FORECAST_OUTPUT = 'daily_performance_forecast';
const MONTHLY_PERFORMANCE_FORECAST_OUTPUT = 'monthly_performance_forecast';

export const PRICE_STATS_SUMMARY_META_QUERY_KEY_BASE = 'priceStatsSummaryMeta';
export const DAILY_FORECAST_META_QUERY_KEY_BASE = 'dailyForecastMeta';
export const KAIZEN_PRICING_SCENARIO_META_QUERY_KEY_BASE = 'kaizenPricingScenarioMeta';
export const RECENT_PRICING_SCENARIO_META_QUERY_KEY_BASE = 'recentPricingScenarioMeta';
export const PRODUCT_CATALOG_META_QUERY_KEY_BASE = 'productCatalogMeta';
export const MONTHLY_PERFORMANCE_META_QUERY_KEY_BASE = 'monthlyPerformanceMeta';
export const PRICE_STATS_SUMMARY_QUERY_KEY_BASE = 'priceStatsSummary';
export const PACE_FORECAST_STATS_QUERY_KEY_BASE = 'paceForecastStats';
export const DAILY_FORECAST_STATS_QUERY_KEY_BASE = 'dailyForecastStats';
export const PRICING_RECOMMENDATION_STATS_QUERY_KEY_BASE = 'pricingRecommendationStats';
export const KAIZEN_PRICING_SCENARIO_QUERY_KEY_BASE = 'kaizenPricingScenario';
export const RECENT_PRICING_SCENARIO_QUERY_KEY_BASE = 'recentPricingScenario';
export const AVERAGE_PRODUCT_REVENUE_FORECAST_QUERY_KEY_BASE = 'averageProductRevenueForecast';
export const PRODUCT_CATALOG_QUERY_KEY_BASE = 'productCatalog';
export const DAILY_PERFORMANCE_QUERY_KEY_BASE = 'dailyPerformance';
export const MONTHLY_PERFORMANCE_QUERY_KEY_BASE = 'monthlyPerformance';

export const usePriceStatsMeta = (workflowId, queryConfig) =>
  useQuery(
    [PRICE_STATS_SUMMARY_META_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchMetaPriceStats(workflowId, PRICE_STATS_SUMMARY_OUTPUT);
      return response.data;
    },
    queryConfig,
  );

export const useDailyForecastMeta = (workflowId, queryConfig) =>
  useQuery(
    [DAILY_FORECAST_META_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchMetaPriceStats(workflowId, DAILY_FORECAST_STATS_OUTPUT);
      return response.data;
    },
    queryConfig,
  );

export const useKaizenPricingScenarioMeta = (workflowId, queryConfig) =>
  useQuery(
    [KAIZEN_PRICING_SCENARIO_META_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchMetaPriceStats(workflowId, KAIZEN_PRICING_SCENARIO_OUTPUT);
      return response.data;
    },
    queryConfig,
  );

export const useRecentPricingScenarioMeta = (workflowId, queryConfig) =>
  useQuery(
    [RECENT_PRICING_SCENARIO_META_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchMetaPriceStats(workflowId, RECENT_PRICING_SCENARIO_OUTPUT);
      return response.data;
    },
    queryConfig,
  );

export const useProductCatalogMeta = (workflowId, queryConfig) =>
  useQuery(
    [PRODUCT_CATALOG_META_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchMetaPriceStats(workflowId, PRODUCT_CATALOG_OUTPUT);
      return response.data;
    },
    queryConfig,
  );

export const useMultipleProductCatalogMeta = kpWorkflows =>
  useQueries(
    kpWorkflows.map(workflow => ({
      queryKey: [PRODUCT_CATALOG_META_QUERY_KEY_BASE, workflow.workflow_id],
      queryFn: async () => {
        const response = await fetchMetaPriceStats(workflow.workflow_id, PRODUCT_CATALOG_OUTPUT);
        return response.data;
      },
      enabled: !!workflow.workflow_id,
    })),
  );

export const usePerformanceForecastMeta = (workflowId, queryConfig) =>
  useQuery(
    [MONTHLY_PERFORMANCE_META_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchMetaPriceStats(workflowId, MONTHLY_PERFORMANCE_FORECAST_OUTPUT);
      return response.data;
    },
    queryConfig,
  );

export const usePriceStatsSummary = (workflowId, queryConfig) =>
  useQuery(
    [PRICE_STATS_SUMMARY_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchPriceStats(workflowId, PRICE_STATS_SUMMARY_OUTPUT);

      try {
        const { data: rawData } = response;
        const rows = transformResponse(
          rawData,
          transformHeader,
          transformPriceStatsValues,
          'Price Stats Summary parsing',
        );

        return rows;
      } catch {
        throw new Error('Unable to interpret your data. If this issue persists, please contact us.');
      }
    },
    queryConfig,
  );

export const usePaceForecastStats = (workflowId, queryConfig) =>
  useQuery(
    [PACE_FORECAST_STATS_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchPriceStats(workflowId, PACE_FORECAST_STATS_OUTPUT);

      try {
        const { data: rawData } = response;
        const rows = transformResponse(
          rawData,
          transformHeader,
          transformPaceStatsValues,
          'Pace Forecast Stats parsing',
        );

        return rows;
      } catch {
        throw new Error('Unable to interpret your data. If this issue persists, please contact us.');
      }
    },
    { retry: 2, ...queryConfig },
  );

export const useDailyForecastStats = (workflowId, queryConfig) =>
  useQuery(
    [DAILY_FORECAST_STATS_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchPriceStats(workflowId, DAILY_FORECAST_STATS_OUTPUT);

      try {
        const { data: rawData } = response;

        const rows = transformResponse(
          rawData,
          transformHeader,
          transformPriceStatsValues,
          'Daily Forecast Stats parsing',
        );
        return rows;
      } catch {
        throw new Error('Unable to interpret your data. If this issue persists, please contact us.');
      }
    },
    { retry: 2, ...queryConfig },
  );

export const usePricingRecommendationStats = (workflowId, queryConfig) =>
  useQuery(
    [PRICING_RECOMMENDATION_STATS_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchPriceStats(workflowId, PRICING_RECOMMENDATION_STATS_OUTPUT);

      try {
        const { data: rawData } = response;
        const rows = transformResponse(
          rawData,
          transformHeader,
          transformPriceStatsValues,
          'Pricing Recommendation Stats parsing',
        );

        return rows;
      } catch {
        throw new Error('Unable to interpret your data. If this issue persists, please contact us.');
      }
    },
    { retry: 2, ...queryConfig },
  );

export const useKaizenPricingScenario = (workflowId, queryConfig) =>
  useQuery(
    [KAIZEN_PRICING_SCENARIO_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchPriceStats(workflowId, KAIZEN_PRICING_SCENARIO_OUTPUT);

      try {
        const { data: rawData } = response;
        const rows = transformResponse(
          rawData,
          transformKaizenScenarioHeader,
          transformPricingScenarioValues,
          'Kaizen Pricing Scenario parsing',
        );

        return rows;
      } catch {
        throw new Error('Unable to interpret your data. If this issue persists, please contact us.');
      }
    },
    queryConfig,
  );

export const useRecentPricingScenario = (workflowId, queryConfig) =>
  useQuery(
    [RECENT_PRICING_SCENARIO_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchPriceStats(workflowId, RECENT_PRICING_SCENARIO_OUTPUT);

      try {
        const { data: rawData } = response;
        const rows = transformResponse(
          rawData,
          transformRecentScenarioHeader,
          transformPricingScenarioValues,
          'Recent Pricing Scenario parsing',
        );

        return rows;
      } catch {
        throw new Error('Unable to interpret your data. If this issue persists, please contact us.');
      }
    },
    queryConfig,
  );

export const useAverageProductRevenueForecast = (workflowId, queryConfig) =>
  useQuery(
    [AVERAGE_PRODUCT_REVENUE_FORECAST_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchPriceStats(workflowId, AVERAGE_PRODUCT_REVENUE_FORECAST_OUTPUT);

      try {
        const { data: rawData } = response;
        const rows = transformResponse(
          rawData,
          transformHeader,
          transformAverageProductRevenueValues,
          'Average Product Revenue Foreast Scenario parsing',
        );

        return rows;
      } catch {
        throw new Error('Unable to interpret your data. If this issue persists, please contact us.');
      }
    },
    queryConfig,
  );

export const useProductCatalog = (workflowId, queryConfig) =>
  useQuery(
    [PRODUCT_CATALOG_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchPriceStats(workflowId, PRODUCT_CATALOG_OUTPUT);

      try {
        const { data: rawData } = response;
        const rows = transformResponse(
          rawData,
          transformHeader,
          transformProductCatalogValues,
          'Product Catalog parsing',
        );

        return rows;
      } catch {
        throw new Error('Unable to interpret your data. If this issue persists, please contact us.');
      }
    },
    queryConfig,
  );

export const useDailyPerformanceForecast = (workflowId, queryConfig) =>
  useQuery(
    [DAILY_PERFORMANCE_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchPriceStats(workflowId, DAILY_PERFORMANCE_FORECAST_OUTPUT);

      try {
        const { data: rawData } = response;
        const rows = transformResponse(
          rawData,
          transformPerformanceHeader,
          transformPerformanceValues,
          'Daily performance forecast parsing',
        );

        return rows;
      } catch {
        throw new Error('Unable to interpret your data. If this issue persists, please contact us.');
      }
    },
    queryConfig,
  );

export const useMonthlyPerformanceForecast = (workflowId, queryConfig) =>
  useQuery(
    [MONTHLY_PERFORMANCE_QUERY_KEY_BASE, workflowId],
    async () => {
      const response = await fetchPriceStats(workflowId, MONTHLY_PERFORMANCE_FORECAST_OUTPUT);

      try {
        const { data: rawData } = response;
        const rows = transformResponse(
          rawData,
          transformPerformanceHeader,
          transformPerformanceValues,
          'Monthly performance forecast parsing',
        );

        return rows;
      } catch {
        throw new Error('Unable to interpret your data. If this issue persists, please contact us.');
      }
    },
    queryConfig,
  );
