/* eslint-disable camelcase */
import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { Prompt, useHistory } from 'react-router-dom';
import { Link, useQueryClient } from 'react-query';
import CheckoutPanel from './CheckoutPanel';
import { BillingDate, Branding, SubscriptionInfoCard } from './common/CommonSubscriptionComponents';
import ConfirmationModal from './ConfirmationModal';
import LocationsTable from './LocationsTable';
import { useLocationTableState } from './LocationsTableFunctions';
import ManagePaymentMethodModal from './ManagePaymentMethodModal';
import LoadingIndicator from '../common/LoadingIndicator';
import NAVIGATION_SEPARATOR from '../settingsNavigationMenuConstants';
import SettingsNavigationMenu from '../SettingsNavigationMenu';
import SettingsPageWrapper from '../SettingsPageWrapper';
import { LOCATION_KEY_DATASOURCES } from '../../dataSources/dataSourceConstants';
import { ProfitRoverPrimaryButton, ProfitRoverSecondaryButton } from '../../forms/ProfitRoverButtons';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import ReactivateSubscriptionBanner from '../../generic/subscriptions/ReactivateSubscriptionBanner';
import StartPlanBanner from '../../generic/subscriptions/StartPlanBanner';
import {
  KaizenBillingInterval,
  KaizenProductStatus,
  SubscriptionBannerButton,
} from '../../generic/subscriptions/SubscriptionConstants';
import UnpaidInvoiceBanner from '../../generic/subscriptions/UnpaidInvoiceBanner';
import Header from '../../header/header';
import { CenteredProfitRoverSpinner } from '../../spinner/ProfitRoverSpinner';
import PartnerConnection from '../../setup/modern/PartnerConnection';
import { formatCurrency } from '../../util/format';
import { toggleAutoRenew } from '../../../data-access/mutation/subscriptions';
import { useDatasetConfigs } from '../../../data-access/query/datasetConfigs';
import { LOCATIONS_QUERY_KEY } from '../../../data-access/query/locations';
import { BILLING_INFO_QUERY_KEY_BASE, useBillingInfo } from '../../../data-access/query/subscriptions';
import { gaEmitViewInvoicesButtonClick } from '../../../google-analytics/subscriptionSettings';

const PlanInfo = ({ billingInfoState, isCanceled, isDelinquent, trialHasExpired, isFreeTier }) => {
  const { data: billingInfo, error: billingInfoError, isFetching } = billingInfoState;
  const { quantity, auto_renew: isAutoRenewOn, coupon, unit_amount: unitAmount } = billingInfo?.plan_info ?? {};
  const { default_payment_method: defaultPaymentMethod } = billingInfo ?? {};
  const hasPaymentMethod = defaultPaymentMethod != null;

  const history = useHistory();
  const navigateToManagePlanPage = () => history.push('/manage-plan');

  let planDisplay;

  if (isFetching) {
    planDisplay = (
      <>
        <div className="heading-text">
          <LoadingIndicator />
        </div>
        <div className="plan-cost">
          <LoadingIndicator />
        </div>
      </>
    );
  } else if (billingInfoError) {
    planDisplay = (
      <>
        <div className="heading-text">Unknown Plan</div>
      </>
    );
  } else {
    const isTrialing = billingInfo.plan_info.subscription_status === KaizenProductStatus.TRIALING;
    const isMonthlyPlan = billingInfo.plan_info.billing_interval === KaizenBillingInterval.MONTHLY;

    let planType;
    if (isTrialing) {
      planType = 'Free Trial';
    } else if (isFreeTier && quantity === 1) {
      planType = 'Free Tier Plan';
    } else {
      planType = isMonthlyPlan ? 'Monthly Plan' : 'Annual Plan';
    }

    const interval = isMonthlyPlan ? 'Month' : 'Year';
    let text;
    if (isTrialing) {
      text = `1 Location for ${billingInfo.plan_info.trial_period_days} Days`;
    } else if (isFreeTier && quantity < 2) {
      text = `1 Free Location`;
    } else if (isFreeTier && quantity >= 2) {
      const [, nonFreeTier = {}] = billingInfo?.plan_info?.tiers ?? [];
      const { unit_amount: unitPrice = 0 } = nonFreeTier;
      const unitCost = formatCurrency(unitPrice / 100);
      text = `${unitCost} per Location per ${interval}`;
    } else {
      const unitCost = formatCurrency(billingInfo.plan_info.unit_amount / 100);
      text = `${unitCost} per Location per ${interval}`;
    }

    planDisplay = (
      <>
        <div className="heading-text">{planType}</div>
        {!isTrialing && !isFreeTier && coupon ? (
          <div>
            <p>
              <span className="discount-text discount-price">{formatCurrency(unitAmount / 100)}</span>
              <span className="discount-text">
                {formatCurrency((unitAmount / 100) * (1 - coupon.percent_off / 100))}
              </span>
              <span className="discount-text"> Per Location Per {interval}</span>
            </p>
            <p className="promo-label">
              {coupon.percent_off}% Promo Applied for First {coupon.duration_in_months} Months
            </p>
          </div>
        ) : (
          <div className="plan-cost">{text}</div>
        )}
        {isTrialing && isAutoRenewOn && (
          <div className="days-remaining">
            <span className="days-remaining-integer">{billingInfo.plan_info.trial_days_remaining}</span> Days Remaining
          </div>
        )}
        {isFreeTier && <div className="first-free-text">1st Location Free</div>}
        {!isAutoRenewOn && <div className="auto-renew-disabled">Auto-Renew is Off</div>}
      </>
    );
  }

  if (isCanceled) {
    planDisplay = <div className="heading-text warning">No Active Plan</div>;
  }
  if (trialHasExpired) {
    planDisplay = <div className="heading-text warning">Inactive</div>;
  }
  const freeTierWithMultipleActiveLocations = isFreeTier && quantity >= 2;
  const showManagePlan = !trialHasExpired && (hasPaymentMethod || freeTierWithMultipleActiveLocations);

  return (
    <div className="right-side plan-info">
      <div>{planDisplay}</div>
      {showManagePlan && (
        <div className="buttons">
          <ProfitRoverSecondaryButton small onClick={navigateToManagePlanPage} disabled={isDelinquent || isCanceled}>
            Manage Plan
          </ProfitRoverSecondaryButton>
        </div>
      )}
    </div>
  );
};

const CardNumber = ({ isFetching, billingInfoError, cardBrand, last4Digits }) => {
  if (isFetching) {
    return <LoadingIndicator />;
  }

  if (billingInfoError) {
    return 'Card Ending in (Unknown)';
  }

  return `${cardBrand} Ending in ****${last4Digits}`;
};

const BillingInfo = ({ billingInfoState, isCanceled, trialHasExpired, subscriptionId }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorToggling, setErrorToggling] = React.useState(false);
  const [showUpdatePaymentMethodModal, setShowUpdatePaymentMethodModal] = React.useState(false);
  const onHide = () => setShowUpdatePaymentMethodModal(false);
  const queryClient = useQueryClient();

  const submitEnableAutoRenew = async () => {
    setIsSubmitting(true);
    setErrorToggling(false);
    try {
      await toggleAutoRenew(subscriptionId);
      await Promise.allSettled([
        queryClient.refetchQueries([...BILLING_INFO_QUERY_KEY_BASE, subscriptionId]),
        queryClient.refetchQueries([...LOCATIONS_QUERY_KEY]),
      ]);
      setIsSubmitting(false);
    } catch {
      setErrorToggling(true);
      setIsSubmitting(false);
    }
  };

  const { data: billingInfo, error: billingInfoError, isFetching } = billingInfoState;

  const autoRenewIsOn = billingInfo?.plan_info?.auto_renew;
  const currentBillingCycleEnd = billingInfo?.plan_info?.current_billing_cycle_end;
  const nextBillingIntervalChanged = billingInfo?.plan_info?.is_billing_interval_change_scheduled;
  const { default_payment_method: defaultPaymentMethod } = billingInfo ?? {};
  const hasPaymentMethod = defaultPaymentMethod != null;
  const last4Digits = billingInfo?.default_payment_method?.last4;
  let cardBrand = billingInfo?.default_payment_method?.brand ?? 'Card';

  // Capitalize the first letter
  cardBrand = cardBrand.charAt(0).toUpperCase() + cardBrand.slice(1);

  const isTrialing = billingInfo?.plan_info.subscription_status === KaizenProductStatus.TRIALING;
  const isMonthlyPlan = billingInfo?.plan_info.billing_interval === 'month';
  const planType = isMonthlyPlan ? 'Monthly Plan' : 'Annual Plan';

  const history = useHistory();

  const navigateToInvoicesPage = () => {
    gaEmitViewInvoicesButtonClick();
    history.push('/invoices');
  };
  const navigateToManagePaymentMethodPage = () => history.push('/manage-payment-method');

  return (
    <div className="left-side billing-info">
      <ManagePaymentMethodModal show={showUpdatePaymentMethodModal} subscriptionId={subscriptionId} onHide={onHide} />
      {!isCanceled && !trialHasExpired ? (
        <div>
          <div className="heading-text">
            Next Billing
            {!isTrialing && !hasPaymentMethod && (
              <span className="upgrading-text"> *Only after adding a payment method</span>
            )}
            {nextBillingIntervalChanged && <span className="upgrading-text"> *Switching to {planType}</span>}
          </div>
          {!autoRenewIsOn ? (
            <div className="auto-renew-disabled">
              <BillingDate
                isFetching={isFetching}
                billingInfoError={billingInfoError}
                currentBillingCycleEnd={currentBillingCycleEnd}
                autoRenewIsOn={autoRenewIsOn}
              />
              {errorToggling && (
                <div className="auto-renew-contact-us">
                  An error has occurred. Please try again later or reach out to our <Link to="/help">support team</Link>
                  to re-enable auto-renew.
                </div>
              )}
              {hasPaymentMethod && (
                <button type="button" disabled={isSubmitting} onClick={submitEnableAutoRenew}>
                  {isSubmitting || isFetching ? <LoadingIndicator /> : 'Turn auto renew back on'}
                </button>
              )}
            </div>
          ) : (
            <>
              <BillingDate
                isFetching={isFetching}
                billingInfoError={billingInfoError}
                currentBillingCycleEnd={currentBillingCycleEnd}
              />
              {hasPaymentMethod && (
                <div className="payment-method-info">
                  <CardNumber
                    isFetching={isFetching}
                    billingInfoError={billingInfoError}
                    cardBrand={cardBrand}
                    last4Digits={last4Digits}
                  />
                </div>
              )}
            </>
          )}
        </div>
      ) : trialHasExpired ? (
        <>
          <div className="heading-text">Next Billing</div>
          <div className="payment-method-info">Not Scheduled</div>
        </>
      ) : (
        <>
          <div className="heading-text">Next Billing</div>
          {/* TODO: Revive this as part of KP-3054 */}
          {/* <i className="billing-reactivation-message">You will not be billed unless you reactivate.</i> */}
        </>
      )}
      <div className="buttons">
        <ProfitRoverSecondaryButton small onClick={navigateToInvoicesPage}>
          View Invoices
        </ProfitRoverSecondaryButton>
        {hasPaymentMethod ? (
          <ProfitRoverSecondaryButton small onClick={navigateToManagePaymentMethodPage}>
            Manage Payment Method
          </ProfitRoverSecondaryButton>
        ) : (
          <ProfitRoverSecondaryButton
            small
            onClick={() => setShowUpdatePaymentMethodModal(true)}
            disabled={trialHasExpired}
          >
            Add Payment Method
          </ProfitRoverSecondaryButton>
        )}
      </div>
    </div>
  );
};

const ActiveLocationHeaderInfo = ({
  billingInfoState,
  numberOfActiveLocations,
  isCanceled,
  isFreeTier,
  isTrialing,
  hasPaymentMethod,
  trialHasExpired,
}) => {
  const { data: billingInfo, error: billingInfoError, isFetching } = billingInfoState;
  const {
    auto_renew: autoRenewIsOn,
    coupon,
    current_billing_cycle_end: billingCycleEnd = 0,
    unit_amount: unitAmount = 0,
  } = billingInfo?.plan_info ?? {};
  const isDiscount = coupon != null;

  const couponDurationInMonths = coupon?.duration_in_months;
  const nextBillingDate = moment.unix(billingCycleEnd);
  const couponCreatedDate = moment.unix(coupon?.created);
  const couponExpiresDate = couponCreatedDate.add(couponDurationInMonths, 'months');
  const couponExpiredForNextBilling = nextBillingDate.isAfter(couponExpiresDate);

  if (!isCanceled) {
    let amountDue;
    let subtotal;
    if (isFetching) {
      amountDue = <LoadingIndicator pull="right" />;
    } else if (billingInfoError) {
      amountDue = '$X.XX';
    } else if (!autoRenewIsOn) {
      amountDue = `$0.00`;
    } else if (trialHasExpired || (isTrialing && !hasPaymentMethod)) {
      subtotal = formatCurrency(unitAmount / 100, true);
      amountDue = (unitAmount / 100) * (1 - coupon?.percent_off / 100);
      amountDue = formatCurrency(amountDue, true);
    } else {
      subtotal = billingInfo?.next_billing_info?.subtotal ?? 0;
      subtotal = formatCurrency(subtotal / 100, true);
      amountDue = billingInfo?.next_billing_info?.total_amount_due ?? 0;
      amountDue = formatCurrency(amountDue / 100, true);
    }

    return (
      <div className="right-side active-location-info">
        <div className="discount">
          {isDiscount && !couponExpiredForNextBilling && <h4 className="discount-price">{subtotal}</h4>}
          <h4>
            {amountDue}
            {!isFetching && !billingInfoError && <span className="tax-text"> + tax</span>}
          </h4>
        </div>
        {autoRenewIsOn && <div className="location-count">{numberOfActiveLocations} Active Locations</div>}
        {isFreeTier && <div className="first-free-text">1st Location Free</div>}
      </div>
    );
  }

  return (
    <div className="right-side active-location-info">
      <div className="location-count">0 Active Locations</div>
      <h4>$0.00</h4>
    </div>
  );
};

const TRIAL_ENDED_TEXT = `Your free trial has ended. Start Your Plan Today to regain access to your insights.`;

const remainingDaysText = daysRemaining => {
  let text = `${daysRemaining} day`;

  if (daysRemaining > 1) {
    text += 's';
  }

  return text;
};

const composeTrialBannerText = (isMultiLocation, hasPaymentMethod, daysRemaining) => {
  let bannerText;

  if (isMultiLocation) {
    if (hasPaymentMethod) {
      bannerText = `Your paid plan will automatically start in ${remainingDaysText(daysRemaining)}.`;
      bannerText += ' Want to start your plan today so you can activate more locations?';
    } else {
      bannerText = `You will lose your insights in ${remainingDaysText(daysRemaining)}!`;
      bannerText += ' Either add a payment method to avoid disruptions or start your plan today';
      bannerText += ' so you can activate more locations.';
    }
  } else if (hasPaymentMethod) {
    bannerText = 'You’re all set! You will be automatically charged when your free trial ends.';
  } else {
    bannerText = `You will lose your insights in ${remainingDaysText(daysRemaining)}!`;
    bannerText += ' Confirm your payment method to avoid disruptions at the end of your free trial.';
  }

  return bannerText;
};

const TrialSubscriptionBanner = ({
  totalLocations,
  subscriptionId,
  trialHasExpired,
  hasPaymentMethod,
  trialDaysRemaining,
}) => (
  <StartPlanBanner
    bannerText={
      trialHasExpired
        ? TRIAL_ENDED_TEXT
        : composeTrialBannerText(totalLocations > 1, hasPaymentMethod, trialDaysRemaining)
    }
    bannerButton={
      trialHasExpired || totalLocations > 1
        ? SubscriptionBannerButton.START_NOW
        : hasPaymentMethod
        ? SubscriptionBannerButton.NONE
        : SubscriptionBannerButton.ADD_PAYMENT
    }
    subscriptionId={subscriptionId}
    trialHasExpired={trialHasExpired}
  />
);

const isApiKeyInUse = (apiKey, locations) => {
  const exists = locations.some(location => location.location_identifier === apiKey);
  return !exists;
};

// naics/ka_code needed for location creation - returning one of the partner dataset config values
// currently there is no logic based on this so for now it does not really matter which one we pick
const getNaicsCodesFromPartnerDatasetConfigs = (datasetConfigRecords, partner) => {
  if (partner) {
    const datasetConfig = datasetConfigRecords.find(item => item.partner === partner);

    if (datasetConfig) {
      const { naics_code: naicsCode, ka_code: kaCode } = datasetConfig;
      return { naicsCode, kaCode };
    }
  }

  return {};
};

const getLocationKeyPartnersFromDatasetConfigs = datasetConfigRecords => {
  const partners = datasetConfigRecords
    .filter(config => LOCATION_KEY_DATASOURCES.includes(config.partner))
    .map(config => config.partner);

  return [...new Set(partners)];
};

const SubscriptionManagementSummary = ({
  subscriptionId,
  setLocationsToActivate,
  setLocationsToDeactivate,
  isTrialing,
  isCanceled,
  isDelinquent,
  isFreeTier,
  trialHasExpired,
}) => {
  const billingInfoState = useBillingInfo(subscriptionId);
  const locationTableState = useLocationTableState(subscriptionId);
  const { data: datasetConfigs = [], isFetching: isFetchingDatasetConfigs } = useDatasetConfigs();

  const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);
  const toggleShowConfirmationModal = () => setShowConfirmationModal(!showConfirmationModal);

  const [openPartnerConnection, setOpenPartnerConnection] = React.useState(false);

  const partners = getLocationKeyPartnersFromDatasetConfigs(datasetConfigs);

  // For now we only handle the case where there is exactly one location key partner.
  // TODO: if there is more than 1, allow the user to select which partner they wish to add a location for
  const partner = partners.length === 1 ? partners[0] : undefined;

  const showAddLocationButton = !isFetchingDatasetConfigs && partner;
  const { naicsCode, kaCode } = getNaicsCodesFromPartnerDatasetConfigs(datasetConfigs, partner);

  const { data: billingInfo = {}, isFetching } = billingInfoState;
  const { plan_info: planInfo = {}, default_payment_method: defaultPaymentMethod } = billingInfo;
  const hasPaymentMethod = defaultPaymentMethod != null;
  const { quantity, auto_renew: autoRenewIsOn = false, trial_days_remaining: trialDaysRemaining } = planInfo;
  const hideBillingInfo = isFreeTier && quantity < 2;

  const locationTogglesEnabled = !isTrialing && !isDelinquent && !isCanceled && !trialHasExpired && autoRenewIsOn;
  const showNavigationMenu = !isDelinquent && !isCanceled && !trialHasExpired;

  const {
    resetTableState,
    numberOfActiveLocations,
    stagedLocationsPendingActivation,
    stagedLocationsPendingDeactivation,
    hasStagedChanges,
    initialRowData: locationRows,
    refetchLocations,
  } = locationTableState;

  const totalLocations = locationRows.length;

  const onClickContinue = () => {
    if (hasStagedChanges) {
      if (stagedLocationsPendingActivation.length) {
        setLocationsToActivate(stagedLocationsPendingActivation);
      }
      if (stagedLocationsPendingDeactivation.length) {
        setLocationsToDeactivate(stagedLocationsPendingDeactivation);
      }
    }
  };

  const onLocationAdded = () => {
    setOpenPartnerConnection(false);
    // refresh locations
    refetchLocations();
  };

  const blurStyles = { filter: showConfirmationModal ? 'blur(8px)' : undefined };

  let banner;
  if (isCanceled) {
    banner = <ReactivateSubscriptionBanner />;
  } else if (isFetching) {
    banner = <LoadingIndicator pull="center" />;
  } else if (isTrialing || trialHasExpired) {
    // TODO: plan info should have days left in trial - send this to the banner
    banner = (
      <TrialSubscriptionBanner
        totalLocations={totalLocations}
        subscriptionId={subscriptionId}
        trialHasExpired={trialHasExpired}
        hasPaymentMethod={hasPaymentMethod}
        trialDaysRemaining={trialDaysRemaining}
      />
    );
  }

  return (
    <HeaderAwarePage style={blurStyles}>
      <Header />
      {showNavigationMenu && (
        <SettingsNavigationMenu label={`Settings ${NAVIGATION_SEPARATOR} Subscription`} tabsId={['settings']} />
      )}
      <SettingsPageWrapper>
        <div className="container">
          <UnpaidInvoiceBanner spacingClassName="sub-mgmt-summary-spacing" />
          <h3 className="settings-header">Subscription</h3>
          <div className={classnames('subscription-mgmt-container', { 'show-banner': banner })}>
            <div className="summary">
              <SubscriptionInfoCard>
                <Branding />
                <PlanInfo
                  billingInfoState={billingInfoState}
                  isCanceled={isCanceled}
                  isDelinquent={isDelinquent}
                  numberOfActiveLocations={numberOfActiveLocations}
                  isFreeTier={isFreeTier}
                  trialHasExpired={trialHasExpired}
                />
              </SubscriptionInfoCard>
              {hideBillingInfo || (
                <SubscriptionInfoCard>
                  <BillingInfo
                    billingInfoState={billingInfoState}
                    isCanceled={isCanceled}
                    subscriptionId={subscriptionId}
                    trialHasExpired={trialHasExpired}
                  />
                  <ActiveLocationHeaderInfo
                    billingInfoState={billingInfoState}
                    numberOfActiveLocations={numberOfActiveLocations}
                    isCanceled={isCanceled}
                    isFreeTier={isFreeTier}
                    isTrialing={isTrialing}
                    hasPaymentMethod={hasPaymentMethod}
                    trialHasExpired={trialHasExpired}
                  />
                </SubscriptionInfoCard>
              )}
            </div>

            {banner}

            <LocationsTable
              locationTableState={locationTableState}
              locationTogglesEnabled={locationTogglesEnabled}
              autoRenewIsOn={autoRenewIsOn}
              loadingBillingInfo={billingInfoState.isLoading}
              showEditLocation={showNavigationMenu}
            />

            {showAddLocationButton && (
              <>
                <div className="d-flex justify-content-center m-4">
                  <ProfitRoverPrimaryButton
                    onClick={() => setOpenPartnerConnection(true)}
                    disabled={openPartnerConnection}
                  >
                    {openPartnerConnection ? <CenteredProfitRoverSpinner /> : 'Add Location'}
                  </ProfitRoverPrimaryButton>
                </div>
                <PartnerConnection
                  partner={partner}
                  onConnected={onLocationAdded}
                  open={openPartnerConnection}
                  onCancel={() => setOpenPartnerConnection(false)}
                  onValidateApiKey={apiKey => isApiKeyInUse(apiKey, locationRows)}
                  locationNaicsCode={naicsCode}
                  locationKaCode={kaCode}
                  enableInstructionsModal
                />
              </>
            )}

            <Prompt
              when={hasStagedChanges}
              message="If you leave this page, you will lose any changes you have made to your subscription."
            />

            {hasStagedChanges && (
              <>
                <ConfirmationModal
                  show={isTrialing && showConfirmationModal}
                  closeModal={toggleShowConfirmationModal}
                  onContinue={onClickContinue}
                />
                <CheckoutPanel
                  onContinue={isTrialing ? toggleShowConfirmationModal : onClickContinue}
                  onCancel={resetTableState}
                  numActivating={stagedLocationsPendingActivation.length}
                  numDeactivating={stagedLocationsPendingDeactivation.length}
                />
              </>
            )}
          </div>
        </div>
      </SettingsPageWrapper>
    </HeaderAwarePage>
  );
};

export default SubscriptionManagementSummary;
