import React from 'react';
import './tooltip.scss';

const Tooltip = React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      className="pace-tooltip-container"
      style={{
        position: 'absolute',
        left: `${props.x}px`,
        top: `${props.y}px`,
      }}
    >
      <div className="pace-tooltip">
        {Array.isArray(props.content) ? (
          props.content.map((line, index) => <div key={index}>{line}</div>)
        ) : (
          <div>{props.content}</div>
        )}
      </div>
    </div>
  );
});

Tooltip.displayName = 'Tooltip';

export default Tooltip;
