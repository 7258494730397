import React from 'react';
import { DataSource } from '../../dataSources/dataSourceConstants';

const PARTNER_ADDITIONAL_STEPS_LIST = {
  [DataSource.GOTAB]: [
    `You will be redirected to a GoTab authorization page (note that you may have to verify your GoTab account).`,
    `Press the Authorize button to grant access (do not refresh your browser during this process).`,
  ],
  [DataSource.HEARTLAND_RESTAURANT]: [
    `You will need an API key for ProfitRover.
    To obtain your API key, open up a browser and login to your Heartland Restaurant account.`,
    `Navigate to the Integrations section and select the location that you wish to add.`,
    `Press the +New button, choose API for Integration Type and ProfitRover for Integration Partner, 
    and then press the Save button.`,
    `Copy the API key so you have it for the dialog box that will show up after clicking the button below.`,
    `Enter the API key and press OK. Your location details will be shown in another dialog box 
    (you may optionally change anything you see there).`,
    `When you are satisfied that the location details are correct, press the Confirm button to complete the process.`,
  ],
  [DataSource.HEARTLAND_RETAIL]: [
    `Open up a new tab in your browser window and login to your Heartland Retail account.`,
    `You will be redirected to a Heartland Retail page where you will be asked to 
    allow Profitrover to access your data.`,
    `Click on the Allow button to authorize access.`,
  ],
  [DataSource.LIGHTSPEED_K]: [
    `You will be redirected to a Lightspeed authorization page 
    (note that you may have to login to your Lightspeed account).`,
    `Make sure that each of the protected resources listed is toggled ON
    and then press the Authorize button to grant access
    (do not refresh your browser during this process).`,
  ],
  [DataSource.SQUARE]: ['Login with your Square Credentials.', `Allow access to the permissions we request.`],
};

const PartnerSteps = ({ partner, additionalSteps }) => {
  const steps = [...(additionalSteps ?? []), ...PARTNER_ADDITIONAL_STEPS_LIST[partner]];

  return (
    <ol>
      {steps.map((step, index) => (
        <li key={index}>{step}</li>
      ))}
    </ol>
  );
};

export default PartnerSteps;
