import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useHistory } from 'react-router-dom';
import { STEPS, useStepControl } from './commonStepControl';
import { Provider } from './guidedSetupContext';
import { useUpdateGuidedSetupState } from './guidedSetupHooks';
import GuidedSetupWelcome from './GuidedSetupWelcome';
import { PointOfSaleGuidedSetup } from './PointOfSaleSetup';
import ScheduleStrategyCall from './ScheduleStrategyCall';
import TellAboutYourself from './TellAboutYourself';
import { DataSource, INTEGRATION_DATASOURCES } from '../../dataSources/dataSourceConstants';
import RetryOrGoToFallback from '../../generic/errorBoundary/RetryOrGoToFallback';
import GenericLoadingPage from '../../generic/GenericLoadingPage';
import Header from '../../header/header';
import { IN_DEVELOPMENT_BUILD } from '../../../config/build';
import { useGuidedSetup } from '../../../data-access/query/guidedSetup';

const MAP_STEP_TO_COMPONENT = {
  [STEPS.WELCOME]: GuidedSetupWelcome,
  [STEPS.ONE]: TellAboutYourself,
  [STEPS.TWO]: ScheduleStrategyCall,
  [STEPS.THREE]: PointOfSaleGuidedSetup,
};

const useNavToNextGuidedSetupFlow = guidedSetup => {
  const history = useHistory();

  const dataSource = guidedSetup?.data_source?.source;
  const { dataset_id: datasetId, dataset_config_id: datasetConfigId } = guidedSetup;

  return () => {
    if (!dataSource) {
      return;
    }

    if (INTEGRATION_DATASOURCES.includes(dataSource)) {
      history.push('/guided-setup/partner', { datasetId, datasetConfigId, partner: dataSource });
    } else if (dataSource === DataSource.ETSY) {
      history.push('/guided-setup/manual/file-upload/etsy');
    } else if (dataSource === DataSource.CLOVER) {
      history.push('/guided-setup/manual/file-upload/clover');
    } else {
      // For manual and file-based "pseudo-integrations"
      history.push('/guided-setup/manual/file-upload');
    }
  };
};

const StepController = ({ guidedSetup }) => {
  const stepState = useStepControl(guidedSetup);
  const { step } = stepState;

  const navigateToNextFlow = useNavToNextGuidedSetupFlow(guidedSetup);
  const { updateGuidedSetup } = useUpdateGuidedSetupState();

  if (step === STEPS.COMPLETE) {
    navigateToNextFlow();
    return null;
  }

  const Step = MAP_STEP_TO_COMPONENT[step];
  if (!Step) {
    const errorMessage = IN_DEVELOPMENT_BUILD
      ? 'Developer Error: Unknown Guided Setup Step'
      : 'Unexpected error occurred.';
    throw new Error(errorMessage);
  }

  return (
    <Provider value={{ guidedSetup, stepState, updateGuidedSetup }}>
      <Step />
    </Provider>
  );
};

const GuidedSetup = () => {
  const guidedSetupQuery = useGuidedSetup();
  const { data: guidedSetup = {}, isLoading } = guidedSetupQuery;

  if (isLoading) {
    return <GenericLoadingPage />;
  }

  return (
    <ErrorBoundary
      fallbackRender={props => (
        <>
          <Header />
          <RetryOrGoToFallback {...props} />
        </>
      )}
    >
      <StepController guidedSetup={guidedSetup} />
    </ErrorBoundary>
  );
};

export default GuidedSetup;
