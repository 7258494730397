/*
 * TODO KP-3925 note that as of KP-3916 the revenue uplift dashboard can optionally be used as a profit uplift dashboard
 * instead, according to workflow settings. There are still many file / component / variable names that specifically
 * reference 'revenue' even though they could technically be either revenue or profit.
 */

/* eslint-disable default-case */
import { addDays, differenceInCalendarDays, startOfToday, isValid } from 'date-fns';
import _ from 'lodash';
import camelcase from 'camelcase';
import React from 'react';
import { useSevenDayForecast, usePercentiles } from './atAGlanceTabUtil';
import DashboardDimensionStats from './dashboardDimensionStats';
import { isProductDimColumnName } from './inventoryTabUtil';
import {
  BUCKETS,
  BUCKET_TO_FILTER_LABEL,
  LARGE_DECREASE,
  LARGE_INCREASE,
  LARGE_PRICE_CHANGE_RATIO_CAP,
  MEDIUM_DECREASE,
  MEDIUM_INCREASE,
  MEDIUM_PRICE_CHANGE_RATIO_CAP,
  NO_CHANGE,
  PRICE_CHANGE_BUCKET_ID,
  PRICE_CHANGE_BUCKET_LABEL,
  PRICE_LIKE_COLS,
  SMALL_DECREASE,
  SMALL_INCREASE,
} from './pricingTabConstants';
import { NoDataAvailableError } from './revenueDashboardErrors';
import { DomainContext } from './revenueUpliftContexts';
import {
  DAYS_OF_WEEK,
  DAY_OF_WEEK_DIMENSION_ID,
  LOCATIONS_DIMENSION_ID,
  SEASON_DIMENSION_ID,
  SEASON_FEATURE_COLUMN_NAME,
  TIME_DIMENSION_IDS,
  WEEKPART_DIMENSION_ID,
  WEEKPART_FEATURE_COLUMN_NAME,
  WEEKS_SINCE_RELEASE_DIMENSION_ID,
  WEEKS_SINCE_RELEASE_FEATURE_COLUMN_NAME,
} from './revenueUpliftConstants';
import { TABS } from './revenueUpliftDashboardTabs';
import { formatAsDatasetDate, getDayOfWeek, parseDatasetDate } from './timePeriod';
import { HOUR_DIMENSION_TYPE, USER_DIMENSION_TYPE } from '../../../workflow/workflowConstants';
import { logExecutionTime } from '../../../../utils/performanceMonitoring';

export const WEEKS_SINCE_RELEASE_COLUMN_DIM_KEY = camelcase(WEEKS_SINCE_RELEASE_FEATURE_COLUMN_NAME);

export const getDimColumnKey = dimensionId => {
  if (dimensionId === DAY_OF_WEEK_DIMENSION_ID) {
    return 'dayOfWeek';
  }
  if (dimensionId === LOCATIONS_DIMENSION_ID) {
    return 'locationId';
  }
  if (dimensionId === WEEKPART_DIMENSION_ID) {
    return WEEKPART_FEATURE_COLUMN_NAME;
  }
  if (dimensionId === SEASON_DIMENSION_ID) {
    return SEASON_FEATURE_COLUMN_NAME;
  }
  if (dimensionId === WEEKS_SINCE_RELEASE_DIMENSION_ID) {
    return WEEKS_SINCE_RELEASE_COLUMN_DIM_KEY;
  }
  if (dimensionId === PRICE_CHANGE_BUCKET_ID) {
    return PRICE_CHANGE_BUCKET_ID;
  }

  return `productDim${dimensionId}Id`;
};

export const gatherKnownLocationAndDimValueIds = (locations, productDimensions, productDimensionValues) => {
  const dimensionValues = [
    {
      id: LOCATIONS_DIMENSION_ID,
      dimValueIds: new Set(locations.map(location => location.location_id)),
    },
    ...productDimensions.map(dimension => {
      const { product_dimension_id: dimensionId } = dimension;

      const relevantDimValues = productDimensionValues.filter(dimVal => dimVal.dimension_id === dimensionId);

      return {
        id: dimensionId,
        dimValueIds: new Set(relevantDimValues.map(dimValue => dimValue.id)),
      };
    }),
  ];

  return _.keyBy(dimensionValues, 'id');
};

const initializeDashboardDimensionStats = (
  locations,
  productDimensions,
  productDimensionValues,
  configTimeDimState,
) => {
  const dimStats = [
    new DashboardDimensionStats({
      id: DAY_OF_WEEK_DIMENSION_ID,
      label: 'Day of Week',
      dimValues: DAYS_OF_WEEK,
    }),
    new DashboardDimensionStats({
      id: LOCATIONS_DIMENSION_ID,
      label: 'Location',
      dimValues: locations.map(location => ({
        dimValLabel: location.location_description,
        datasetValue: location.location_id,
      })),
    }),
    ...productDimensions.map(dimension => {
      const { product_dimension_id: dimensionId, name } = dimension;

      const relevantDimValues = productDimensionValues.filter(dimVal => dimVal.dimension_id === dimensionId);

      return new DashboardDimensionStats({
        id: dimensionId,
        label: name,
        dimValues: relevantDimValues.map(dimValue => ({
          dimValLabel: dimValue.value,
          datasetValue: dimValue.id,
        })),
      });
    }),
    new DashboardDimensionStats({
      id: PRICE_CHANGE_BUCKET_ID,
      label: PRICE_CHANGE_BUCKET_LABEL,
      dimValues: BUCKETS.map(bucket => ({
        dimValLabel: BUCKET_TO_FILTER_LABEL[bucket],
        datasetValue: bucket,
      })),
    }),
  ];

  const { shouldIncludeDim } = configTimeDimState;

  if (shouldIncludeDim(WEEKPART_DIMENSION_ID)) {
    const { values: weekpartValues } = configTimeDimState[WEEKPART_DIMENSION_ID];
    dimStats.push(
      new DashboardDimensionStats({
        id: WEEKPART_DIMENSION_ID,
        label: 'Weekpart',
        dimValues: weekpartValues.map(weekpartLabel => ({
          dimValLabel: weekpartLabel,
          datasetValue: weekpartLabel,
        })),
      }),
    );
  }

  if (shouldIncludeDim(SEASON_DIMENSION_ID)) {
    const { values: seasonValues } = configTimeDimState[SEASON_DIMENSION_ID];
    dimStats.push(
      new DashboardDimensionStats({
        id: SEASON_DIMENSION_ID,
        label: 'Season',
        dimValues: seasonValues.map(seasonLabel => ({
          dimValLabel: seasonLabel,
          datasetValue: seasonLabel,
        })),
      }),
    );
  }

  if (shouldIncludeDim(WEEKS_SINCE_RELEASE_DIMENSION_ID)) {
    const { values: weeksSinceReleaseValues } = configTimeDimState[WEEKS_SINCE_RELEASE_DIMENSION_ID];
    dimStats.push(
      new DashboardDimensionStats({
        id: WEEKS_SINCE_RELEASE_DIMENSION_ID,
        label: 'Weeks Since Release',
        dimValues: weeksSinceReleaseValues.map(weeksSinceReleaseLabel => ({
          dimValLabel: weeksSinceReleaseLabel,
          datasetValue: weeksSinceReleaseLabel,
        })),
      }),
    );
  }

  return dimStats;
};

const produceDimensionPreviews = (dashboardDimensionsById, { selectionState }) => {
  const dimensionPreviews = [];

  const dimIds = Object.keys(dashboardDimensionsById);

  dimIds.forEach(dimId => {
    const [dashboardDimStats, dimValSelections] = [dashboardDimensionsById[dimId], selectionState[dimId]];
    const { id, label } = dashboardDimStats;

    const dimensionPreview = { id, label, dimValues: [] };

    dimValSelections.forEach(({ dimensionValueId, isSelected }) => {
      const dimValStatistic = dashboardDimStats.dimValueStats[dimensionValueId];

      const displayValues = _.pick(dimValStatistic, ['hasRows', 'label']);

      dimensionPreview.dimValues.push({
        ...displayValues,
        dimValueId: dimValStatistic.datasetValue,
        isSelected,
      });
    });

    if (id === DAY_OF_WEEK_DIMENSION_ID) {
      dimensionPreview.applicableTabs = [TABS.PRICING];
    }

    if (id === PRICE_CHANGE_BUCKET_ID) {
      dimensionPreview.applicableTabs = [TABS.PRICING];
    }

    if (label === 'Hour') {
      // Not applicable to Inventory tab
      dimensionPreview.applicableTabs = [TABS.AT_A_GLANCE, TABS.PRICING, TABS.STAFFING, TABS.HOURS];
    }

    if (TIME_DIMENSION_IDS.includes(id) || label === 'Hour') {
      dimensionPreview.category = 'Time';
    } else if (id === LOCATIONS_DIMENSION_ID) {
      dimensionPreview.category = 'Location';
    } else if (id === PRICE_CHANGE_BUCKET_ID) {
      dimensionPreview.category = 'Pricing';
    } else {
      dimensionPreview.category = 'Product';
    }

    dimensionPreviews.push(dimensionPreview);
  });

  return dimensionPreviews;
};

export const useDimensionPreviews = dashboardSelectionState => {
  const {
    dimensions: productDimensions,
    dimensionValues: productDimensionValues,
    locations,
    configTimeDimState,
  } = React.useContext(DomainContext);

  const dashboardDimensionStats = initializeDashboardDimensionStats(
    locations,
    productDimensions,
    productDimensionValues,
    configTimeDimState,
  );

  const dashboardDimensionsStatsById = _.keyBy(dashboardDimensionStats, 'id');

  return produceDimensionPreviews(dashboardDimensionsStatsById, dashboardSelectionState);
};

const shouldIncludeRowByDim = (row, selectedValuesByDimId, dashboardDimIds) => {
  return dashboardDimIds.every(dimId => {
    const dimValue = row[getDimColumnKey(dimId)];
    const selectedValuesSet = selectedValuesByDimId[dimId];

    return selectedValuesSet?.has(dimValue) ?? false;
  });
};

export const useMinMaxHourlyGridHours = (rows, selectedValuesByDimId, getDimValueLabel) => {
  const { dimensions: productDims } = React.useContext(DomainContext);

  return React.useMemo(() => {
    const hourDimId = productDims.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE)?.product_dimension_id;

    let maxSevenDayForecastHour;
    let minSevenDayForecastHour;

    rows.forEach(row => {
      // Product dimensions should not affect the range of the hourly grid
      if (
        !!hourDimId &&
        (row.currentRevenue > 0 || row.prediction > 0) &&
        shouldIncludeRowByDim(row, selectedValuesByDimId, [LOCATIONS_DIMENSION_ID, hourDimId])
      ) {
        const hour = getDimValueLabel(hourDimId, row[getDimColumnKey(hourDimId)]);
        if (!!hour && (!maxSevenDayForecastHour || hour > maxSevenDayForecastHour)) {
          maxSevenDayForecastHour = hour;
        }
        if (!!hour && (!minSevenDayForecastHour || hour < minSevenDayForecastHour)) {
          minSevenDayForecastHour = hour;
        }
      }
    });

    return [minSevenDayForecastHour, maxSevenDayForecastHour];
  }, [productDims, selectedValuesByDimId, getDimValueLabel, rows]);
};

const shouldIncludeRowByHoursOfOperation = (row, openHoursPerDayPerLocation, hourDimId, getDimValueLabel) => {
  const { locationId, tranDate } = row;
  if (
    hourDimId == null ||
    locationId == null ||
    tranDate == null ||
    openHoursPerDayPerLocation == null ||
    openHoursPerDayPerLocation[locationId] == null
  ) {
    // No hours of operation defined
    return true;
  }

  const dayOfWeek = getDayOfWeek(parseDatasetDate(tranDate));
  const hourValueId = row[getDimColumnKey(hourDimId)];
  const hourValue = getDimValueLabel(hourDimId, hourValueId);
  const openHours = openHoursPerDayPerLocation[locationId][dayOfWeek] ?? [];

  return openHours.includes(hourValue);
};

export const useFilteredRows = (
  rows,
  selectedValuesByDimId,
  getDimValueLabel,
  { openHoursPerDayPerLocation, userDimensionsOnly, archivedDimValues } = {},
) => {
  const { dimensions: productDims } = React.useContext(DomainContext);

  return React.useMemo(() => {
    const productDimIds = productDims
      .filter(dim => !userDimensionsOnly || dim.dimension_type === USER_DIMENSION_TYPE)
      .map(dim => dim.product_dimension_id);
    const dimIds = [LOCATIONS_DIMENSION_ID, ...productDimIds];
    const hourDimId = productDims?.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE)?.product_dimension_id;
    const filteredRows = [];

    if (archivedDimValues) {
      Object.keys(selectedValuesByDimId).forEach(dimId => {
        selectedValuesByDimId[dimId] = new Set([...selectedValuesByDimId[dimId], ...(archivedDimValues[dimId] ?? [])]);
      });
    }

    rows.forEach(row => {
      if (
        shouldIncludeRowByDim(row, selectedValuesByDimId, dimIds) &&
        shouldIncludeRowByHoursOfOperation(row, openHoursPerDayPerLocation, hourDimId, getDimValueLabel)
      ) {
        filteredRows.push(row);
      }
    });

    return filteredRows;
  }, [
    productDims,
    archivedDimValues,
    rows,
    userDimensionsOnly,
    selectedValuesByDimId,
    openHoursPerDayPerLocation,
    getDimValueLabel,
  ]);
};

export const useFilteredForecastStatsRows = (
  rows,
  selectedValuesByDimId,
  getDimValueLabel,
  { openHoursPerDayPerLocation, archivedDimValues } = {},
) => {
  const { dimensions: productDims } = React.useContext(DomainContext);

  return React.useMemo(() => {
    const hourDimensionId = productDims.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE)?.product_dimension_id;
    const forecastStatsDimIds = [LOCATIONS_DIMENSION_ID, hourDimensionId];
    const filteredRows = [];

    if (archivedDimValues) {
      Object.keys(selectedValuesByDimId).forEach(dimId => {
        selectedValuesByDimId[dimId] = new Set([...selectedValuesByDimId[dimId], ...(archivedDimValues[dimId] ?? [])]);
      });
    }

    rows.forEach(row => {
      if (
        shouldIncludeRowByDim(row, selectedValuesByDimId, forecastStatsDimIds) &&
        shouldIncludeRowByHoursOfOperation(row, openHoursPerDayPerLocation, hourDimensionId, getDimValueLabel)
      ) {
        filteredRows.push(row);
      }
    });

    return filteredRows;
  }, [productDims, archivedDimValues, rows, selectedValuesByDimId, openHoursPerDayPerLocation, getDimValueLabel]);
};

const productDimensionColumnNameToId = columnName => {
  return Number(columnName.replace('productDim', '').replace('Id', ''));
};

export const useFilteredProductCatalog = (
  products,
  selectedValuesByDimId,
  getDimValueLabel,
  openHoursPerDayPerLocation = {},
) => {
  const { dimensions: productDims } = React.useContext(DomainContext);

  const productDimCols = React.useMemo(() => {
    return products.length > 0
      ? Object.keys(products[0])
          .filter(isProductDimColumnName)
          .map(productDimensionColumnNameToId)
      : [];
  }, [products]);

  return React.useMemo(() => {
    const productDimIds = productDims
      .filter(dim => dim.dimension_type === USER_DIMENSION_TYPE)
      .map(dim => dim.product_dimension_id)
      .filter(dimId => productDimCols.includes(dimId)); // filter out product dims that are not in the product catalog

    const dimIds = [LOCATIONS_DIMENSION_ID, ...productDimIds];
    const hourDimId = productDims?.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE)?.product_dimension_id;
    const filteredProducts = [];

    products.forEach(product => {
      if (
        shouldIncludeRowByDim(product, selectedValuesByDimId, dimIds) &&
        shouldIncludeRowByHoursOfOperation(product, openHoursPerDayPerLocation, hourDimId, getDimValueLabel)
      ) {
        filteredProducts.push(product);
      }
    });

    return filteredProducts;
  }, [productDims, products, productDimCols, selectedValuesByDimId, openHoursPerDayPerLocation, getDimValueLabel]);
};

export const useSevenDayForecastWindow = (
  weeklyForecastRows,
  weeklyForecastStatsRows,
  maxDay,
  startDay = startOfToday(),
  daysToShow = 7,
) => {
  const [forecastStartDate, setForecastStartDate] = React.useState(startDay);
  const [selectedDate, setSelectedDate] = React.useState(startDay);

  const rightmostDay = React.useMemo(() => addDays(forecastStartDate, daysToShow - 1), [forecastStartDate, daysToShow]);
  const validMaxDay = React.useMemo(() => (isValid(maxDay) ? maxDay : startOfToday()), [maxDay]);

  if (validMaxDay < startDay) {
    throw new NoDataAvailableError();
  }

  const updateSelectedDate = React.useCallback(
    newStartDate => {
      const daysFromStart = differenceInCalendarDays(selectedDate, newStartDate);
      if (daysFromStart < 0 || daysFromStart >= daysToShow) {
        setSelectedDate(newStartDate);
      }
    },
    [selectedDate, daysToShow],
  );

  const onJumpToStart = React.useCallback(() => {
    setForecastStartDate(startDay);
    setSelectedDate(startDay);
  }, [startDay]);

  const onBackOneDay = React.useCallback(() => {
    const daysFromStart = differenceInCalendarDays(forecastStartDate, startDay);
    if (daysFromStart > 0) {
      const newStartDate = addDays(forecastStartDate, -1);
      setForecastStartDate(newStartDate);
      updateSelectedDate(newStartDate);
    }
  }, [forecastStartDate, startDay, updateSelectedDate]);

  const onForwardOneDay = React.useCallback(() => {
    const daysDiff = differenceInCalendarDays(validMaxDay, rightmostDay);
    if (daysDiff > 0) {
      const newStartDate = addDays(forecastStartDate, 1);
      setForecastStartDate(newStartDate);
      updateSelectedDate(newStartDate);
    }
  }, [forecastStartDate, rightmostDay, validMaxDay, updateSelectedDate]);

  const onBackRow = React.useCallback(() => {
    const daysFromStart = differenceInCalendarDays(forecastStartDate, startDay);
    if (daysFromStart >= daysToShow) {
      const newStartDate = addDays(forecastStartDate, -daysToShow);
      setForecastStartDate(newStartDate);
      updateSelectedDate(newStartDate);
    } else if (daysFromStart < daysToShow && daysFromStart > 0) {
      setForecastStartDate(startDay);
      updateSelectedDate(startDay);
    }
  }, [forecastStartDate, startDay, daysToShow, updateSelectedDate]);

  const onForwardRow = React.useCallback(() => {
    const daysDiff = differenceInCalendarDays(validMaxDay, rightmostDay);
    if (daysDiff >= daysToShow) {
      const newStartDate = addDays(forecastStartDate, daysToShow);
      setForecastStartDate(newStartDate);
      updateSelectedDate(newStartDate);
    } else if (daysDiff < daysToShow && daysDiff > 0) {
      const newStartDate = addDays(forecastStartDate, daysDiff);
      setForecastStartDate(newStartDate);
      updateSelectedDate(newStartDate);
    }
  }, [forecastStartDate, rightmostDay, validMaxDay, daysToShow, updateSelectedDate]);

  const sevenDayForecastRows = React.useMemo(() => {
    const isSevenDayForecastRow = row => {
      const { tranDate } = row;
      return tranDate >= formatAsDatasetDate(forecastStartDate) && tranDate <= formatAsDatasetDate(rightmostDay);
    };

    return weeklyForecastRows.filter(row => isSevenDayForecastRow(row));
  }, [forecastStartDate, weeklyForecastRows, rightmostDay]);

  const sevenDayForecastStatsRows = React.useMemo(() => {
    const isSevenDayForecastRow = row => {
      const { tranDate } = row;
      return tranDate >= formatAsDatasetDate(forecastStartDate) && tranDate <= formatAsDatasetDate(rightmostDay);
    };

    return weeklyForecastStatsRows.filter(row => isSevenDayForecastRow(row));
  }, [forecastStartDate, weeklyForecastStatsRows, rightmostDay]);

  return {
    forecastStartDate,
    selectedDate,
    sevenDayForecastBounds: [forecastStartDate, rightmostDay],
    setSelectedDate,
    minDayReached: differenceInCalendarDays(forecastStartDate, startDay) <= 0,
    maxDayReached: differenceInCalendarDays(rightmostDay, validMaxDay) >= 0,
    onJumpToStart,
    onBackOneDay,
    onForwardOneDay,
    onBackRow,
    onForwardRow,
    sevenDayForecastRows,
    sevenDayForecastStatsRows,
  };
};

export const useAtAGlanceData = (
  sevenDayForecastRows,
  statisticHistoryRows,
  forecastStatsSevenDayForecastRows,
  forecastStatsHistoryRows,
) => {
  const percentiles = usePercentiles(statisticHistoryRows, forecastStatsHistoryRows);
  const sevenDayForecast = useSevenDayForecast(sevenDayForecastRows, forecastStatsSevenDayForecastRows, percentiles);

  return sevenDayForecast;
};

const useFilteredScenarioRows = (rows, selectedValuesByDimId, allDashboardDimIds) => {
  return React.useMemo(() => {
    const filteredRows = [];

    rows.forEach(row => {
      if (shouldIncludeRowByDim(row, selectedValuesByDimId, allDashboardDimIds)) {
        filteredRows.push(row);
      }
    });

    return filteredRows;
  }, [rows, selectedValuesByDimId, allDashboardDimIds]);
};

const joinScenarios = (
  kaizenPricingScenario,
  recentPricingScenario,
  pricingScenarioKaizenCompareRecentBuckets,
  selectedDatasetDimValuesSets,
) => {
  let combinedScenarioData = {};
  let recentPriceKeys = [];
  if (kaizenPricingScenario.length > 0 && recentPricingScenario.length > 0) {
    combinedScenarioData = _.keyBy(kaizenPricingScenario, 'pk');

    recentPriceKeys = Object.keys(recentPricingScenario[0]).filter(key =>
      PRICE_LIKE_COLS.some(col => key.toLowerCase().includes(col)),
    );

    // the kaizen scenario has been put into the combinedScenarioData map above - here we want to
    // copy in the recent scenario data by extending each object indexed by the primary key
    recentPricingScenario.forEach(row => {
      const recentPriceValues = _.pick(row, recentPriceKeys);

      const scenarioObj = combinedScenarioData[row.pk];
      if (scenarioObj) {
        combinedScenarioData[row.pk] = _.extend(scenarioObj, recentPriceValues);
      } else {
        // this should never happen, but if we end up here for some reason just copy in the recent scenario object
        combinedScenarioData[row.pk] = row;
      }
    });
  }

  let { medium: mediumPriceChangeRatio, large: largePriceChangeRatio } = pricingScenarioKaizenCompareRecentBuckets;

  mediumPriceChangeRatio =
    mediumPriceChangeRatio > MEDIUM_PRICE_CHANGE_RATIO_CAP ? MEDIUM_PRICE_CHANGE_RATIO_CAP : mediumPriceChangeRatio;
  largePriceChangeRatio =
    largePriceChangeRatio > LARGE_PRICE_CHANGE_RATIO_CAP ? LARGE_PRICE_CHANGE_RATIO_CAP : largePriceChangeRatio;

  const getPriceChangeBucket = (price, compareToPrice) => {
    const diff = price - compareToPrice;
    const isDecrease = diff < 0;
    const ratio = Math.abs(diff) / compareToPrice;

    if (ratio === 0) {
      return NO_CHANGE;
    }
    if (ratio <= mediumPriceChangeRatio) {
      return isDecrease ? SMALL_DECREASE : SMALL_INCREASE;
    }
    if (ratio <= largePriceChangeRatio) {
      return isDecrease ? MEDIUM_DECREASE : MEDIUM_INCREASE;
    }
    return isDecrease ? LARGE_DECREASE : LARGE_INCREASE;
  };

  const pricingRows = [];
  Object.values(combinedScenarioData).forEach(row => {
    const { baselinePrice, newPrice } = row;

    row[PRICE_CHANGE_BUCKET_ID] = getPriceChangeBucket(newPrice, baselinePrice);

    const selectedValuesSet = selectedDatasetDimValuesSets[PRICE_CHANGE_BUCKET_ID];

    if (selectedValuesSet?.has(row[PRICE_CHANGE_BUCKET_ID])) {
      pricingRows.push(row);
    }
  });

  return pricingRows;
};

export const usePricingRows = (
  kaizenPricingScenario,
  recentPricingScenario,
  pricingScenarioKaizenCompareRecentBuckets,
  pricingHistory,
  selectedDatasetDimValuesSets,
) => {
  const start = performance.now();

  const { dimensions, configTimeDimState } = React.useContext(DomainContext);

  const allDashboardDimIds = React.useMemo(() => {
    const productDimIds = dimensions.map(dim => dim.product_dimension_id);

    const dimIds = [DAY_OF_WEEK_DIMENSION_ID, LOCATIONS_DIMENSION_ID, ...productDimIds];
    const { shouldIncludeDim } = configTimeDimState;
    if (shouldIncludeDim(WEEKPART_DIMENSION_ID)) {
      dimIds.push(WEEKPART_DIMENSION_ID);
    }
    if (shouldIncludeDim(SEASON_DIMENSION_ID)) {
      dimIds.push(SEASON_DIMENSION_ID);
    }
    if (shouldIncludeDim(WEEKS_SINCE_RELEASE_DIMENSION_ID)) {
      dimIds.push(WEEKS_SINCE_RELEASE_DIMENSION_ID);
    }
    return dimIds;
  }, [configTimeDimState, dimensions]);

  const filteredKaizenPricingScenario = useFilteredScenarioRows(
    kaizenPricingScenario,
    selectedDatasetDimValuesSets,
    allDashboardDimIds,
  );
  const filteredRecentPricingScenario = useFilteredScenarioRows(
    recentPricingScenario,
    selectedDatasetDimValuesSets,
    allDashboardDimIds,
  );
  const filteredPricingHistory = useFilteredScenarioRows(
    pricingHistory,
    selectedDatasetDimValuesSets,
    allDashboardDimIds,
  );

  const joinedScenarios = React.useMemo(() => {
    return joinScenarios(
      filteredKaizenPricingScenario,
      filteredRecentPricingScenario,
      pricingScenarioKaizenCompareRecentBuckets,
      selectedDatasetDimValuesSets,
    );
  }, [
    filteredKaizenPricingScenario,
    filteredRecentPricingScenario,
    pricingScenarioKaizenCompareRecentBuckets,
    selectedDatasetDimValuesSets,
  ]);

  const joinedUnfilteredScenarios = React.useMemo(() => {
    return joinScenarios(
      kaizenPricingScenario,
      recentPricingScenario,
      pricingScenarioKaizenCompareRecentBuckets,
      selectedDatasetDimValuesSets,
    );
  }, [
    kaizenPricingScenario,
    recentPricingScenario,
    pricingScenarioKaizenCompareRecentBuckets,
    selectedDatasetDimValuesSets,
  ]);

  if (!_.isEmpty(joinedScenarios)) {
    logExecutionTime('Join Scenarios', start, performance.now());
  }

  return { filteredPricingHistory, pricingRows: joinedScenarios, unfilteredPricingRows: joinedUnfilteredScenarios };
};
