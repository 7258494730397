import React from 'react';
import { Modal } from 'react-bootstrap';
import { DataSourceDisplayName } from '../../dataSources/dataSourceConstants';
import { ProfitRoverPrimaryButton, ProfitRoverSecondaryButton } from '../../forms/ProfitRoverButtons';
import { ProfitRoverModalFooter, ProfitRoverModalMain } from '../../generic/ProfitRoverCard';
import PartnerSteps from '../../setup/modern/PartnerSteps';
import './partnerInstructionsModal.scss';

const PartnerInstructionsModal = ({ partner, show, onOK, onCancel }) => {
  return (
    <Modal show={show} onHide={onCancel} centered dialogClassName="partner-instructions-modal">
      <Modal.Header>
        <Modal.Title>How to Add a New Location from {DataSourceDisplayName[partner]}</Modal.Title>
      </Modal.Header>
      <ProfitRoverModalMain>
        <PartnerSteps partner={partner} />
      </ProfitRoverModalMain>
      <ProfitRoverModalFooter className="confirmation-modal-footer">
        <ProfitRoverPrimaryButton onClick={onOK}>OK</ProfitRoverPrimaryButton>
        <ProfitRoverSecondaryButton onClick={onCancel}>Cancel</ProfitRoverSecondaryButton>
      </ProfitRoverModalFooter>
    </Modal>
  );
};

export default PartnerInstructionsModal;
