import React from 'react';
import { Modal } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import { DataSourceDisplayName } from './dataSourceConstants';
import { ProfitRoverPrimaryButton, ProfitRoverSecondaryButton } from '../forms/ProfitRoverButtons';
import { ProfitRoverModalFooter, ProfitRoverModalMain } from '../generic/ProfitRoverCard';
import './datasource-modal.scss';

const LocationConnectModal = ({ partner, show, errorText, apiKeyChange, enteredValue, onOK, onCancel }) => {
  return (
    <Modal show={show} onHide={onCancel} centered>
      <Modal.Header>
        <Modal.Title>Connect to {DataSourceDisplayName[partner]}</Modal.Title>
      </Modal.Header>
      <ProfitRoverModalMain>
        <span className="location-modal-error-text">{errorText ?? ''}</span>
        <TextareaAutosize
          className="location-modal-text-input"
          value={enteredValue}
          onChange={apiKeyChange}
          placeholder="Enter API Key"
        />
      </ProfitRoverModalMain>
      <ProfitRoverModalFooter className="confirmation-modal-footer">
        <ProfitRoverPrimaryButton onClick={onOK} disabled={enteredValue == null || enteredValue.trim() === ''}>
          OK
        </ProfitRoverPrimaryButton>
        <ProfitRoverSecondaryButton onClick={onCancel}>Cancel</ProfitRoverSecondaryButton>
      </ProfitRoverModalFooter>
    </Modal>
  );
};

export default LocationConnectModal;
