import React from 'react';
import _ from 'lodash';
import {
  ALL_HOURS,
  CLOSE_TIME,
  DAYS_OF_WEEK,
  DEFAULT_HOURS_OF_OPERATION_SETTINGS,
  IS_CLOSED,
  OPEN_24_HOURS,
  OPEN_TIME,
} from './locationManagementConstants';
import { useUpdateHoursOfOperation } from '../../../data-access/mutation/hoursOfOperation';
import { useHoursOfOperation } from '../../../data-access/query/hoursOfOperation';

export const isValidHour = hour => {
  if (hour == null || hour.length !== 5) {
    return false;
  }

  const [hourStr, minuteStr] = hour.split(':');
  const hourNum = parseInt(hourStr, 10);
  const minuteNum = parseInt(minuteStr, 10);

  if (Number.isNaN(hourNum) || Number.isNaN(minuteNum)) {
    return false;
  }

  return hourNum >= 0 && hourNum <= 23 && minuteNum >= 0 && minuteNum <= 59;
};

export const isValidHoursOfOperation = hoursOfOperation => {
  const { [OPEN_TIME]: openTime, [CLOSE_TIME]: closeTime, [IS_CLOSED]: isClosed, [OPEN_24_HOURS]: open24Hours } =
    hoursOfOperation ?? {};

  return isClosed || open24Hours || (isValidHour(openTime) && isValidHour(closeTime));
};

// e.g. if hourMinuteString is '19:30' and field is OPEN_TIME returns '19:00'
// else if field is CLOSE_TIME returns '20:00'
export const normalizeToHour = (hourMinuteString = '', field = OPEN_TIME) => {
  if (!/^([01]\d|2[0-3]):([0-5]\d)$/.test(hourMinuteString)) {
    return '';
  }

  const [hoursStr, minutesStr] = hourMinuteString.split(':');
  let hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);

  if (field === CLOSE_TIME && minutes > 0) {
    hours = (hours + 1) % 24;
  }

  const paddedHours = String(hours).padStart(2, '0');

  return `${paddedHours}:00`;
};

export const floorHour = hour => {
  const [hourStr] = hour.split(':');
  return `${hourStr}:00`;
};

export const endsOnNextDay = (open, close) => {
  const [openHourStr, openMinuteStr] = open.split(':');
  const [closeHourStr, closeMinuteStr] = close.split(':');

  const openHour = `${openHourStr}:00`;
  const closeHour = `${closeHourStr}:00`;
  const openMinute = parseInt(openMinuteStr, 10);
  const closeMinute = parseInt(closeMinuteStr, 10);

  return (
    ALL_HOURS.includes(openHour) &&
    ALL_HOURS.includes(closeHour) &&
    openMinute >= 0 &&
    closeMinute >= 0 &&
    openMinute < 60 &&
    closeMinute < 60 &&
    (ALL_HOURS.indexOf(openHour) > ALL_HOURS.indexOf(closeHour) ||
      (ALL_HOURS.indexOf(openHour) === ALL_HOURS.indexOf(closeHour) && closeMinute <= openMinute))
  );
};

export const areHoursOfOperationEqual = (hoursOfOperation1, hoursOfOperation2) => {
  const { [IS_CLOSED]: isClosed1, [OPEN_24_HOURS]: open24Hours1, [OPEN_TIME]: openTime1, [CLOSE_TIME]: closeTime1 } =
    hoursOfOperation1 ?? {};
  const { [IS_CLOSED]: isClosed2, [OPEN_24_HOURS]: open24Hours2, [OPEN_TIME]: openTime2, [CLOSE_TIME]: closeTime2 } =
    hoursOfOperation2 ?? {};

  return (
    (isClosed1 && isClosed2) ||
    (open24Hours1 && open24Hours2) ||
    (!isClosed1 && !isClosed2 && !open24Hours1 && !open24Hours2 && openTime1 === openTime2 && closeTime1 === closeTime2)
  );
};

export const HOURS_OVERLAP_ERROR_CODE = 'hours_overlap';
export const INVALID_HOURS_ERROR_CODE = 'invalid_hours';

export const validateLocationHoursOfOperation = locationHoursOfOperation => {
  if (!_.every(locationHoursOfOperation.map(isValidHoursOfOperation))) {
    return { code: INVALID_HOURS_ERROR_CODE, fields: [] };
  }

  const overlapFields = [];
  locationHoursOfOperation.forEach((data, index) => {
    const {
      [IS_CLOSED]: isClosed,
      [OPEN_24_HOURS]: open24Hours,
      [OPEN_TIME]: openTime,
      [CLOSE_TIME]: closeTime,
    } = data;
    if (isClosed || open24Hours || !endsOnNextDay(openTime, closeTime)) {
      return;
    }

    const nextDayIndex = (index + 1) % locationHoursOfOperation.length;
    const {
      [OPEN_TIME]: nextDayOpenTime,
      [IS_CLOSED]: nextDayIsClosed,
      [OPEN_24_HOURS]: nextDayOpen24,
      day: nextDay,
    } = locationHoursOfOperation[nextDayIndex];
    if (nextDayIsClosed) {
      return;
    }

    const closeHour = normalizeToHour(closeTime ?? '', CLOSE_TIME);
    const nextDayOpenHour = normalizeToHour(nextDayOpenTime, OPEN_TIME);
    if (nextDayOpen24 || ALL_HOURS.indexOf(closeHour) > ALL_HOURS.indexOf(nextDayOpenHour)) {
      overlapFields.push({ day: data.day, field: CLOSE_TIME });
      overlapFields.push({ day: nextDay, field: OPEN_TIME });
    }
  });

  if (overlapFields.length > 0) {
    return { code: HOURS_OVERLAP_ERROR_CODE, fields: overlapFields };
  }

  return null;
};

export const useLocationsHoursOfOperation = locations => {
  const [selectedLocation, setSelectedLocation] = React.useState(locations[0]);
  const [locationHoursOfOperation, setLocationHoursOfOperation] = React.useState([]);
  const [error, setError] = React.useState(null); // { code: '', fields: [ { day: '', field: '' } ] }
  const [showUnsavedIncompleteChangesModal, setShowUnsavedIncompleteChangesModal] = React.useState(false);
  const [showUnsavedValidChangesModal, setShowUnsavedValidChangesModal] = React.useState(false);
  const [currentlySavedHours, setCurrentlySavedHours] = React.useState({});
  const [hasPendingChanges, setHasPendingChanges] = React.useState(false);
  const pendingLocationChange = React.useRef(null);

  const {
    data: currentHoursOfOperation = [],
    isLoading: hoursOfOperationIsLoading,
    isFetching: hoursOfOperationIsFetching,
  } = useHoursOfOperation();
  const { mutate: updateHoursOfOperation, isLoading: updateLoading } = useUpdateHoursOfOperation();

  React.useEffect(() => {
    const locationIds = locations.map(location => location.location_id);

    if (!selectedLocation || !locationIds.includes(selectedLocation?.location_id)) {
      setSelectedLocation(locations[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  React.useEffect(() => {
    const newLocationHoursOfOperation = [];
    const { hours_of_operation_by_day: hoursByDay = [] } =
      currentHoursOfOperation.find(({ location_id: locationId }) => locationId === selectedLocation?.location_id) ?? {};

    DAYS_OF_WEEK.forEach(day => {
      const { hours_of_operation: dayHoursArray = [] } =
        hoursByDay.find(({ day_of_week: dayOfWeek }) => dayOfWeek === day) ?? {};
      const [dayHours = {}] = dayHoursArray; // There should only be one set of hours per day

      newLocationHoursOfOperation.push({
        day,
        [OPEN_TIME]: dayHours[OPEN_TIME] ?? DEFAULT_HOURS_OF_OPERATION_SETTINGS[OPEN_TIME],
        [CLOSE_TIME]: dayHours[CLOSE_TIME] ?? DEFAULT_HOURS_OF_OPERATION_SETTINGS[CLOSE_TIME],
        [IS_CLOSED]: dayHours[IS_CLOSED] ?? DEFAULT_HOURS_OF_OPERATION_SETTINGS[IS_CLOSED],
        [OPEN_24_HOURS]: dayHours[OPEN_24_HOURS] ?? DEFAULT_HOURS_OF_OPERATION_SETTINGS[OPEN_24_HOURS],
      });
    });

    setLocationHoursOfOperation(newLocationHoursOfOperation);
    setCurrentlySavedHours(_.cloneDeep(newLocationHoursOfOperation));
    setError(null);
    setHasPendingChanges(false);
    localStorage.removeItem('unsaved_page');
  }, [currentHoursOfOperation, selectedLocation]);

  React.useEffect(() => {
    setError(validateLocationHoursOfOperation(locationHoursOfOperation));
  }, [locationHoursOfOperation]);

  const setHoursOfOperationForDay = React.useCallback(
    (day, value) => {
      setLocationHoursOfOperation(prev => {
        const newLocationHoursOfOperation = [...prev];

        const dayIndex = newLocationHoursOfOperation.findIndex(data => data.day === day);
        const newDayData = { ...newLocationHoursOfOperation[dayIndex], ...value };

        newLocationHoursOfOperation[dayIndex] = newDayData;

        if (_.isEqual(newLocationHoursOfOperation, currentlySavedHours)) {
          setHasPendingChanges(false);
        } else {
          setHasPendingChanges(true);
        }
        localStorage.setItem('unsaved_page', '/location-settings');

        return newLocationHoursOfOperation;
      });
    },
    [currentlySavedHours],
  );

  const saveHoursOfOperation = React.useCallback(() => {
    const getSelectedLocationHoursOfOperation = () => {
      const newHoursByDay = [];

      locationHoursOfOperation.forEach(data => {
        newHoursByDay.push({
          day_of_week: data.day,
          hours_of_operation: [
            {
              [OPEN_TIME]: data[OPEN_TIME],
              [CLOSE_TIME]: data[CLOSE_TIME],
              [IS_CLOSED]: data[IS_CLOSED],
              [OPEN_24_HOURS]: data[OPEN_24_HOURS],
            },
          ],
        });
      });

      return {
        location_id: selectedLocation.location_id,
        hours_of_operation_by_day: newHoursByDay,
      };
    };

    const selectedLocationHoursOfOperation = getSelectedLocationHoursOfOperation();

    const payload = currentHoursOfOperation.filter(
      ({ location_id: locationId }) => locationId !== selectedLocation.location_id,
    );
    payload.push(selectedLocationHoursOfOperation);

    updateHoursOfOperation(payload, {
      onSuccess: () => setCurrentlySavedHours(_.cloneDeep(locationHoursOfOperation)),
    });
  }, [selectedLocation, updateHoursOfOperation, currentHoursOfOperation, locationHoursOfOperation]);

  const selectLocationIfNoPendingChanges = location => {
    if (!hasPendingChanges) {
      setSelectedLocation(location);
      return;
    }

    pendingLocationChange.current = location;
    if (error == null) {
      setShowUnsavedValidChangesModal(true);
    } else {
      setShowUnsavedIncompleteChangesModal(true);
    }
  };

  const discardChanges = () => {
    setShowUnsavedIncompleteChangesModal(false);
    setShowUnsavedValidChangesModal(false);

    if (pendingLocationChange.current) {
      setSelectedLocation(pendingLocationChange.current);
      pendingLocationChange.current = null;
    }
  };

  const cancelLocationSelection = () => {
    setShowUnsavedIncompleteChangesModal(false);
    setShowUnsavedValidChangesModal(false);
    pendingLocationChange.current = null;
  };

  const onSave = () => {
    setShowUnsavedValidChangesModal(false);
    saveHoursOfOperation();
  };

  return {
    locations,
    selectedLocation,
    setSelectedLocation: selectLocationIfNoPendingChanges,
    locationHoursOfOperation,
    setLocationHoursOfOperation: setHoursOfOperationForDay,
    hoursOfOperationIsLoading,
    saveHoursOfOperation,
    isUpdating: hoursOfOperationIsFetching || updateLoading,
    error,
    isError: error != null,
    showUnsavedIncompleteChangesModal,
    showUnsavedValidChangesModal,
    discardChanges,
    cancelLocationSelection,
    onSave,
    hasPendingChanges,
  };
};
