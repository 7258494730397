/* eslint-disable no-unused-vars */
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PerformanceBarChart from './charts/PerformanceBarChart';
import PerformanceLineChart, { PERFORMANCE_LINE_CHART_COLORS } from './charts/PerformanceLineChart';
import { PerformanceToolbar } from './FiltersToolbar';
import PerformanceTabContext from './performanceTabContext';
import {
  GRAPH_TIME_GRANULARITY_LABELS,
  METRIC_OPTIONS,
  MONTH_NAMES,
  PERIOD_OPTIONS,
  SALES_QUANTITY,
  WEEKDAY_NAMES,
} from './performanceConstants';
import { MONTH, QUARTER, WEEK, YEAR, parseRelativeDates } from './relativeDates';
import { DashboardContext, DomainContext } from './revenueUpliftContexts';
import usePerformanceData from './usePerformanceData';
import { ProfitRoverCard } from '../../../generic/ProfitRoverCard';
import { CenteredProfitRoverSpinner } from '../../../spinner/ProfitRoverSpinner';
import {
  DateType,
  compactFormatNumber,
  shortDay,
  shortDayYear,
  shortMonth,
  shortQuarter,
  shortRange,
  shortYear,
} from '../../../util/format';
import { usePerformanceForecastMeta } from '../../../../data-access/query/priceStats';
import './performance-tab.scss';

const formatBarGraphTitle = (selectedPeriod, daySelected, cumulativeTotals) => {
  switch (selectedPeriod.value) {
    case WEEK: {
      const date = daySelected.toLocaleDateString('en-US', {
        weekday: 'long',
      });
      return cumulativeTotals ? `Thru ${date}` : date;
    }
    case MONTH: {
      const date = daySelected.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
      return cumulativeTotals ? `Thru ${date}` : date;
    }
    case QUARTER: {
      const date = daySelected.toLocaleDateString('en-US', {
        month: 'short',
        year: 'numeric',
      });
      return cumulativeTotals ? `Thru ${date}` : date;
    }
    case YEAR: {
      const date = daySelected.toLocaleDateString('en-US', {
        month: 'short',
      });
      return cumulativeTotals ? `Thru ${date}` : date;
    }
    default:
      return '';
  }
};

const DateControl = () => {
  const { selectedPeriod } = React.useContext(PerformanceTabContext);

  const formatSectionTitle = () => {
    switch (selectedPeriod.value) {
      case WEEK:
        return 'Current Week';
      case MONTH:
        return 'Current Month';
      case QUARTER:
        return 'Current Quarter';
      case YEAR:
        return 'Current Year';
      default:
        return '';
    }
  };

  return (
    <div className="date-control-container">
      {/* <button type="button" className="button" onClick={() => {}} disabled={false}>
        <FontAwesomeIcon icon={faAngleLeft} />
      </button> */}
      <h2 className="title">{formatSectionTitle()}</h2>
      {/* <button type="button" className="button" onClick={() => {}} disabled>
        <FontAwesomeIcon icon={faAngleRight} />
      </button> */}
    </div>
  );
};

const TODAY = new Date();

const PerformanceTab = () => {
  const {
    selectedPeriod,
    setSelectedPeriod,
    selectedMetric,
    setSelectedMetric,
    cumulativeTotals,
    setCumulativeTotals,
    performanceMetadata,
  } = React.useContext(PerformanceTabContext);

  const { currencySymbol, locale } = React.useContext(DashboardContext);
  const [daySelected, setDaySelected] = React.useState(TODAY);
  const { relative_dates: relativeDateStrings } = performanceMetadata;
  const relativeDates = React.useMemo(() => parseRelativeDates(relativeDateStrings), [relativeDateStrings]);

  React.useEffect(() => {
    // reset day selected when period/metric/cumulative changes (bar graph)
    switch (selectedPeriod.value) {
      case WEEK:
        return setDaySelected(relativeDates.weekEnd);
      case MONTH:
        return setDaySelected(relativeDates.monthEnd);
      case QUARTER:
        return setDaySelected(relativeDates.quarterEnd);
      case YEAR:
        return setDaySelected(relativeDates.yearEnd);
      default:
        return setDaySelected(TODAY);
    }
  }, [cumulativeTotals, relativeDates, selectedPeriod.value, selectedMetric.value]);

  const graphNumberFormatter = React.useCallback(
    value =>
      compactFormatNumber(value, {
        formatAsCurrency: selectedMetric.value !== SALES_QUANTITY,
        currencySymbol,
        fixedDecimalDigits: 1,
      }),
    [currencySymbol, selectedMetric.value],
  );

  const dateFormatter = React.useCallback(
    (date, { endDate, type } = {}) => {
      let formattedDate;

      if (endDate) {
        return shortRange(date, endDate, locale);
      }

      switch (type) {
        case DateType.DAY:
          formattedDate = shortDay(date, locale);
          break;
        case DateType.DAY_YEAR:
          formattedDate = shortDayYear(date, locale);
          break;
        case DateType.MONTH:
          formattedDate = shortMonth(date, locale);
          break;
        case DateType.QUARTER:
          formattedDate = shortQuarter(date, locale);
          break;
        case DateType.YEAR:
          formattedDate = shortYear(date, locale);
          break;
        default:
          formattedDate = shortDay(date, locale);
      }
      return formattedDate;
    },
    [locale],
  );

  const { lineChartData, cumulativeLineChartData, barChartData, cumulativeBarChartData } = usePerformanceData(
    selectedPeriod.value,
    selectedMetric.value,
    daySelected,
    relativeDates,
    graphNumberFormatter,
    dateFormatter,
  );

  const lineGraphData = cumulativeTotals ? cumulativeLineChartData : lineChartData;
  const barGraphData = cumulativeTotals ? cumulativeBarChartData : barChartData;

  const dragInitialPosition = React.useMemo(() => {
    switch (selectedPeriod.value) {
      case WEEK:
        return WEEKDAY_NAMES[(relativeDates.weekEnd.getDay() + 6) % 7];
      case MONTH:
        return relativeDates.monthEnd.getDate();
      case QUARTER:
        return MONTH_NAMES[relativeDates.quarterEnd.getMonth()];
      case YEAR:
        return MONTH_NAMES[relativeDates.yearEnd.getMonth()];
      default:
        return relativeDates.monthEnd.getDate();
    }
  }, [relativeDates, selectedPeriod.value]);

  const onDragChange = React.useCallback(
    value => {
      setDaySelected(prevDate => {
        const year = prevDate.getFullYear();
        const month = prevDate.getMonth();
        switch (selectedPeriod.value) {
          case WEEK: {
            // Calculate Monday of the current week.
            const currentDay = prevDate.getDay(); // Sunday = 0, Monday = 1, etc.
            const offsetToMonday = currentDay === 0 ? -6 : 1 - currentDay;
            const monday = new Date(year, month, prevDate.getDate() + offsetToMonday);
            const weekdayIndex = WEEKDAY_NAMES.indexOf(value);
            return new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + weekdayIndex);
          }
          case MONTH:
            // 'value' is a day number (from 1 to 31).
            return new Date(year, month, value);
          case QUARTER:
          case YEAR: {
            // 'value' is one of ['Jan', 'Feb', ..., 'Dec'].
            const monthIndex = MONTH_NAMES.indexOf(value);
            return new Date(year, monthIndex, 1);
          }
          default:
            return prevDate;
        }
      });
    },
    [selectedPeriod],
  );

  return (
    <div className="performance-tab">
      <div className="my-2">
        <PerformanceToolbar
          periodOptions={PERIOD_OPTIONS}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
          metricOptions={METRIC_OPTIONS}
          selectedMetric={selectedMetric}
          setSelectedMetric={setSelectedMetric}
          cumulativeTotals={cumulativeTotals}
          setCumulativeTotals={setCumulativeTotals}
        />
      </div>
      <ProfitRoverCard className="performance-card">
        <div className="performance-card-content">
          <DateControl />
          <div className="graph-container">
            <div className="line-graph-content">
              <PerformanceLineChart
                data={lineGraphData}
                numberFormatter={graphNumberFormatter}
                yLabel={cumulativeTotals ? `Cumulative ${selectedMetric.label}` : selectedMetric.label}
                xLabel={GRAPH_TIME_GRANULARITY_LABELS[selectedPeriod.value]}
                onDragChange={onDragChange}
                dragInitialPosition={dragInitialPosition}
              />
            </div>
            <div className="vertical-divider" />
            <div className="bar-graph-content">
              <h4 className="title">{formatBarGraphTitle(selectedPeriod, daySelected, cumulativeTotals)}</h4>
              <div className="bar-graph">
                <PerformanceBarChart data={barGraphData} numberFormatter={graphNumberFormatter} />
              </div>
            </div>
          </div>
          <div className="legend-container">
            <div className="legend-item">
              <span className="legend-dashed-line" />
              <span className="legend-text">Forecast</span>
            </div>
            {lineGraphData.map((series, index) => (
              <div key={index} className="legend-item">
                <span className="legend-line" style={{ backgroundColor: PERFORMANCE_LINE_CHART_COLORS[index] }} />
                <span className="legend-text">{series.legend}</span>
              </div>
            ))}
          </div>
        </div>
      </ProfitRoverCard>
    </div>
  );
};

const PerformanceTabContextController = ({ children, performanceMetadata }) => {
  const [selectedPeriod, setSelectedPeriod] = React.useState(PERIOD_OPTIONS[1]);
  const [selectedMetric, setSelectedMetric] = React.useState(METRIC_OPTIONS[0]);
  const [cumulativeTotals, setCumulativeTotals] = React.useState(true);

  const contextValue = {
    selectedPeriod,
    setSelectedPeriod,
    selectedMetric,
    setSelectedMetric,
    cumulativeTotals,
    setCumulativeTotals,
    performanceMetadata,
  };

  return <PerformanceTabContext.Provider value={contextValue}>{children}</PerformanceTabContext.Provider>;
};

const PerformanceTabContainer = ({ isActive }) => {
  const { workflowId } = React.useContext(DomainContext);
  const { isLoadingPerformance } = React.useContext(DashboardContext);

  const { data: performanceMetadata = {}, isLoading: isLoadingPerformanceMeta } = usePerformanceForecastMeta(
    workflowId,
    {
      enabled: isActive,
    },
  );

  if (isLoadingPerformance || isLoadingPerformanceMeta) {
    return (
      <div className="hours-tab h-100">
        <CenteredProfitRoverSpinner />
      </div>
    );
  }

  return (
    <PerformanceTabContextController performanceMetadata={performanceMetadata}>
      <PerformanceTab />
    </PerformanceTabContextController>
  );
};

export default PerformanceTabContainer;
