import { MONTH, QUARTER, WEEK, YEAR } from './relativeDates';

export const GROSS_SALES = 'GROSS_SALES';
export const SALES_QUANTITY = 'SALES_QUANTITY';
export const TIPS = 'TIPS';

export const METRIC_OPTIONS = [
  {
    value: GROSS_SALES,
    label: 'Gross Sales',
  },
  {
    value: SALES_QUANTITY,
    label: 'Sales Qty',
  },
  // {
  //   value: TIPS,
  //   label: 'Tips',
  // },
];

export const METRIC_TO_GRAPH_TYPE = {
  [GROSS_SALES]: 'revenue',
  [SALES_QUANTITY]: 'demand',
  // [TIPS]: 'tips',
};

export const PERIOD_OPTIONS = [
  {
    value: WEEK,
    label: 'Week',
  },
  {
    value: MONTH,
    label: 'Month',
  },
  {
    value: QUARTER,
    label: 'Quarter',
  },
  {
    value: YEAR,
    label: 'Year',
  },
];

export const GRAPH_TIME_GRANULARITY_LABELS = {
  [WEEK]: 'Day',
  [MONTH]: 'Day',
  [QUARTER]: 'Month',
  [YEAR]: 'Month',
};

export const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const WEEKDAY_NAMES = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
