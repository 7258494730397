import React from 'react';
import PartnerInstructionsModal from '../../accountSettings/subscriptionManagement/PartnerInstructionsModal';
import LocationConnectModal from '../../dataSources/LocationConnectModal';
import AddressDetailsModal from '../../dashboards/eo/revenueUplift/AddressDetailsModal';
import { CountryDisplay } from '../../generic/countryConstants';
import { useCreateLocation } from '../../../data-access/mutation/locations';
import { usePartnerLocationSettings } from '../../../data-access/query/partner';
import { getWeatherLocationInfo } from '../../../data-access/query/kdl/weather';

const PartnerConnection = ({
  partner,
  onConnected,
  open,
  enableInstructionsModal,
  onCancel,
  onValidateApiKey,
  locationNaicsCode,
  locationKaCode,
}) => {
  const createLocation = useCreateLocation();

  const [showPartnerInstructionsModal, setShowPartnerInstructionsModal] = React.useState(false);
  const [showLocationConnectModal, setShowLocationConnectModal] = React.useState(false);
  const [showAddressDetails, setShowAddressDetails] = React.useState(false);
  const [partnerApiKey, setPartnerApiKey] = React.useState('');
  const [apiKey, setApiKey] = React.useState('');
  const [locationConnectOK, setLocationConnectOK] = React.useState(false);
  const [locationConnectError, setLocationConnectError] = React.useState(null);
  const [selectedLocation, setSelectedLocation] = React.useState(null);
  const [addLocationFromPartner, setAddLocationFromPartner] = React.useState(false);

  // Show instructions modal first if enabled
  React.useEffect(() => {
    if (enableInstructionsModal && open) {
      setShowPartnerInstructionsModal(true);
    } else if (open) {
      setShowLocationConnectModal(true);
    }
  }, [enableInstructionsModal, open]);

  const cancel = () => {
    setPartnerApiKey('');
    setApiKey('');
    if (onCancel) {
      onCancel();
    }
  };

  const onCancelPartnerInstructions = () => {
    setShowPartnerInstructionsModal(false);
    cancel();
  };

  const onOkPartnerInstructions = () => {
    setShowPartnerInstructionsModal(false);
    setShowLocationConnectModal(true);
  };

  const {
    data: locationSettings,
    isLoading: isLoadingPartnerLocationSettings,
    error: locationSettingsError,
  } = usePartnerLocationSettings(partner, apiKey, { enabled: apiKey !== '', retry: false });

  const transformConnectedLocation = React.useCallback(() => {
    if (!isLoadingPartnerLocationSettings && locationSettings) {
      const { name, address, city, state, zip, timezone } = locationSettings;
      setSelectedLocation({
        location_description: name,
        location_identifier: apiKey,
        address,
        city,
        state,
        zip,
        timezone,
        country_code: 'US',
      });
    }
  }, [isLoadingPartnerLocationSettings, locationSettings, apiKey]);

  React.useEffect(() => {
    if (locationSettingsError) {
      setApiKey('');
      setPartnerApiKey('');
      setLocationConnectOK(false);
      setLocationConnectError('Something went wrong. Please re-enter your API key.');
      setShowLocationConnectModal(true);
    }
  }, [locationSettingsError]);

  React.useEffect(() => {
    if (!isLoadingPartnerLocationSettings && locationSettings && locationConnectOK) {
      setLocationConnectError(null);
      transformConnectedLocation();
      setShowAddressDetails(true);
    }
  }, [isLoadingPartnerLocationSettings, locationConnectOK, locationSettings, transformConnectedLocation]);

  const handleApiKeyChange = event => {
    setPartnerApiKey(event.target.value);
  };

  const finishLocationConnect = () => {
    if (partnerApiKey) {
      const apiKeyValid = onValidateApiKey ? onValidateApiKey(partnerApiKey) : true;
      if (apiKeyValid) {
        setApiKey(partnerApiKey);
        setShowLocationConnectModal(false);
        setLocationConnectOK(true);
      } else {
        setLocationConnectError('You have already used this API key.');
        setLocationConnectOK(false);
      }
    } else {
      setLocationConnectError('Please enter an API key.');
      setLocationConnectOK(false);
      setShowLocationConnectModal(true);
    }
  };

  const onCancelLocationConnectModal = () => {
    setShowLocationConnectModal(false);
    setLocationConnectOK(false);
    setLocationConnectError(null);
    cancel();
  };

  const onOkAddressDetails = locationDetails => {
    setShowAddressDetails(false);
    const updatedLocation = {
      ...selectedLocation,
      ...locationDetails,
      naics_code: locationNaicsCode,
      ka_code: locationKaCode,
    };
    setSelectedLocation(updatedLocation);
    setAddLocationFromPartner(true);
  };

  const onCancelAddressDetails = () => {
    setShowAddressDetails(false);
    setLocationConnectOK(false);
    cancel();
  };

  const finishLocationDatasource = React.useCallback(async () => {
    if (addLocationFromPartner && selectedLocation) {
      const { city, state, zip } = selectedLocation;
      const weatherLocationResponse = await getWeatherLocationInfo(city, state, zip, CountryDisplay.US);
      const { weatherLocationId } = weatherLocationResponse;

      const location = {
        ...selectedLocation,
        kdl_weather_location_id: weatherLocationId,
      };

      await createLocation.mutateAsync(location);

      if (onConnected) {
        onConnected(location);
      }
    }
  }, [addLocationFromPartner, createLocation, onConnected, selectedLocation]);

  React.useEffect(() => {
    if (addLocationFromPartner) {
      finishLocationDatasource();
      setAddLocationFromPartner(false);
      setPartnerApiKey('');
      setApiKey('');
    }
  }, [addLocationFromPartner, finishLocationDatasource]);

  return (
    <>
      <PartnerInstructionsModal
        partner={partner}
        show={showPartnerInstructionsModal}
        onOK={onOkPartnerInstructions}
        onCancel={onCancelPartnerInstructions}
      />
      <LocationConnectModal
        partner={partner}
        show={showLocationConnectModal}
        errorText={locationConnectError}
        apiKeyChange={handleApiKeyChange}
        enteredValue={partnerApiKey}
        onOK={finishLocationConnect}
        onCancel={onCancelLocationConnectModal}
      />
      {showAddressDetails && (
        <AddressDetailsModal
          selectedLocation={selectedLocation}
          onHideAddressDetailsModal={onCancelAddressDetails}
          onUpdateLocation={onOkAddressDetails}
          isSubmittingTrialLocationSelection={false}
          headerLine1Override="Confirm address details"
          headerLine2Override=""
          cancelButtonLabelOverride="Cancel"
        />
      )}
    </>
  );
};

export default PartnerConnection;
