import React from 'react';
import * as d3 from 'd3';
import ResizeDetector from './ResizeDetector';
import Tooltip from './Tooltip';
import { FONT_BLACK, LIGHT_COOL_GRAY } from '../../../../../colors';
import './performance-charts.scss';

export const PERFORMANCE_LINE_CHART_COLORS = ['#64f884', '#0d729e', '#888888'];

const PerformanceLineChart = ({
  data,
  numberFormatter,
  yLabel,
  xLabel,
  width,
  height,
  onDragChange,
  dragInitialPosition = undefined,
}) => {
  const svgRef = React.useRef();
  const tooltipRef = React.useRef(null);
  const [tooltipContent, setTooltipContent] = React.useState('');
  const [tooltipPosition, setTooltipPosition] = React.useState({ x: 0, y: 0 });

  React.useLayoutEffect(() => {
    const handleMouseOver = (content, x, y) => {
      setTooltipPosition({ x, y });
      setTooltipContent(content);
    };

    const handleMouseOut = () => {
      setTooltipContent('');
    };

    const splitSegments = dataPoints => {
      const segments = [];
      let currentSegment = [];
      for (let i = 0; i < dataPoints.length; i++) {
        const point = dataPoints[i];
        if (currentSegment.length === 0) {
          currentSegment.push(point);
        } else if (point.dashed === currentSegment[currentSegment.length - 1].dashed) {
          currentSegment.push(point);
        } else {
          // Add the boundary point to both segments to ensure continuity
          currentSegment.push(point);
          segments.push(currentSegment);
          currentSegment = [point];
        }
      }
      if (currentSegment.length) {
        segments.push(currentSegment);
      }
      return segments;
    };

    const svg = d3
      .select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .style('font-family', 'URWDIN');

    // Clear previous content
    svg.selectAll('*').remove();

    // Margin to accommodate axes and labels
    const margin = { top: 20, right: 60, bottom: 50, left: 65 };

    // Get all x values from all series
    const allXValues = data.flatMap(series => series.data.map(d => d.x));
    const isXNumeric = allXValues.every(x => typeof x === 'number');

    // Scale for the x axis: if it is numeric, linear scale; if it is a string, point scale
    let xScale;
    if (isXNumeric) {
      const uniqueXValues = Array.from(new Set(allXValues)).sort((a, b) => a - b);
      xScale = d3
        .scalePoint()
        .domain(uniqueXValues)
        .range([margin.left, width - margin.right]);
    } else {
      const uniqueXValues = Array.from(new Set(allXValues));
      xScale = d3
        .scalePoint()
        .domain(uniqueXValues)
        .range([margin.left, width - margin.right])
        .padding(0.5);
    }

    // Scale for the y axis (always numeric)
    const allYValues = data.flatMap(series => series.data.map(d => d.y));
    const maxY = d3.max(allYValues) || 0;
    const yScale = d3
      .scaleLinear()
      .domain([0, maxY])
      .range([height - margin.bottom, margin.top]);

    // Line generator
    const lineGenerator = d3
      .line()
      .x(d => xScale(d.x))
      .y(d => yScale(d.y));

    // Main group for content
    const g = svg.append('g');

    // Draw horizontal grid lines (y axis ticks)
    const yTicks = yScale.ticks(5);
    yTicks.forEach(tick => {
      g.append('line')
        .attr('x1', margin.left)
        .attr('y1', yScale(tick))
        .attr('x2', width - margin.right)
        .attr('y2', yScale(tick))
        .attr('stroke', LIGHT_COOL_GRAY)
        .attr('opacity', 0.7);
    });

    // Y axis
    const yAxisG = svg
      .append('g')
      .attr('transform', `translate(${margin.left},0)`)
      .call(
        d3
          .axisLeft(yScale)
          .ticks(5)
          .tickSizeOuter(0)
          .tickFormat(numberFormatter),
      );

    yAxisG
      .selectAll('path')
      .attr('stroke', LIGHT_COOL_GRAY)
      .attr('opacity', 0.7);
    yAxisG.selectAll('line').attr('opacity', 0.7);
    yAxisG
      .selectAll('text')
      .attr('fill', FONT_BLACK)
      .attr('dy', '0.35em');

    // X axis
    const xAxisG = svg
      .append('g')
      .attr('transform', `translate(0, ${height - margin.bottom})`)
      .call(d3.axisBottom(xScale));

    xAxisG
      .selectAll('path')
      .attr('stroke', LIGHT_COOL_GRAY)
      .attr('opacity', 0.7);
    xAxisG.selectAll('line').attr('opacity', 0.7);
    xAxisG
      .selectAll('text')
      .attr('fill', FONT_BLACK)
      .attr('dy', '0.35em');

    // Define the variable first
    let draggableLine;
    let prevClosestValue = null;

    // Calculate the unique value and initial position for the bar
    const uniqueXValues = Array.from(new Set(allXValues)).sort((a, b) => a - b);
    const initialXValue = dragInitialPosition || uniqueXValues[0];
    const dragLineInitialPosition = xScale(initialXValue);

    // Function to get the closest x value
    const getClosestX = xPos =>
      uniqueXValues.reduce((prev, curr) =>
        Math.abs(xScale(curr) - xPos) < Math.abs(xScale(prev) - xPos) ? curr : prev,
      );

    // Hit area width - 4 pixels on each side
    const hitAreaWidth = 8;
    let hitArea;

    // Create the drag behavior
    const drag = d3.drag().on('drag', () => {
      const [newX] = d3.mouse(svg.node());
      const clampedX = Math.max(margin.left, Math.min(width - margin.right, newX));
      const closestValue = getClosestX(clampedX);
      const snappedX = xScale(closestValue);
      draggableLine.attr('x1', snappedX).attr('x2', snappedX);
      hitArea.attr('x', snappedX - hitAreaWidth / 2);
      if (closestValue !== prevClosestValue && typeof onDragChange === 'function') {
        prevClosestValue = closestValue;
        onDragChange(closestValue);
      }
    });

    // Create the invisible hit area
    hitArea = svg
      .append('rect')
      .attr('x', dragLineInitialPosition - hitAreaWidth / 2)
      .attr('y', margin.top)
      .attr('width', hitAreaWidth)
      .attr('height', height - margin.bottom)
      .style('fill', 'transparent') // Make it invisible
      .style('cursor', 'ew-resize')
      .style('pointer-events', 'all')
      .call(drag);

    // Create the draggable line
    draggableLine = svg
      .append('line')
      .attr('x1', dragLineInitialPosition)
      .attr('x2', dragLineInitialPosition)
      .attr('y1', margin.top)
      .attr('y2', height - margin.bottom)
      .attr('stroke', 'red')
      .attr('stroke-width', 2)
      .style('pointer-events', 'none'); // Prevent line from directly receiving events

    // Bring line to front
    draggableLine.raise();

    // Draw each line of each series
    data.forEach((series, i) => {
      const segments = splitSegments(series.data);
      segments.forEach(segment => {
        g.append('path')
          .datum(segment)
          .attr('fill', 'none')
          .attr('stroke', PERFORMANCE_LINE_CHART_COLORS[i])
          .attr('stroke-width', 2)
          .attr('d', lineGenerator)
          .attr('stroke-dasharray', segment[0].dashed ? '8,6' : null);
      });
    });

    // Draw circles for each point of each series and associate hover events
    const circleRadius = 4;
    data.forEach((series, i) => {
      series.data.forEach(point => {
        g.append('circle')
          .attr('cx', xScale(point.x))
          .attr('cy', yScale(point.y))
          .attr('r', circleRadius)
          .attr('fill', PERFORMANCE_LINE_CHART_COLORS[i])
          .style('pointer-events', 'all')
          .on('mouseover', () => handleMouseOver(point.tooltip, xScale(point.x) + circleRadius, yScale(point.y)))
          .on('mouseout', handleMouseOut);
      });
    });

    // Add Y axis label (rotated, read from bottom to top)
    svg
      .append('text')
      .attr('transform', `rotate(-90)`)
      .attr('x', -(margin.top + (height - margin.top - margin.bottom) / 2))
      .attr('y', margin.left - 50)
      .attr('fill', FONT_BLACK)
      .attr('text-anchor', 'middle')
      .text(yLabel);

    // Add X axis label (below the chart)
    svg
      .append('text')
      .attr('x', margin.left + (width - margin.left - margin.right) / 2)
      .attr('y', height - margin.bottom + 40)
      .attr('fill', FONT_BLACK)
      .attr('text-anchor', 'middle')
      .text(xLabel);
  }, [data, numberFormatter, yLabel, xLabel, width, height, onDragChange, dragInitialPosition]);

  return (
    <>
      <svg ref={svgRef} />
      {tooltipContent && <Tooltip ref={tooltipRef} {...tooltipPosition} content={tooltipContent} />}
    </>
  );
};

const PerformanceChartContainer = ({ data, numberFormatter, yLabel, xLabel, onDragChange, dragInitialPosition }) => (
  <ResizeDetector>
    {({ height, width }) => (
      <div className="chart" style={{ height, width }}>
        <PerformanceLineChart
          data={data}
          numberFormatter={numberFormatter}
          yLabel={yLabel}
          xLabel={xLabel}
          width={width}
          height={height}
          onDragChange={onDragChange}
          dragInitialPosition={dragInitialPosition}
        />
      </div>
    )}
  </ResizeDetector>
);

export default PerformanceChartContainer;
