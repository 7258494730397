/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query';
import { DataSource } from '../../components/dataSources/dataSourceConstants';
import { KA_API_ROOT_URL } from '../../config/baseUrl';
import axios from '../../utils/axios-client';

/**
 * Requests
 */

const getHeartlandLocationSettings = async apiKey =>
  axios.get(`${KA_API_ROOT_URL}/heartland/location_settings`, {
    params: {
      api_key: apiKey,
    },
  });

const PARTNER_API_CALLS = {
  [DataSource.HEARTLAND_RESTAURANT]: getHeartlandLocationSettings,
};

/**
 * Hooks
 */

const PARTNER_LOCATION_QUERY_BASE = 'partnerQueryLocation';

export const usePartnerLocationSettings = (partner, apiKey, queryConfig) =>
  useQuery(
    [PARTNER_LOCATION_QUERY_BASE, partner, apiKey],
    async () => {
      const apiCall = PARTNER_API_CALLS[partner];

      if (apiKey == null || !apiCall) {
        return {};
      }

      const response = await apiCall(apiKey);
      const { data = {} } = response;

      return data;
    },
    queryConfig,
  );
