import React from 'react';
import * as d3 from 'd3';
import ResizeDetector from './ResizeDetector';
import Tooltip from './Tooltip';
import { FONT_BLACK, FONT_GRAY } from '../../../../../colors';
import './performance-charts.scss';

// Fixed colors for the bars
const BAR_COLORS = ['#64f884', '#0d729e', '#888888'];

/**
 * Creates hatch patterns for each forecast bar.
 * Each pattern uses the given color.
 *
 * @param {Object} svg - The D3 selection of the SVG element.
 * @param {Array} data - The data array (length should be 3).
 */
const createHatchPatterns = (svg, data) => {
  const defs = svg.append('defs');
  data.forEach((d, i) => {
    defs
      .append('pattern')
      .attr('id', `hatch-${i}`)
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('width', 4)
      .attr('height', 4)
      .append('path')
      .attr('d', 'M0,0 l4,4')
      .attr('stroke', BAR_COLORS[i])
      .attr('stroke-width', 1);
  });
};

/**
 * Draws the vertical stacked bars.
 * For each bar, two rectangles are drawn:
 *  - The current portion (solid fill).
 *  - The forecast portion (filled with a hatch pattern).
 *
 * @param {Object} g - The D3 selection of the main group.
 * @param {Object} xScale - The x band scale.
 * @param {Object} yScale - The y linear scale.
 * @param {Array} data - The data array (expected length is 3).
 */
const drawBars = (g, xScale, yScale, data, numberFormatter, setTooltipContent, setTooltipPosition) => {
  const handleMouseOver = (content, x, y) => {
    setTooltipPosition({ x, y });
    setTooltipContent(content);
  };

  const handleMouseOut = () => {
    setTooltipContent('');
  };

  data.forEach((d, i) => {
    const x = xScale(i);
    // Calculate heights for current and forecast portions
    const currentValue = d.current;
    const forecastValue = d.forecast;
    const totalValue = currentValue + forecastValue;
    const currentHeight = yScale(0) - yScale(currentValue);
    const totalHeight = yScale(0) - yScale(totalValue);

    // Draw current portion (solid fill)
    g.append('rect')
      .attr('x', x)
      .attr('y', yScale(currentValue))
      .attr('width', xScale.bandwidth())
      .attr('height', currentHeight)
      .attr('fill', BAR_COLORS[i]);

    // Draw forecast portion (stacked above current, with hatch fill)
    g.append('rect')
      .attr('x', x)
      .attr('y', yScale(totalValue))
      .attr('width', xScale.bandwidth())
      .attr('height', totalHeight - currentHeight)
      .attr('fill', `url(#hatch-${i})`)
      .on('mouseover', () => {
        if (d.forecastTooltip) {
          const forecastBarHeight = totalHeight - currentHeight;
          const tooltipY = yScale(totalValue) + forecastBarHeight / 2;
          handleMouseOver(d.forecastTooltip, x, tooltipY);
        }
      })
      .on('mouseout', handleMouseOut);

    g.append('rect')
      .attr('x', x)
      .attr('y', yScale(currentValue))
      .attr('width', xScale.bandwidth())
      .attr('height', currentHeight)
      .attr('fill', 'transparent')
      .style('pointer-events', 'all')
      .on('mouseover', () => {
        if (d.currentTooltip) {
          const tooltipY = yScale(currentValue) + currentHeight / 2;
          handleMouseOver(d.currentTooltip, x, tooltipY);
        }
      })
      .on('mouseout', handleMouseOut);

    // Draw total value text above each bar
    const textElement = g
      .append('text')
      .attr('x', x + xScale.bandwidth() / 2)
      .attr('y', yScale(totalValue) - 8)
      .attr('text-anchor', 'middle')
      .attr('fill', FONT_BLACK)
      .style('font-weight', 'bold')
      .text(numberFormatter(totalValue));

    // Insert a white rectangle behind the text
    const bbox = textElement.node().getBBox();

    g.insert('rect', 'text')
      .attr('x', bbox.x)
      .attr('y', bbox.y)
      .attr('width', bbox.width)
      .attr('height', bbox.height)
      .attr('fill', 'white');
  });
};

/**
 * VerticalBarChart component renders a vertical stacked bar chart using D3.
 * Each object represents a bar and must contain:
 * - current: numeric value for the current period.
 * - forecast: numeric value for the forecast.
 * - currentTooltip: string tooltip for the current portion.
 * - forecastTooltip: string tooltip for the forecast portion.
 * - label: string to use for the label underneath the bar
 *
 * The forecast portion is drawn with a hatch pattern of the same color as the solid bar.
 *
 * @param {Object} props
 * @param {Array<Object>} props.data - Array of 3 data objects.
 * @param {number} [props.width=500] - Width of the chart.
 * @param {number} [props.height=300] - Height of the chart.
 * @returns {JSX.Element} The rendered chart.
 */
const PerformanceBarChart = ({ data, numberFormatter, width, height }) => {
  const svgRef = React.useRef();
  const tooltipRef = React.useRef(null);
  const [tooltipContent, setTooltipContent] = React.useState('');
  const [tooltipPosition, setTooltipPosition] = React.useState({ x: 0, y: 0 });

  React.useLayoutEffect(() => {
    const svg = d3.select(svgRef.current);
    // Clear previous content
    svg.selectAll('*').remove();

    // Set margins and dimensions
    const margin = { top: 20, right: 20, bottom: 40, left: 40 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Create a group for the chart contents
    const g = svg
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Define x scale (a band scale for 3 bars)
    const xScale = d3
      .scaleBand()
      .domain(data.map((d, i) => i))
      .range([0, innerWidth])
      .padding(0.3);

    // Define y scale based on the maximum stacked value
    const maxTotal = d3.max(data, d => d.current + d.forecast) || 0;
    const yScale = d3
      .scaleLinear()
      .domain([0, maxTotal])
      .range([innerHeight, 0])
      .nice();

    // Create the hatch patterns for forecast fills
    createHatchPatterns(svg, data);

    // Draw the stacked bars (current + forecast) with tooltip events
    drawBars(g, xScale, yScale, data, numberFormatter, setTooltipContent, setTooltipPosition);

    // If there is data, draw a horizontal dashed line at the top of the first bar
    if (data && data.length > 0) {
      const firstBar = data[0];
      const firstBarTotal = firstBar.current + firstBar.forecast;
      const yPosition = yScale(firstBarTotal) - 2;

      // Draw the line from the left edge of the first bar to the right edge of the inner chart area
      g.append('line')
        .attr('x1', xScale(0))
        .attr('x2', innerWidth)
        .attr('y1', yPosition)
        .attr('y2', yPosition)
        .attr('stroke', FONT_GRAY)
        .attr('stroke-width', 2)
        .attr('stroke-dasharray', '5,5');

      // dashedLine.lower();
    }

    // Draw labels below each bar
    // NOTE: this should be changed to font size of 12px and wrap to a second line if the text is wider than the bar
    data.forEach((d, i) => {
      g.append('text')
        .attr('x', xScale(i) + xScale.bandwidth() / 2)
        .attr('y', innerHeight + 20)
        .attr('text-anchor', 'middle')
        .attr('fill', FONT_BLACK)
        .attr('font-size', d.label.length > 9 ? '10px' : '8px')
        .text(d.label);
    });
  }, [data, numberFormatter, width, height]);

  return (
    <>
      <svg ref={svgRef} />
      {tooltipContent && <Tooltip ref={tooltipRef} {...tooltipPosition} content={tooltipContent} />}
    </>
  );
};

const PerformanceBarChartContainer = ({ data, numberFormatter }) => (
  <ResizeDetector>
    {({ height, width }) => (
      <div className="chart" style={{ height, width }}>
        <PerformanceBarChart data={data} numberFormatter={numberFormatter} width={width} height={height} />
      </div>
    )}
  </ResizeDetector>
);

export default PerformanceBarChartContainer;
