/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigateToPartnerOAuthInitiate } from './PartnerHooks';
import PartnerConnection from './PartnerConnection';
import PartnerSteps from './PartnerSteps';
import ConnectCheckModal from '../../dataSources/ConnectCheckModal';
import {
  OAUTH_DATASOURCES,
  LOCATION_KEY_DATASOURCES,
  DataSource,
  DataSourceDisplayName,
  locationKeyDatasourceCompletionRoute,
} from '../../dataSources/dataSourceConstants';
import { ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';
import { CenteredProfitRoverSpinner } from '../../spinner/ProfitRoverSpinner';
import { useGuidedSetup } from '../../../data-access/query/guidedSetup';
import './partner-instructions.scss';

const PARTNER_NEEDS_CONFIRMATION = Object.freeze([DataSource.HEARTLAND_RETAIL]);

export const PartnerInstructions = ({ partner }) => {
  const history = useHistory();
  const initiateOauth = useNavigateToPartnerOAuthInitiate(partner);
  const { data: guidedSetup = { naics_industry: {} } } = useGuidedSetup();
  const {
    naics_industry: { naics_code: naicsCode, ka_code: kaCode },
  } = guidedSetup;

  const [openPartnerConnection, setOpenPartnerConnection] = React.useState(false);
  const [showConnectCheckModal, setShowConnectCheckModal] = React.useState(false);

  const initiatePartnerConnection = () => {
    if (showConnectCheckModal) {
      setShowConnectCheckModal(false);
    }

    if (OAUTH_DATASOURCES.includes(partner)) {
      initiateOauth();
    } else if (LOCATION_KEY_DATASOURCES.includes(partner)) {
      setOpenPartnerConnection(true);
    }
  };

  const handleConnectButtonClick = () => {
    if (PARTNER_NEEDS_CONFIRMATION.includes(partner)) {
      setShowConnectCheckModal(true);
    } else {
      initiatePartnerConnection();
    }
  };

  const handleConnectCheckModalCancel = () => {
    setShowConnectCheckModal(false);
  };

  const onPartnerConnectionSuccess = () => {
    setOpenPartnerConnection(false);

    history.push(locationKeyDatasourceCompletionRoute(partner));
  };

  const firstStep = `The Connect to ${DataSourceDisplayName[partner]} button below initiates the authorization process, 
  allowing ProfitRover to access to your account.`;

  return (
    <>
      <div className="partner-instructions">
        <PartnerSteps partner={partner} additionalSteps={[firstStep]} />

        {OAUTH_DATASOURCES.includes(partner) ?? (
          <div className="post-integration-instructions">
            <i>You will be automatically redirected back to ProfitRover.</i>
          </div>
        )}

        <hr />

        <ConnectCheckModal
          partner={partner}
          show={showConnectCheckModal}
          onOK={initiatePartnerConnection}
          onCancel={handleConnectCheckModalCancel}
        />

        <PartnerConnection
          partner={partner}
          onConnected={onPartnerConnectionSuccess}
          open={openPartnerConnection}
          onCancel={() => setOpenPartnerConnection(false)}
          locationNaicsCode={naicsCode}
          locationKaCode={kaCode}
        />

        <ProfitRoverPrimaryButton onClick={handleConnectButtonClick} disabled={openPartnerConnection}>
          {openPartnerConnection ? (
            <CenteredProfitRoverSpinner />
          ) : (
            <div className="d-flex align-items-center">
              Connect to {DataSourceDisplayName[partner]}
              <FontAwesomeIcon
                icon={faCaretRight}
                pull="right"
                style={{ fontSize: 22, position: 'relative', top: 1 }}
              />
            </div>
          )}
        </ProfitRoverPrimaryButton>
      </div>
    </>
  );
};
