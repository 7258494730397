import React from 'react';
import camelcase from 'camelcase';
import _ from 'lodash';
import {
  BASE_RECOMMENDATIONS,
  DEFAULT_HOURS_SETTINGS,
  HOURS_OF_OPERATION_SETTINGS_KEYS,
  HOURS_OF_OPERATION_VARIATION_OPTION_KEYS,
  MAX_HOUR_OFFSET,
  MAX_NEXT_DAY_HOUR_INDEX,
  NUMBER_OF_VISIBLE_HOURS,
} from './hoursOfOperationConstants';
import { NEXT_CALENDAR_YEAR_PERIOD } from './periodConstants';
import { DashboardContext, DomainContext } from './revenueUpliftContexts';
import { getDimColumnKey } from './revenueUpliftDashboardComputations';
import {
  ALL_HOURS,
  CLOSE_TIME,
  DAYS_OF_WEEK,
  EARLY_NEXT_DAY_HOURS,
  IS_CLOSED,
  OPEN_24_HOURS,
  OPEN_TIME,
} from '../../../accountSettings/locationSettings/locationManagementConstants';
import {
  endsOnNextDay,
  HOURS_OVERLAP_ERROR_CODE,
  isValidHour,
  isValidHoursOfOperation,
  normalizeToHour,
  validateLocationHoursOfOperation,
} from '../../../accountSettings/locationSettings/locationHoursOfOperationUtil';
import { formatCurrency } from '../../../util/format';
import { HOUR_DIMENSION_TYPE } from '../../../workflow/workflowConstants';
import { useUpdateHoursOfOperationSettings } from '../../../../data-access/mutation/hoursOfOperationSettings';
import { useHoursOfOperationSettings } from '../../../../data-access/query/hoursOfOperationSettings';
import { ERROR, LIGHT_BLUE, LIGHT_COOL_GRAY, MID_GREEN } from '../../../../colors';

const areHoursSettingsValid = hoursSettings => {
  return (
    hoursSettings[HOURS_OF_OPERATION_SETTINGS_KEYS.HOURLY_REVENUE_CLOSE_THRESHOLD] > 0 &&
    HOURS_OF_OPERATION_VARIATION_OPTION_KEYS.includes(
      hoursSettings[HOURS_OF_OPERATION_SETTINGS_KEYS.VARY_HOURS_OF_OPERATION_BY],
    ) &&
    Object.values(BASE_RECOMMENDATIONS).includes(
      hoursSettings[HOURS_OF_OPERATION_SETTINGS_KEYS.BASE_RECOMMENDATIONS_ON],
    )
  );
};

export const useEditHoursOfOperationSettings = ({ currentLocationId, locations, hoursEnabled }) => {
  const [hoursOfOperationSettings, setHoursOfOperationSettings] = React.useState(() => {
    const initialHoursSettings = {};

    locations.forEach(({ location_id: locationId }) => {
      initialHoursSettings[locationId] = DEFAULT_HOURS_SETTINGS;
    });

    return initialHoursSettings;
  });

  const { mutate: updateHoursSettings, isLoading: isUpdatingHoursSettings } = useUpdateHoursOfOperationSettings();
  const { isLoading: isLoadingHoursSettings } = useHoursOfOperationSettings({
    onSuccess: data => {
      const currentHoursSettings = [...data];
      let additions = 0;

      locations.forEach(({ location_id: locationId }) => {
        const locationSettings = currentHoursSettings.find(({ location_id: currentId }) => currentId === locationId);

        if (!locationSettings) {
          currentHoursSettings.push({
            location_id: locationId,
            hours_of_operation_settings: DEFAULT_HOURS_SETTINGS,
          });
          additions += 1;
        }
      });

      if (additions > 0) {
        // Update the hours of operation settings in the backend
        updateHoursSettings(currentHoursSettings);
      }

      const hoursSettingsObject = {};
      currentHoursSettings.forEach(({ location_id: locationId, hours_of_operation_settings: settings }) => {
        hoursSettingsObject[locationId] = settings;
      });

      setHoursOfOperationSettings(hoursSettingsObject);
    },
    enabled: hoursEnabled,
  });

  const [currentHoursSettings, setCurrentHoursSettings] = React.useState(
    () => hoursOfOperationSettings[currentLocationId] ?? DEFAULT_HOURS_SETTINGS,
  );

  React.useEffect(() => {
    setCurrentHoursSettings(hoursOfOperationSettings[currentLocationId] ?? DEFAULT_HOURS_SETTINGS);
  }, [hoursOfOperationSettings, currentLocationId]);

  const hasPendingChanges = () => {
    return JSON.stringify(currentHoursSettings) !== JSON.stringify(hoursOfOperationSettings[currentLocationId]);
  };

  const saveCurrentHoursSettings = () => {
    if (hasPendingChanges() && areHoursSettingsValid(currentHoursSettings)) {
      const updatedHoursSettings = { ...hoursOfOperationSettings };
      updatedHoursSettings[currentLocationId] = currentHoursSettings;

      const updatedHoursSettingsArray = Object.entries(updatedHoursSettings).map(([locationId, settings]) => ({
        location_id: parseInt(locationId, 10),
        hours_of_operation_settings: settings,
      }));

      updateHoursSettings(updatedHoursSettingsArray);
      setHoursOfOperationSettings(updatedHoursSettings);
    }
  };

  return {
    isLoadingHoursSettings,
    hoursOfOperationSettings,
    currentHoursSettings,
    setCurrentHoursSettings,
    saveCurrentHoursSettings,
    isUpdatingHoursSettings,
  };
};

const useAverageRevenuePerDay = (locationHoursOfOperation, avgRevenuePerDayPerHour) => {
  const [avgHourlyRevenueByDay, overallAvgHourlyRevenue] = React.useMemo(() => {
    const { current, recommended, newHours } = locationHoursOfOperation;
    const averageRevenueAcc = { current: {}, recommended: {}, newHours: {} };
    const overallAverageRevenue = { current: 0, recommended: 0, newHours: 0 };

    [
      { key: 'current', hoursOfOperation: current },
      { key: 'recommended', hoursOfOperation: recommended },
      { key: 'newHours', hoursOfOperation: newHours },
    ].forEach(({ key, hoursOfOperation }) => {
      const allHourlyRevenues = [];

      hoursOfOperation.forEach(
        ({ day, [OPEN_TIME]: openTime, [CLOSE_TIME]: closeTime, [OPEN_24_HOURS]: open24, [IS_CLOSED]: isClosed }) => {
          if (isClosed) {
            averageRevenueAcc[key][day] = 0;
            return;
          }

          if (open24) {
            const hourlyRevenues = Object.values(avgRevenuePerDayPerHour[day] ?? {});

            allHourlyRevenues.push(...hourlyRevenues);
            averageRevenueAcc[key][day] = (_.sum(hourlyRevenues) ?? 0) / ALL_HOURS.length;

            return;
          }

          const openHour = normalizeToHour(openTime, OPEN_TIME);
          const closeHour = normalizeToHour(closeTime, CLOSE_TIME);
          const openHourIndex = ALL_HOURS.indexOf(openHour);
          const closeHourIndex = ALL_HOURS.indexOf(closeHour);
          const dayOpenHours = [];

          if (endsOnNextDay(openTime, closeTime)) {
            dayOpenHours.push(...ALL_HOURS.slice(openHourIndex));
            dayOpenHours.push(...ALL_HOURS.slice(0, closeHourIndex));
          } else {
            const closeHourIndexAdjusted = closeHourIndex === 0 ? ALL_HOURS.length + 1 : closeHourIndex;
            dayOpenHours.push(...ALL_HOURS.slice(openHourIndex, closeHourIndexAdjusted));
          }

          const hourlyRevenues = dayOpenHours.map(hour => avgRevenuePerDayPerHour[day]?.[hour] ?? 0);

          allHourlyRevenues.push(...hourlyRevenues);
          const averageRevenue = dayOpenHours.length > 0 ? _.sum(hourlyRevenues) / dayOpenHours.length : 0;
          averageRevenueAcc[key][day] = averageRevenue;
        },
      );

      overallAverageRevenue[key] = _.mean(allHourlyRevenues) ?? 0;
    });

    return [averageRevenueAcc, overallAverageRevenue];
  }, [locationHoursOfOperation, avgRevenuePerDayPerHour]);

  return { avgHourlyRevenueByDay, overallAvgHourlyRevenue };
};

const useAverageRevenuePerDayForMultipleLocations = (
  locationHoursOfOperation,
  forecastAvgPerDayPerHourMultipleLocations,
) => {
  const [avgHourlyRevenueByDay, overallAvgHourlyRevenue] = React.useMemo(() => {
    const averageRevenueAcc = {};
    const overallAverageRevenue = {};

    Object.keys(locationHoursOfOperation).forEach(locationId => {
      const { current, recommended, newHours } = locationHoursOfOperation[locationId];
      const avgRevenuePerDayPerHour = forecastAvgPerDayPerHourMultipleLocations[locationId];

      averageRevenueAcc[locationId] = { current: {}, recommended: {}, newHours: {} };
      overallAverageRevenue[locationId] = { current: 0, recommended: 0, newHours: 0 };

      [
        { key: 'current', hoursOfOperation: current },
        { key: 'recommended', hoursOfOperation: recommended },
        { key: 'newHours', hoursOfOperation: newHours },
      ].forEach(({ key, hoursOfOperation }) => {
        const allHourlyRevenues = [];

        hoursOfOperation.forEach(
          ({ day, open_time: openTime, close_time: closeTime, open_24_hours: open24, closed: isClosed }) => {
            if (isClosed) {
              averageRevenueAcc[locationId][key][day] = 0;
              return;
            }

            if (open24) {
              const hourlyRevenues = Object.values(avgRevenuePerDayPerHour?.[day] ?? {});

              allHourlyRevenues.push(...hourlyRevenues);
              averageRevenueAcc[locationId][key][day] = (_.sum(hourlyRevenues) ?? 0) / ALL_HOURS.length;

              return;
            }

            const openHour = normalizeToHour(openTime, OPEN_TIME);
            const closeHour = normalizeToHour(closeTime, CLOSE_TIME);
            const openHourIndex = ALL_HOURS.indexOf(openHour);
            const closeHourIndex = ALL_HOURS.indexOf(closeHour);
            const dayOpenHours = [];

            if (endsOnNextDay(openTime, closeTime)) {
              dayOpenHours.push(...ALL_HOURS.slice(openHourIndex));
              dayOpenHours.push(...ALL_HOURS.slice(0, closeHourIndex));
            } else {
              const closeHourIndexAdjusted = closeHourIndex === 0 ? ALL_HOURS.length + 1 : closeHourIndex;
              dayOpenHours.push(...ALL_HOURS.slice(openHourIndex, closeHourIndexAdjusted));
            }

            const hourlyRevenues = dayOpenHours.map(hour => avgRevenuePerDayPerHour?.[day]?.[hour] ?? 0);

            allHourlyRevenues.push(...hourlyRevenues);
            const averageRevenue = dayOpenHours.length > 0 ? _.sum(hourlyRevenues) / dayOpenHours.length : 0;
            averageRevenueAcc[locationId][key][day] = averageRevenue;
          },
        );

        overallAverageRevenue[locationId][key] = _.mean(allHourlyRevenues) ?? 0;
      });
    });

    return [averageRevenueAcc, overallAverageRevenue];
  }, [locationHoursOfOperation, forecastAvgPerDayPerHourMultipleLocations]);

  return { avgHourlyRevenueByDay, overallAvgHourlyRevenue };
};

const getNextDayOfWeek = dayOfWeek => DAYS_OF_WEEK[(DAYS_OF_WEEK.indexOf(dayOfWeek) + 1) % DAYS_OF_WEEK.length];

const useHourOffset = (initialOffset = 0) => {
  const [offset, setOffset] = React.useState(initialOffset);

  const hours = React.useMemo(() => [...ALL_HOURS, ...ALL_HOURS.slice(0, MAX_NEXT_DAY_HOUR_INDEX + 1)], []);

  React.useEffect(() => {
    if (offset > MAX_HOUR_OFFSET) {
      setOffset(MAX_HOUR_OFFSET);
    }
  }, [offset]);

  const visibleHours = React.useMemo(
    () =>
      offset > MAX_HOUR_OFFSET
        ? hours.slice(MAX_HOUR_OFFSET, MAX_HOUR_OFFSET + NUMBER_OF_VISIBLE_HOURS)
        : hours.slice(offset, offset + NUMBER_OF_VISIBLE_HOURS),
    [offset, hours],
  );

  const increaseOffset = () => {
    setOffset(prev => {
      const maxOffset = Math.min(MAX_HOUR_OFFSET, hours.length - NUMBER_OF_VISIBLE_HOURS);
      return prev >= maxOffset ? prev : prev + 1;
    });
  };

  const decreaseOffset = () => {
    setOffset(prev => (prev <= 0 ? 0 : prev - 1));
  };

  return {
    offset,
    setOffset,
    increaseOffset,
    decreaseOffset,
    increaseDisabled: offset >= Math.min(MAX_HOUR_OFFSET, hours.length - NUMBER_OF_VISIBLE_HOURS),
    decreaseDisabled: offset <= 0,
    hours,
    visibleHours,
  };
};

const getParsedHoursOfOperationByLocation = (
  locations,
  hoursOfOperation,
  recommendations,
  forecastAvgPerDayPerHour,
) => {
  const newHoursOfOperationByLocation = {};

  const getForecastHoursOfOperation = day => {
    const forecastOpenHours = Object.entries(forecastAvgPerDayPerHour?.[day] ?? {})
      .filter(([, value]) => value >= 1)
      .map(([hour]) => ALL_HOURS.indexOf(hour));
    const startHour = _.min(forecastOpenHours);
    const lastShiftHour = _.max(forecastOpenHours);

    const isOpen = startHour >= 0 && lastShiftHour >= 0;
    const open24 = isOpen && startHour === 0 && lastShiftHour === ALL_HOURS.length - 1;
    const endHour = (lastShiftHour + 1) % ALL_HOURS.length;

    return {
      [IS_CLOSED]: !isOpen,
      [OPEN_24_HOURS]: open24,
      [OPEN_TIME]: isOpen ? ALL_HOURS[startHour] : '',
      [CLOSE_TIME]: isOpen ? ALL_HOURS[endHour] : '',
    };
  };

  locations.forEach(({ value: currentLocationId }) => {
    const newLocationHoursOfOperation = [];
    const { hours_of_operation_by_day: hoursByDay = [] } =
      hoursOfOperation.find(({ location_id: locationId }) => locationId === currentLocationId) ?? {};

    DAYS_OF_WEEK.forEach(day => {
      const { hours_of_operation: dayHoursArray = [] } =
        hoursByDay.find(({ day_of_week: dayOfWeek }) => dayOfWeek === day) ?? {};
      const [dayHours = {}] = dayHoursArray; // There should only be one set of hours per day

      if (isValidHoursOfOperation(dayHours)) {
        newLocationHoursOfOperation.push({
          day,
          ...dayHours,
        });
      } else {
        newLocationHoursOfOperation.push({
          day,
          ...getForecastHoursOfOperation(day),
        });
      }
    });

    if (validateLocationHoursOfOperation(newLocationHoursOfOperation) == null) {
      newHoursOfOperationByLocation[currentLocationId] = {
        current: [...newLocationHoursOfOperation],
        recommended: [...recommendations],
        newHours: [...recommendations],
        error: null,
      };
    } else {
      const defaultHours = DAYS_OF_WEEK.map(day => ({
        day,
        ...getForecastHoursOfOperation(day),
      }));

      newHoursOfOperationByLocation[currentLocationId] = {
        current: [...defaultHours],
        recommended: [...recommendations],
        newHours: [...recommendations],
        error: null,
      };
    }
  });

  return newHoursOfOperationByLocation;
};

const getParsedHoursOfOperationByLocations = (
  locations,
  hoursOfOperation,
  recommendationsByLocation,
  forecastAvgPerDayPerHourMultipleLocations,
) => {
  const newHoursOfOperationByLocation = {};

  const getForecastHoursOfOperation = (day, locationId) => {
    const forecastOpenHours = Object.entries(forecastAvgPerDayPerHourMultipleLocations?.[locationId]?.[day] ?? {})
      .filter(([, value]) => value >= 1)
      .map(([hour]) => ALL_HOURS.indexOf(hour));

    const startHour = _.min(forecastOpenHours);
    const lastShiftHour = _.max(forecastOpenHours);

    const isOpen = startHour >= 0 && lastShiftHour >= 0;
    const open24 = isOpen && startHour === 0 && lastShiftHour === ALL_HOURS.length - 1;
    const endHour = (lastShiftHour + 1) % ALL_HOURS.length;

    return {
      [IS_CLOSED]: !isOpen,
      [OPEN_24_HOURS]: open24,
      [OPEN_TIME]: isOpen ? ALL_HOURS[startHour] : '',
      [CLOSE_TIME]: isOpen ? ALL_HOURS[endHour] : '',
    };
  };

  locations.forEach(({ value: currentLocationId }) => {
    const newLocationHoursOfOperation = [];
    const { hours_of_operation_by_day: hoursByDay = [] } =
      hoursOfOperation.find(({ location_id: locationId }) => locationId === currentLocationId) ?? {};

    DAYS_OF_WEEK.forEach(day => {
      const { hours_of_operation: dayHoursArray = [] } =
        hoursByDay.find(({ day_of_week: dayOfWeek }) => dayOfWeek === day) ?? {};
      const [dayHours = {}] = dayHoursArray;

      if (isValidHoursOfOperation(dayHours)) {
        newLocationHoursOfOperation.push({
          day,
          ...dayHours,
        });
      } else {
        newLocationHoursOfOperation.push({
          day,
          ...getForecastHoursOfOperation(day, currentLocationId),
        });
      }
    });

    const recommendations = recommendationsByLocation[currentLocationId] || [];

    if (validateLocationHoursOfOperation(newLocationHoursOfOperation) == null) {
      newHoursOfOperationByLocation[currentLocationId] = {
        current: [...newLocationHoursOfOperation],
        recommended: [...recommendations],
        newHours: [...recommendations],
        error: null,
      };
    } else {
      const defaultHours = DAYS_OF_WEEK.map(day => ({
        day,
        ...getForecastHoursOfOperation(day, currentLocationId),
      }));

      newHoursOfOperationByLocation[currentLocationId] = {
        current: [...defaultHours],
        recommended: [...recommendations],
        newHours: [...recommendations],
        error: null,
      };
    }
  });

  return newHoursOfOperationByLocation;
};

const useForecastAvgPerDayPerHour = (location, period) => {
  const { dimensions, dimensionValues } = React.useContext(DomainContext);
  const { averageProductRevenueForecast } = React.useContext(DashboardContext);

  return React.useMemo(() => {
    const hourDimId = dimensions.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE)?.product_dimension_id;
    const forecastAvgPerDayPerHourAcc = {};

    DAYS_OF_WEEK.forEach(day => {
      forecastAvgPerDayPerHourAcc[day] = {};
    });

    averageProductRevenueForecast.forEach(row => {
      const { locationId, dayOfWeek, [getDimColumnKey(hourDimId)]: hour } = row;

      if (locationId !== location) {
        return;
      }

      const key = camelcase(`${period}_avg_revenue`);
      const hourValue = dimensionValues.find(dimValue => dimValue.dimension_id === hourDimId && dimValue.id === hour)
        ?.value;

      const previousValue = forecastAvgPerDayPerHourAcc[dayOfWeek][hourValue] ?? 0;
      forecastAvgPerDayPerHourAcc[dayOfWeek][hourValue] = previousValue + row[key];
    });

    return forecastAvgPerDayPerHourAcc;
  }, [averageProductRevenueForecast, dimensions, dimensionValues, location, period]);
};

const useForecastAvgPerDayPerHourMultipleLocations = (locations, period) => {
  const { dimensions, dimensionValues } = React.useContext(DomainContext);
  const { averageProductRevenueForecast } = React.useContext(DashboardContext);

  return React.useMemo(() => {
    const hourDimId = dimensions.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE)?.product_dimension_id;

    const forecastAvgPerDayPerHourAcc = {};

    locations.forEach(location => {
      forecastAvgPerDayPerHourAcc[location.value] = {};

      DAYS_OF_WEEK.forEach(day => {
        forecastAvgPerDayPerHourAcc[location.value][day] = {};
      });
    });

    locations.forEach(location => {
      averageProductRevenueForecast.forEach(row => {
        const { locationId, dayOfWeek, [getDimColumnKey(hourDimId)]: hour } = row;

        if (locationId !== location.value) {
          return;
        }

        const key = camelcase(`${period}_avg_revenue`);
        const hourValue = dimensionValues.find(dimValue => dimValue.dimension_id === hourDimId && dimValue.id === hour)
          ?.value;

        const previousValue = forecastAvgPerDayPerHourAcc[location.value][dayOfWeek][hourValue] ?? 0;
        forecastAvgPerDayPerHourAcc[location.value][dayOfWeek][hourValue] = previousValue + row[key];
      });
    });

    return forecastAvgPerDayPerHourAcc;
  }, [averageProductRevenueForecast, dimensions, dimensionValues, locations, period]);
};

const useAvgHistoryPerDayPerHour = (location, period) => {
  const { dimensions, dimensionValues } = React.useContext(DomainContext);
  const { averageProductRevenueHistory } = React.useContext(DashboardContext);

  return React.useMemo(() => {
    const hourDimId = dimensions.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE)?.product_dimension_id;
    const last4WeeksAvgPerDayPerHourAcc = {};
    const stlyAvgPerDayPerHourAcc = {};

    DAYS_OF_WEEK.forEach(day => {
      last4WeeksAvgPerDayPerHourAcc[day] = {};
      stlyAvgPerDayPerHourAcc[day] = {};
    });

    averageProductRevenueHistory.forEach(row => {
      const { locationId, dayOfWeek, [getDimColumnKey(hourDimId)]: hour } = row;

      if (locationId !== location) {
        return;
      }

      const last4WeeksKey = camelcase('last_4_weeks_avg_revenue');
      const stlyKey = camelcase(`${period}_stly_avg_revenue`);

      const hourValue = dimensionValues.find(dimValue => dimValue.dimension_id === hourDimId && dimValue.id === hour)
        ?.value;

      // Accumulates values for the last 4 weeks average
      const last4WeeksPreviousValue = last4WeeksAvgPerDayPerHourAcc[dayOfWeek][hourValue] ?? 0;
      last4WeeksAvgPerDayPerHourAcc[dayOfWeek][hourValue] = last4WeeksPreviousValue + row[last4WeeksKey];

      // Accumulates values for the same period last year average
      const stlyPreviousValue = stlyAvgPerDayPerHourAcc[dayOfWeek][hourValue] ?? 0;
      stlyAvgPerDayPerHourAcc[dayOfWeek][hourValue] = stlyPreviousValue + row[stlyKey];
    });

    return { last4WeeksAvgPerDayPerHour: last4WeeksAvgPerDayPerHourAcc, stlyAvgPerDayPerHour: stlyAvgPerDayPerHourAcc };
  }, [averageProductRevenueHistory, dimensions, dimensionValues, location, period]);
};

const calculateRecommendationsForDay = (day, index, closeThreshold, forecastAvgPerDayPerHour) => {
  const hourlyStats = {};
  const dayForecastAvgPerHour = forecastAvgPerDayPerHour[day] ?? {};

  // Define the next day and extended hours from 05:00 to 04:00 of the next day
  const nextDay = DAYS_OF_WEEK[(index + 1) % DAYS_OF_WEEK.length];
  const extendedHours = ALL_HOURS.slice(5).concat(EARLY_NEXT_DAY_HOURS);

  // Build extendedForecast using hours from the current day and next day for early hours
  const extendedForecast = {};
  extendedHours.forEach(hour => {
    if (ALL_HOURS.slice(5).includes(hour)) {
      // 05:00 to 23:00 of the current day
      extendedForecast[hour] = dayForecastAvgPerHour[hour];
    } else if (forecastAvgPerDayPerHour[nextDay] && ALL_HOURS.slice(0, 5).includes(hour)) {
      // 00:00 to 04:00 of the next day
      extendedForecast[hour] = forecastAvgPerDayPerHour[nextDay][hour];
    }
  });

  // Calculate hourly statistics based on extendedForecast
  extendedHours.forEach(hour => {
    hourlyStats[hour] = {
      isOverThreshold: extendedForecast[hour] >= closeThreshold,
    };
  });

  extendedHours.forEach((hour, i) => {
    const isCurrentOverThreshold = hourlyStats[hour].isOverThreshold;
    const pocketStartPrev = extendedHours
      .slice(0, i)
      .findLastIndex(h => hourlyStats[h].isOverThreshold !== isCurrentOverThreshold);
    const pocketStart = pocketStartPrev >= 0 ? pocketStartPrev + 1 : 0;
    const pocketEndNext = extendedHours
      .slice(i + 1)
      .findIndex(h => hourlyStats[h].isOverThreshold !== isCurrentOverThreshold);
    const pocketEnd = pocketEndNext >= 0 ? pocketEndNext + i + 1 : extendedHours.length - 1;
    const pocketTotal = _.sum(extendedHours.slice(pocketStart, pocketEnd).map(h => extendedForecast[h]));

    hourlyStats[hour].pocketTotal = pocketTotal;
  });

  const maxPocketTotal = _.max(
    extendedHours.filter(h => hourlyStats[h].isOverThreshold).map(h => hourlyStats[h].pocketTotal),
  );

  extendedHours.forEach(hour => {
    hourlyStats[hour].isTopPriority =
      hourlyStats[hour].pocketTotal === maxPocketTotal && extendedForecast[hour] > closeThreshold;
  });

  extendedHours.forEach((hour, i) => {
    const nextTopPriority = extendedHours.slice(i + 1).findIndex(h => hourlyStats[h].isTopPriority);
    const nextTopPriorityIndex = nextTopPriority >= 0 ? nextTopPriority + i + 1 : null;
    const prevTopPriority = extendedHours.slice(0, i).findLastIndex(h => hourlyStats[h].isTopPriority);
    const prevTopPriorityIndex = prevTopPriority >= 0 ? prevTopPriority : null;

    hourlyStats[hour].nextTopPriorityIndex = nextTopPriorityIndex == null ? prevTopPriorityIndex : nextTopPriorityIndex;
  });

  extendedHours.forEach((hour, i) => {
    const { isTopPriority, nextTopPriorityIndex } = hourlyStats[hour];
    const currentValue = extendedForecast[hour];

    hourlyStats[hour].shoulderAverage =
      currentValue >= closeThreshold && !isTopPriority && nextTopPriorityIndex > i
        ? _.mean(extendedHours.slice(i, nextTopPriorityIndex).map(h => extendedForecast[h]))
        : currentValue >= closeThreshold && !isTopPriority && nextTopPriorityIndex < i
        ? _.mean(extendedHours.slice(nextTopPriorityIndex + 1, i + 1).map(h => extendedForecast[h]))
        : 0;
  });

  extendedHours.forEach((hour, i) => {
    const { isTopPriority, nextTopPriorityIndex } = hourlyStats[hour];

    hourlyStats[hour].recommendOpen =
      isTopPriority ||
      (nextTopPriorityIndex > i &&
        _.max(extendedHours.slice(0, i + 1).map(h => hourlyStats[h].shoulderAverage)) >= closeThreshold) ||
      (nextTopPriorityIndex < i &&
        _.max(extendedHours.slice(i).map(h => hourlyStats[h].shoulderAverage)) >= closeThreshold);
  });

  const startHour = extendedHours.findIndex(h => hourlyStats[h].recommendOpen);
  const lastShiftHour = extendedHours.findLastIndex(h => hourlyStats[h].recommendOpen);

  const isOpen = startHour >= 0 && lastShiftHour >= 0;
  const open24 = isOpen && startHour === 0 && lastShiftHour === extendedHours.length - 1;
  const endHour = (lastShiftHour + 1) % extendedHours.length;

  return {
    day,
    [IS_CLOSED]: !isOpen,
    [OPEN_24_HOURS]: open24,
    [OPEN_TIME]: isOpen ? extendedHours[startHour] : '',
    [CLOSE_TIME]: isOpen ? extendedHours[endHour] : '',
  };
};

const useRecommendationsPerDay = forecastAvgPerDayPerHour => {
  const { hoursOfOperationSettingsApi } = React.useContext(DashboardContext);
  const { currentHoursSettings } = hoursOfOperationSettingsApi;

  return React.useMemo(() => {
    const closeThreshold = currentHoursSettings[HOURS_OF_OPERATION_SETTINGS_KEYS.HOURLY_REVENUE_CLOSE_THRESHOLD] ?? 100;
    const recommendationsPerDay = [];

    DAYS_OF_WEEK.forEach((day, index) => {
      recommendationsPerDay.push(calculateRecommendationsForDay(day, index, closeThreshold, forecastAvgPerDayPerHour));
    });

    return recommendationsPerDay;
  }, [currentHoursSettings, forecastAvgPerDayPerHour]);
};

const useRecommendationsPerDayForLocations = forecastAvgPerDayPerHourByLocation => {
  const { hoursOfOperationSettingsApi } = React.useContext(DashboardContext);
  const { hoursOfOperationSettings } = hoursOfOperationSettingsApi;

  return React.useMemo(() => {
    const recommendationsByLocation = {};

    Object.keys(forecastAvgPerDayPerHourByLocation).forEach(locationId => {
      const closeThreshold =
        hoursOfOperationSettings[Number(locationId)][HOURS_OF_OPERATION_SETTINGS_KEYS.HOURLY_REVENUE_CLOSE_THRESHOLD] ??
        100;
      const forecastAvgPerDayPerHour = forecastAvgPerDayPerHourByLocation[locationId];
      const recommendationsPerDay = [];

      DAYS_OF_WEEK.forEach((day, index) => {
        recommendationsPerDay.push(
          calculateRecommendationsForDay(day, index, closeThreshold, forecastAvgPerDayPerHour),
        );
      });

      recommendationsByLocation[locationId] = recommendationsPerDay;
    });

    return recommendationsByLocation;
  }, [hoursOfOperationSettings, forecastAvgPerDayPerHourByLocation]);
};

const getRevenueUpliftList = (locationHoursOfOperations, avgHourlyRevenueByDayMultipleLocations, currencySymbol) => {
  const results = [];

  Object.keys(locationHoursOfOperations).forEach(locationId => {
    const { current, recommended } = locationHoursOfOperations[locationId];
    const avgHourlyRevenueByDay = avgHourlyRevenueByDayMultipleLocations[locationId];

    current.forEach(({ day, open_time: currentOpen, close_time: currentClose }) => {
      const recommendedDay = recommended.find(item => item.day === day);
      const { open_time: recommendedOpen, close_time: recommendedClose } = recommendedDay;

      const currentRevenue = avgHourlyRevenueByDay?.current?.[day] ?? 0;
      const recommendedRevenue = avgHourlyRevenueByDay?.recommended?.[day] ?? 0;

      const revenueHourUplift = recommendedRevenue - currentRevenue;
      const revenueHourUpliftPercentage = currentRevenue !== 0 ? (revenueHourUplift / currentRevenue) * 100 : 0;

      const currentHours = `${currentOpen} - ${currentClose}`;
      const recommendedHours = `${recommendedOpen} - ${recommendedClose}`;

      const formattedUplift = `${formatCurrency(Math.abs(revenueHourUplift), false, currencySymbol)}`;
      const formattedUpliftPercentage = `(${Math.abs(revenueHourUpliftPercentage).toFixed(0)}%)`;
      const formattedRevenueHourUplift = `${formattedUplift} ${formattedUpliftPercentage}`;

      results.push({
        location: locationId,
        dayOfWeek: day,
        currentHours,
        recommendedHours,
        revenueHourUplift,
        revenueHourUpliftPercentage,
        formattedRevenueHourUplift,
      });
    });
  });

  return results;
};

export const useTopHoursOfOperationRecs = (locations, period, topResultsCount, currencySymbol) => {
  const { hoursOfOperation: currentHoursOfOperation } = React.useContext(DomainContext);
  const forecastAvgPerDayPerHourMultipleLocations = useForecastAvgPerDayPerHourMultipleLocations(locations, period);

  const recommendationsByLocation = useRecommendationsPerDayForLocations(forecastAvgPerDayPerHourMultipleLocations);

  const [hoursOfOperationByLocations, setHoursOfOperationByLocations] = React.useState(
    getParsedHoursOfOperationByLocations(
      locations,
      currentHoursOfOperation,
      recommendationsByLocation,
      forecastAvgPerDayPerHourMultipleLocations,
    ),
  );

  React.useEffect(() => {
    setHoursOfOperationByLocations(
      getParsedHoursOfOperationByLocations(
        locations,
        currentHoursOfOperation,
        recommendationsByLocation,
        forecastAvgPerDayPerHourMultipleLocations,
      ),
    );
  }, [currentHoursOfOperation, locations, recommendationsByLocation, forecastAvgPerDayPerHourMultipleLocations]);

  const locationHoursOfOperations = React.useMemo(() => hoursOfOperationByLocations ?? {}, [
    hoursOfOperationByLocations,
  ]);

  const { avgHourlyRevenueByDay: avgHourlyRevenueByDayMultipleLocations } = useAverageRevenuePerDayForMultipleLocations(
    locationHoursOfOperations,
    forecastAvgPerDayPerHourMultipleLocations,
  );

  const list = getRevenueUpliftList(locationHoursOfOperations, avgHourlyRevenueByDayMultipleLocations, currencySymbol);
  const sortedResult = list.sort((a, b) => b.revenueHourUplift - a.revenueHourUplift);
  const topResults = sortedResult.slice(0, topResultsCount);

  return topResults;
};

export const useLocationHoursOfOperation = (locations, selectedLocation, selectedPeriod, baseRecommendations) => {
  const { hoursOfOperation: currentHoursOfOperation } = React.useContext(DomainContext);
  const { newHoursApi } = React.useContext(DashboardContext);

  const { value: locationId } = selectedLocation;
  const { newHOO, addNewHours, editNewHours, deleteNewHours } = newHoursApi;

  const newHours = React.useMemo(() => newHOO?.[locationId] ?? [], [newHOO, locationId]);
  const hasSavedNewHours = newHours.length > 0;

  const { last4WeeksAvgPerDayPerHour, stlyAvgPerDayPerHour } = useAvgHistoryPerDayPerHour(
    selectedLocation?.value,
    selectedPeriod?.value,
  );
  const forecastAvgPerDayPerHour = useForecastAvgPerDayPerHour(selectedLocation?.value, selectedPeriod?.value);

  const avgPerDayPerHour = (() => {
    switch (baseRecommendations) {
      case BASE_RECOMMENDATIONS.FORECAST_AVG:
        return forecastAvgPerDayPerHour;
      case BASE_RECOMMENDATIONS.LAST_4_WEEKS_AVG:
        return last4WeeksAvgPerDayPerHour;
      case BASE_RECOMMENDATIONS.STLY_AVG:
        return stlyAvgPerDayPerHour;
      default:
        return forecastAvgPerDayPerHour;
    }
  })();

  const recommendations = useRecommendationsPerDay(avgPerDayPerHour);

  const [hoursOfOperationByLocation, setHoursOfOperationByLocation] = React.useState(() => {
    const initialHours = getParsedHoursOfOperationByLocation(
      locations,
      currentHoursOfOperation,
      recommendations,
      avgPerDayPerHour,
    );
    if (hasSavedNewHours) {
      initialHours[locationId].newHours = newHours;
    }
    return initialHours;
  });

  const [shouldUpdateHours, setShouldUpdateHours] = React.useState(true);

  React.useEffect(() => {
    if (!shouldUpdateHours) {
      return;
    }

    const updatedHours = getParsedHoursOfOperationByLocation(
      locations,
      currentHoursOfOperation,
      recommendations,
      avgPerDayPerHour,
    );

    if (hasSavedNewHours) {
      updatedHours[locationId].newHours = newHours;
    }

    setHoursOfOperationByLocation(updatedHours);
    setShouldUpdateHours(false);
  }, [
    currentHoursOfOperation,
    locations,
    recommendations,
    avgPerDayPerHour,
    hasSavedNewHours,
    newHours,
    locationId,
    shouldUpdateHours,
  ]);

  const locationHoursOfOperation = React.useMemo(
    () =>
      hoursOfOperationByLocation[selectedLocation?.value] ?? {
        current: [],
        recommended: [],
        newHours: [],
        error: null,
      },
    [hoursOfOperationByLocation, selectedLocation],
  );

  const minStartHourIndex = _.min(
    locationHoursOfOperation.newHours
      .filter(({ [IS_CLOSED]: isClosed }) => !isClosed)
      .map(({ [OPEN_TIME]: openTime, [OPEN_24_HOURS]: open24 }) =>
        open24 ? 0 : ALL_HOURS.indexOf(normalizeToHour(openTime, OPEN_TIME)),
      ),
  );
  const offsetApi = useHourOffset(minStartHourIndex >= 0 ? minStartHourIndex : 0);
  const { hours, offset } = offsetApi;

  const saveNewHours = React.useCallback(
    (day, newHour) => {
      if (hasSavedNewHours) {
        editNewHours(locationId, newHour);
      } else {
        const currentNewHours = locationHoursOfOperation.newHours.filter(({ day: d }) => d !== day);
        addNewHours(locationId, [...currentNewHours, newHour]);
      }
    },
    [hasSavedNewHours, editNewHours, locationId, locationHoursOfOperation, addNewHours],
  );

  const setLocationHoursOfOperationForDay = React.useCallback(
    (day, value) => {
      const newHour = { ...value, day };
      saveNewHours(day, newHour);

      setHoursOfOperationByLocation(prev => {
        const newHoursOfOperationByLocation = { ...prev };
        const currentLocationHoursOfOperation = newHoursOfOperationByLocation[selectedLocation?.value] ?? {
          current: [],
          recommended: [],
          newHours: [],
          error: null,
        };
        const editedData = currentLocationHoursOfOperation.newHours.map(item => {
          if (item.day !== day) {
            return item;
          }

          const newValue = { ...item, ...value, day };
          if (
            item[OPEN_24_HOURS] &&
            !value[OPEN_24_HOURS] &&
            !isValidHour(value[CLOSE_TIME]) &&
            isValidHour(value[OPEN_TIME])
          ) {
            const openIndex = hours.indexOf(value[OPEN_TIME]);
            newValue[CLOSE_TIME] = hours[(openIndex + 2) % hours.length];
          }
          if (newValue[OPEN_TIME] === ALL_HOURS[0] && newValue[CLOSE_TIME] === ALL_HOURS[0]) {
            newValue[OPEN_24_HOURS] = true;
            newValue[OPEN_TIME] = '';
            newValue[CLOSE_TIME] = '';
          }

          return newValue;
        });

        const error = validateLocationHoursOfOperation(editedData);
        if (error == null || error.code === HOURS_OVERLAP_ERROR_CODE) {
          // Only allow hour overlap error
          newHoursOfOperationByLocation[selectedLocation?.value] = {
            ...currentLocationHoursOfOperation,
            newHours: editedData,
            error,
          };
        }

        return newHoursOfOperationByLocation;
      });
    },
    [saveNewHours, selectedLocation, hours],
  );

  const onIsClosedToggledForDay = React.useCallback(
    day => {
      let currentHour;

      if (hasSavedNewHours) {
        currentHour = newHours.find(({ day: d }) => d === day);
      } else {
        const newDayHour = locationHoursOfOperation.newHours.find(({ day: d }) => d === day);
        const currentDayHour = locationHoursOfOperation.current.find(({ day: d }) => d === day);

        // set current open/close time to prevent empty values
        currentHour = {
          ...newDayHour,
          [OPEN_TIME]: currentDayHour[OPEN_TIME],
          [CLOSE_TIME]: currentDayHour[CLOSE_TIME],
        };
      }

      const newHour = { ...currentHour, [IS_CLOSED]: !currentHour[IS_CLOSED] };
      saveNewHours(day, newHour);

      setHoursOfOperationByLocation(prev => {
        const newHoursOfOperationByLocation = { ...prev };
        const currentLocationHoursOfOperation = newHoursOfOperationByLocation[selectedLocation?.value] ?? {
          current: [],
          recommended: [],
          newHours: [],
          error: null,
        };
        const editedData = currentLocationHoursOfOperation.newHours.map(item => {
          if (item.day !== day) {
            return item;
          }

          const newValue = !item[IS_CLOSED];

          return {
            ...item,
            [IS_CLOSED]: newValue,
            [OPEN_TIME]: newValue ? '' : hours[(offset + 3) % hours.length],
            [CLOSE_TIME]: newValue ? '' : hours[(offset + 5) % hours.length],
            day,
          };
        });

        const error = validateLocationHoursOfOperation(editedData);
        if (error == null || error.code === HOURS_OVERLAP_ERROR_CODE) {
          // Only allow hour overlap error
          newHoursOfOperationByLocation[selectedLocation?.value] = {
            ...currentLocationHoursOfOperation,
            newHours: editedData,
            error,
          };
        }

        return newHoursOfOperationByLocation;
      });
    },
    [hasSavedNewHours, saveNewHours, newHours, locationHoursOfOperation, selectedLocation, hours, offset],
  );

  const resetToRecommended = () => {
    deleteNewHours(locationId);
    setHoursOfOperationByLocation(prev => {
      const newHoursOfOperationByLocation = { ...prev };
      const currentLocationHoursOfOperation = newHoursOfOperationByLocation[selectedLocation?.value] ?? {
        current: [],
        recommended: [],
        newHours: [],
        error: null,
      };

      newHoursOfOperationByLocation[selectedLocation?.value] = {
        ...currentLocationHoursOfOperation,
        newHours: currentLocationHoursOfOperation.recommended,
        error: null,
      };

      return newHoursOfOperationByLocation;
    });
    setShouldUpdateHours(false);
  };

  const resetToCurrent = () => {
    deleteNewHours(locationId);
    setHoursOfOperationByLocation(prev => {
      const newHoursOfOperationByLocation = { ...prev };
      const currentLocationHoursOfOperation = newHoursOfOperationByLocation[selectedLocation?.value] ?? {
        current: [],
        recommended: [],
        newHours: [],
        error: null,
      };

      newHoursOfOperationByLocation[selectedLocation?.value] = {
        ...currentLocationHoursOfOperation,
        newHours: currentLocationHoursOfOperation.current,
        error: null,
      };

      return newHoursOfOperationByLocation;
    });
    setShouldUpdateHours(false);
  };

  const { avgHourlyRevenueByDay, overallAvgHourlyRevenue } = useAverageRevenuePerDay(
    locationHoursOfOperation,
    avgPerDayPerHour,
  );

  return {
    avgPerDayPerHour,
    locationHoursOfOperation,
    setLocationHoursOfOperationForDay,
    ...offsetApi,
    resetToRecommended,
    resetToCurrent,
    onIsClosedToggledForDay,
    avgHourlyRevenueByDay,
    overallAvgHourlyRevenue,
  };
};

const CLOSE_THRESHOLD = {
  label: 'Close Threshold',
  color: ERROR,
  dashed: true,
};

const FORECAST_AVG = {
  label: 'Forecast Avg',
  color: LIGHT_BLUE,
};

const LAST_4_WEEKS_AVG = {
  label: 'Last 4 Weeks Avg',
  color: MID_GREEN,
};

const STLY_AVG = {
  label: 'STLY Avg',
  color: LIGHT_COOL_GRAY,
};

const getAvgRevenueForDayAndHour = (avgRevenuePerDayPerHour, dayOfWeek, hourOffset, hourIndex, hour) => {
  const isNextDay = hourOffset + hourIndex >= 24;
  const actualDay = isNextDay ? getNextDayOfWeek(dayOfWeek) : dayOfWeek;

  return avgRevenuePerDayPerHour[actualDay]?.[hour] ?? 0;
};

const getHourIndexForGraph = (
  dayOfWeek,
  locationHoursOfOperation,
  forecastAvgPerDayPerHour,
  last4WeeksAvgByDayByHour,
  stlyAvgByDayByHour,
) => {
  const { [OPEN_TIME]: newOpenTime, [IS_CLOSED]: newIsClosed, [OPEN_24_HOURS]: newIsOpen24 } =
    locationHoursOfOperation.newHours.find(({ day }) => day === dayOfWeek) ?? {};

  const { [OPEN_TIME]: currentOpenTime, [IS_CLOSED]: currentIsClosed, [OPEN_24_HOURS]: currentIsOpen24 } =
    locationHoursOfOperation.current.find(({ day }) => day === dayOfWeek) ?? {};

  let openTime;
  if (currentIsOpen24 || newIsOpen24) {
    // If either are Open 24 Hrs, start with midnight.
    openTime = '00:00';
  } else if (currentIsClosed && newIsClosed) {
    const forecastAvg = forecastAvgPerDayPerHour[dayOfWeek] ?? {};
    const last4WeeksAvg = last4WeeksAvgByDayByHour[dayOfWeek] ?? {};
    const stlyAvg = stlyAvgByDayByHour[dayOfWeek] ?? {};

    const allHoursSet = new Set([...Object.keys(forecastAvg), ...Object.keys(last4WeeksAvg), ...Object.keys(stlyAvg)]);

    const sortedHours = Array.from(allHoursSet).sort((a, b) => {
      const [aHour, aMin] = a.split(':').map(Number);
      const [bHour, bMin] = b.split(':').map(Number);
      return aHour * 60 + aMin - (bHour * 60 + bMin);
    });

    const candidate = sortedHours.find(hour => forecastAvg[hour] > 0 || last4WeeksAvg[hour] > 0 || stlyAvg[hour] > 0);

    // If both are closed start with the earliest hour that has a positive revenue (forecast, last4weeks or stly).
    // If there are no positive revenues, start with midnight
    openTime = candidate || '00:00';
  } else if (currentIsClosed && !newIsClosed) {
    // If current is closed and new is open, use new open time.
    openTime = newOpenTime;
  } else if (!currentIsClosed && newIsClosed) {
    // If new is closed and current is open, use current open time.
    openTime = currentOpenTime;
  } else {
    // Both have open hours; use the earlier open time.
    const toMinutes = timeStr => {
      const [hs, min] = timeStr.split(':').map(Number);
      return hs * 60 + min;
    };
    openTime = toMinutes(newOpenTime) < toMinutes(currentOpenTime) ? newOpenTime : currentOpenTime;
  }

  const hourIndex = ALL_HOURS.indexOf(normalizeToHour(openTime, OPEN_TIME));

  return hourIndex;
};

export const useAverageHourlyRevenueDataForDay = (dayOfWeek, location, period, locationHoursOfOperation) => {
  const { hoursOfOperationSettingsApi } = React.useContext(DashboardContext);
  const { currentHoursSettings } = hoursOfOperationSettingsApi;
  const forecastAvgPerDayPerHour = useForecastAvgPerDayPerHour(location, period);
  const {
    last4WeeksAvgPerDayPerHour: last4WeeksAvgByDayByHour,
    stlyAvgPerDayPerHour: stlyAvgByDayByHour,
  } = useAvgHistoryPerDayPerHour(location, period);

  const [isActiveForecastAvg, setIsActiveForecastAvg] = React.useState(true);
  const [isActiveLast4WeeksAvg, setIsActiveLast4WeeksAvg] = React.useState(true);
  const [isActiveStlyAvg, setIsActiveStlyAvg] = React.useState(true);

  const offsetApi = useHourOffset();
  const { offset, visibleHours, hours, setOffset } = offsetApi;

  React.useEffect(() => {
    const hourIndex = getHourIndexForGraph(
      dayOfWeek,
      locationHoursOfOperation,
      forecastAvgPerDayPerHour,
      last4WeeksAvgByDayByHour,
      stlyAvgByDayByHour,
    );
    if (hourIndex >= 0) {
      setOffset(hourIndex);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayOfWeek, forecastAvgPerDayPerHour, last4WeeksAvgByDayByHour, locationHoursOfOperation, stlyAvgByDayByHour]);

  const { avgHourlyRevenueByDay: forecastAvgRevenueByDay } = useAverageRevenuePerDay(
    locationHoursOfOperation,
    forecastAvgPerDayPerHour,
  );
  const { avgHourlyRevenueByDay: last4WeeksAvgRevenuePerDay } = useAverageRevenuePerDay(
    locationHoursOfOperation,
    last4WeeksAvgByDayByHour,
  );
  const { avgHourlyRevenueByDay: stlyAvgRevenuePerDay } = useAverageRevenuePerDay(
    locationHoursOfOperation,
    stlyAvgByDayByHour,
  );

  const maxRevenue = React.useMemo(() => {
    const everyHourRevenue = [
      currentHoursSettings[HOURS_OF_OPERATION_SETTINGS_KEYS.HOURLY_REVENUE_CLOSE_THRESHOLD] ?? 0,
      ...(isActiveForecastAvg
        ? hours.map((hour, index) => getAvgRevenueForDayAndHour(forecastAvgPerDayPerHour, dayOfWeek, 0, index, hour))
        : []),
      ...(isActiveLast4WeeksAvg
        ? hours.map((hour, index) => getAvgRevenueForDayAndHour(last4WeeksAvgByDayByHour, dayOfWeek, 0, index, hour))
        : []),
      ...(isActiveStlyAvg
        ? hours.map((hour, index) => getAvgRevenueForDayAndHour(stlyAvgByDayByHour, dayOfWeek, 0, index, hour))
        : []),
    ];

    return _.max(everyHourRevenue);
  }, [
    currentHoursSettings,
    forecastAvgPerDayPerHour,
    dayOfWeek,
    hours,
    isActiveForecastAvg,
    isActiveLast4WeeksAvg,
    isActiveStlyAvg,
    last4WeeksAvgByDayByHour,
    stlyAvgByDayByHour,
  ]);

  const [chartData, tableData] = React.useMemo(() => {
    const closeThreshold = currentHoursSettings[HOURS_OF_OPERATION_SETTINGS_KEYS.HOURLY_REVENUE_CLOSE_THRESHOLD] ?? 0;
    const close = {
      ...CLOSE_THRESHOLD,
      data: visibleHours.map((hour, index) => ({
        x: index,
        y: closeThreshold,
      })),
    };
    const forecast = {
      ...FORECAST_AVG,
      data: visibleHours.map((hour, index) => ({
        x: index,
        y: getAvgRevenueForDayAndHour(forecastAvgPerDayPerHour, dayOfWeek, offset, index, hour),
      })),
      newRevenue: forecastAvgRevenueByDay?.newHours?.[dayOfWeek] ?? 0,
      currentRevenue: forecastAvgRevenueByDay?.current?.[dayOfWeek] ?? 0,
      recommendedRevenue: forecastAvgRevenueByDay?.recommended?.[dayOfWeek] ?? 0,
    };
    const last4Weeks = {
      ...LAST_4_WEEKS_AVG,
      data: visibleHours.map((hour, index) => ({
        x: index,
        y: getAvgRevenueForDayAndHour(last4WeeksAvgByDayByHour, dayOfWeek, offset, index, hour),
      })),
      newRevenue: last4WeeksAvgRevenuePerDay?.newHours?.[dayOfWeek] ?? 0,
      currentRevenue: last4WeeksAvgRevenuePerDay?.current?.[dayOfWeek] ?? 0,
      recommendedRevenue: last4WeeksAvgRevenuePerDay?.recommended?.[dayOfWeek] ?? 0,
    };
    const stly = {
      ...STLY_AVG,
      data: visibleHours.map((hour, index) => ({
        x: index,
        y: getAvgRevenueForDayAndHour(stlyAvgByDayByHour, dayOfWeek, offset, index, hour),
      })),
      newRevenue: stlyAvgRevenuePerDay?.newHours?.[dayOfWeek] ?? 0,
      currentRevenue: stlyAvgRevenuePerDay?.current?.[dayOfWeek] ?? 0,
      recommendedRevenue: stlyAvgRevenuePerDay?.recommended?.[dayOfWeek] ?? 0,
    };

    const chart = [];
    const table = [
      { ...forecast, isActive: isActiveForecastAvg, setIsActive: setIsActiveForecastAvg },
      { ...last4Weeks, isActive: isActiveLast4WeeksAvg, setIsActive: setIsActiveLast4WeeksAvg },
    ];

    if (period !== NEXT_CALENDAR_YEAR_PERIOD) {
      table.push({ ...stly, isActive: isActiveStlyAvg, setIsActive: setIsActiveStlyAvg });
    }

    if (isActiveForecastAvg) {
      chart.push(forecast);
    }

    if (isActiveLast4WeeksAvg) {
      chart.push(last4Weeks);
    }

    if (isActiveStlyAvg && period !== NEXT_CALENDAR_YEAR_PERIOD) {
      chart.push(stly);
    }

    chart.push(close);

    return [chart, table];
  }, [
    visibleHours,
    isActiveForecastAvg,
    isActiveLast4WeeksAvg,
    isActiveStlyAvg,
    currentHoursSettings,
    dayOfWeek,
    forecastAvgPerDayPerHour,
    last4WeeksAvgByDayByHour,
    stlyAvgByDayByHour,
    offset,
    period,
    forecastAvgRevenueByDay,
    last4WeeksAvgRevenuePerDay,
    stlyAvgRevenuePerDay,
  ]);

  return {
    chartData,
    tableData,
    last4WeeksAvgRevenuePerDay,
    stlyAvgRevenuePerDay,
    maxRevenue,
    ...offsetApi,
  };
};

export const hoursOfOperationToBarOffsetAndWidth = (hoursOfOperation, hours, hoursOffset, hourColumnWidth) => {
  const emptyBar = { offset: 0, endOffset: 0, width: 0 };

  if (!isValidHoursOfOperation(hoursOfOperation)) {
    return { ...emptyBar };
  }

  const { [OPEN_TIME]: start, [CLOSE_TIME]: end, [OPEN_24_HOURS]: open24, [IS_CLOSED]: isClosed } = hoursOfOperation;

  if (isClosed) {
    return { ...emptyBar };
  }

  if (open24) {
    const startOffset = hoursOffset * hourColumnWidth * -1;
    const width = 24 * hourColumnWidth;

    return {
      offset: startOffset,
      endOffset: startOffset + width,
      width,
    };
  }

  const [startHour, startMinute] = start.split(':');
  const [endHour, endMinute] = end.split(':');

  if (!hours.includes(`${startHour}:00`) || !hours.includes(`${endHour}:00`)) {
    return { ...emptyBar };
  }

  const startIndex = hours.indexOf(`${startHour}:00`);
  let endIndex = hours.indexOf(`${endHour}:00`);

  if (endsOnNextDay(start, end)) {
    endIndex += 24;
  }

  const startOffset = (startIndex - hoursOffset) * hourColumnWidth + hourColumnWidth * (startMinute / 60);
  const endOffset = (endIndex - hoursOffset) * hourColumnWidth + hourColumnWidth * (endMinute / 60);

  return {
    offset: startOffset,
    endOffset,
    width: endOffset - startOffset,
  };
};

export const widthAndOffsetToHours = (bar, hours, hoursOffset, hourColumnWidth) => {
  const { offset, width } = bar;

  let startHourIndex = Math.floor(offset / hourColumnWidth);
  const startMinute = Math.round(((offset % hourColumnWidth) / hourColumnWidth) * 60);
  let endHourIndex = Math.floor((offset + width) / hourColumnWidth);
  const endMinute = Math.round((((offset + width) % hourColumnWidth) / hourColumnWidth) * 60);

  // The start and end hours have to be in 30 minute increments

  let startMinuteAdjusted = 0;
  let endMinuteAdjusted = 0;

  if (startMinute >= 15 && startMinute < 45) {
    startMinuteAdjusted = 30;
  } else if (startMinute >= 45) {
    startHourIndex += 1;
  }

  if (endMinute >= 15 && endMinute < 45) {
    endMinuteAdjusted = 30;
  } else if (endMinute >= 45) {
    endHourIndex += 1;
  }

  const sameDayHours = hours.slice(0, 24);
  const startHour = sameDayHours[(startHourIndex + hoursOffset) % sameDayHours.length].split(':')[0];
  const endHour = sameDayHours[(endHourIndex + hoursOffset) % sameDayHours.length].split(':')[0];

  return {
    start: `${startHour}:${String(startMinuteAdjusted).padStart(2, '0')}`,
    end: `${endHour}:${String(endMinuteAdjusted).padStart(2, '0')}`,
  };
};
