import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Tab } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation, useHistory } from 'react-router-dom';
import AtAGlanceTab from './AtAGlanceTab';
import FiltersMenu, { HoursSettings, InventorySettings, StaffingSettings } from './FiltersMenu';
import FiltersToolbar, {
  HoursSettingsToolbar,
  InventorySettingsToolbar,
  StaffingSettingsToolbar,
} from './FiltersToolbar';
import HoursTab from './HoursTab';
import NavigationMenu from './NavigationMenu';
import NavigationToolbar from './NavigationToolbar';
import InventoryTab from './InventoryTab';
import PerformanceTab from './PerformanceTab';
import PricingTab from './PricingTab';
import { DashboardContext, DomainContext } from './revenueUpliftContexts';
import {
  DashboardContextContainer,
  DomainContextController,
  RevenueUpliftDashboardPage,
} from './RevenueUpliftDashboard';
import { PARAM_TO_TAB, TABS, TAB_TO_LABEL } from './revenueUpliftDashboardTabs';
import { UnexpectedError, isKnownError } from './revenueDashboardErrors';
import StaffingTab from './StaffingTab';
import RetryOrGoToFallback from '../../../generic/errorBoundary/RetryOrGoToFallback';
import GenericLoadingPage from '../../../generic/GenericLoadingPage';
import HeaderAwarePage from '../../../generic/HeaderAwarePage';
import ProfitRoverTabContent from '../../../generic/ProfitRoverTabContent';
import GuidedSetupCompleteModal from '../../../welcome/GuidedSetupCompleteModal';
import { Objective, HOUR_DIMENSION_TYPE } from '../../../workflow/workflowConstants';
import WorkflowProgress from '../../../batchProgress/WorkflowProgress';
import { Can } from '../../../../casl/abilityContext';
import { Action, Subject } from '../../../../casl/caslConstants';
import {
  gaEmitOpenFiltersMenuButtonClick,
  gaEmitTabClick,
  gaEmitUpdateWorkflowGearClick,
} from '../../../../google-analytics/revenueUpliftDashboardV2';
import './revenue-uplift-dashboard-v2.scss';
import {
  FILTERS_MENU,
  HOURS_SETTINGS,
  INVENTORY_SETTINGS,
  NAVIGATION_MENU,
  STAFFING_SETTINGS,
} from './revenueUpliftDashboardMenuConstants';

const WorkflowConfigurationIcon = () => {
  const { workflow } = React.useContext(DomainContext);
  const history = useHistory();

  const openUpdateWorkflow = e => {
    gaEmitUpdateWorkflowGearClick();
    e.stopPropagation();
    history.push('/updateWorkflow', { workflow });
  };

  const openUpdateWorkflowDimensions = e => {
    gaEmitUpdateWorkflowGearClick();
    e.stopPropagation();
    history.push('/updateWorkflowDimension', { workflow });
  };

  if (!workflow) {
    return null;
  }

  if (workflow.objective === Objective.PRICEELASTICITY) {
    return (
      <Can I={Action.EDIT} a={Subject.WORKFLOW}>
        <FontAwesomeIcon className="Icon" name="editClose" icon={faEllipsisH} onClick={openUpdateWorkflow} />
      </Can>
    );
  }
  if (workflow.objective === Objective.PRICEOPT) {
    return (
      <Can I={Action.EDIT} a={Subject.WORKFLOW}>
        <FontAwesomeIcon className="Icon" name="editDimension" icon={faCog} onClick={openUpdateWorkflowDimensions} />
      </Can>
    );
  }

  return null;
};

const RevenueUpliftDashboardLayout = ({
  setPricingScenariosEnabled,
  setStaffingEnabled,
  setHoursEnabled,
  setInventoryEnabled,
  setPerformanceEnabled,
}) => {
  const location = useLocation();

  const { allDimensions, dimensions: workflowDimensions, workflows, batchInProgress, workflowId } = React.useContext(
    DomainContext,
  );
  const { staffingRolesApi, hoursOfOperationSettingsApi } = React.useContext(DashboardContext);
  const { isLoadingStaffingRoles } = staffingRolesApi;
  const { isLoadingHoursSettings } = hoursOfOperationSettingsApi;
  const [selectedTabKey, setSelectedTabKey] = React.useState(TABS.AT_A_GLANCE);
  const [openMenu, setOpenMenu] = React.useState(null);

  const hourDimId = React.useMemo(
    () => workflowDimensions?.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE)?.product_dimension_id,
    [workflowDimensions],
  );

  const enableFunctionByTab = React.useMemo(
    () => ({
      [TABS.PRICING]: () => setPricingScenariosEnabled(true),
      [TABS.STAFFING]: () => setStaffingEnabled(true),
      [TABS.HOURS]: () => setHoursEnabled(true),
      [TABS.AT_A_GLANCE]: () => {
        if (hourDimId) {
          setHoursEnabled(true);
        }
      },
      [TABS.INVENTORY]: () => {
        setPricingScenariosEnabled(true);
        setInventoryEnabled(true);
      },
      [TABS.PERFORMANCE]: () => {
        setPerformanceEnabled(true);
      },
    }),
    [
      hourDimId,
      setHoursEnabled,
      setInventoryEnabled,
      setPerformanceEnabled,
      setPricingScenariosEnabled,
      setStaffingEnabled,
    ],
  );

  const navigateToTab = React.useCallback(
    newTab => {
      if (!Object.values(TABS).includes(newTab)) {
        return;
      }

      if ((newTab === TABS.STAFFING || newTab === TABS.HOURS) && !hourDimId) {
        return;
      }

      enableFunctionByTab[newTab]();
      setSelectedTabKey(newTab);
      gaEmitTabClick(newTab);
    },
    [enableFunctionByTab, hourDimId],
  );

  React.useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tab = urlParams.get('tab');
    const newTab = PARAM_TO_TAB[tab];

    if (newTab) {
      navigateToTab(newTab);
    }
  }, [
    location.search,
    setHoursEnabled,
    setInventoryEnabled,
    setPerformanceEnabled,
    setPricingScenariosEnabled,
    setStaffingEnabled,
    hourDimId,
    navigateToTab,
  ]);

  const setNavigationMenuIsOpen = isOpen => {
    setOpenMenu(isOpen ? NAVIGATION_MENU : null);
  };
  const setFiltersMenuIsOpen = isOpen => {
    setOpenMenu(isOpen ? FILTERS_MENU : null);
  };
  const setStaffingSettingsIsOpen = isOpen => {
    setOpenMenu(isOpen ? STAFFING_SETTINGS : null);
  };
  const setHoursSettingsIsOpen = isOpen => {
    setOpenMenu(isOpen ? HOURS_SETTINGS : null);
  };
  const setInventorySettingsIsOpen = isOpen => {
    setOpenMenu(isOpen ? INVENTORY_SETTINGS : null);
  };

  const openFiltersMenu = () => {
    gaEmitOpenFiltersMenuButtonClick();
    setFiltersMenuIsOpen(true);
  };
  const openStaffingSettings = () => setStaffingSettingsIsOpen(true);
  const openHoursSettings = () => setHoursSettingsIsOpen(true);
  const openNavigationMenu = () => setNavigationMenuIsOpen(true);
  const openInventorySettings = () => setInventorySettingsIsOpen(true);

  React.useEffect(() => {
    if (hourDimId) {
      // enable services to fetch data for widgets (TABS.AT_A_GLANCE)
      setHoursEnabled(true);
      setStaffingEnabled(true);
    }
  }, [setStaffingEnabled, setHoursEnabled, hourDimId]);

  const [
    isActiveAtAGlance,
    isActivePricing,
    isActiveStaffing,
    isActiveHours,
    isActiveInventory,
    isActivePerformance,
  ] = React.useMemo(
    () => [
      selectedTabKey === TABS.AT_A_GLANCE,
      selectedTabKey === TABS.PRICING,
      selectedTabKey === TABS.STAFFING,
      selectedTabKey === TABS.HOURS,
      selectedTabKey === TABS.INVENTORY,
      selectedTabKey === TABS.PERFORMANCE,
    ],
    [selectedTabKey],
  );

  return (
    <HeaderAwarePage scrollable={false}>
      <Tab.Container activeKey={selectedTabKey} onSelect={navigateToTab}>
        <ProfitRoverTabContent className="profitrover-dashboard">
          <div className="toolbar flex-nowrap">
            <NavigationToolbar openNavigationMenu={openNavigationMenu} label={TAB_TO_LABEL[selectedTabKey]} />
            <div className="spacer" />
            <div className="filters-container">
              {!isLoadingStaffingRoles && (
                <StaffingSettingsToolbar openStaffingSettings={openStaffingSettings} activeTabId={selectedTabKey} />
              )}
              {!isLoadingHoursSettings && (
                <HoursSettingsToolbar openHoursSettings={openHoursSettings} activeTabId={selectedTabKey} />
              )}
              <InventorySettingsToolbar openInventorySettings={openInventorySettings} activeTabId={selectedTabKey} />
              <FiltersToolbar
                openFiltersMenu={openFiltersMenu}
                activeTabId={selectedTabKey}
                activeTabLabel={TAB_TO_LABEL[selectedTabKey]}
              />
            </div>

            <WorkflowProgress batch={batchInProgress} workflowId={workflowId} />
            <div className="d-flex align-items-center ml-2">
              <WorkflowConfigurationIcon />
            </div>
          </div>

          <Tab.Pane eventKey={TABS.AT_A_GLANCE} unmountOnExit className="at-a-glance-pane">
            <AtAGlanceTab
              showPricingTab={() => navigateToTab(TABS.PRICING)}
              showHoursTab={() => navigateToTab(TABS.HOURS)}
              showStaffingTab={() => navigateToTab(TABS.STAFFING)}
              isActive={isActiveAtAGlance}
            />
          </Tab.Pane>
          <Tab.Pane eventKey={TABS.PRICING} unmountOnExit className="pricing-pane px-3">
            <PricingTab isActive={isActivePricing} openFiltersMenu={openFiltersMenu} />
          </Tab.Pane>
          {hourDimId && (
            <Tab.Pane eventKey={TABS.STAFFING} unmountOnExit className="staffing-pane px-3">
              <StaffingTab isActive={isActiveStaffing} openFiltersMenu={openFiltersMenu} />
            </Tab.Pane>
          )}
          {hourDimId && (
            <Tab.Pane eventKey={TABS.HOURS} unmountOnExit className="hours-pane px-3">
              <HoursTab isActive={isActiveHours} openFiltersMenu={openFiltersMenu} />
            </Tab.Pane>
          )}
          <Tab.Pane eventKey={TABS.INVENTORY} unmountOnExit className="inventory-pane px-3">
            <InventoryTab isActive={isActiveInventory} />
          </Tab.Pane>
          <Tab.Pane eventKey={TABS.PERFORMANCE} unmountOnExit className="performance-pane px-3">
            <PerformanceTab isActive={isActivePerformance} />
          </Tab.Pane>

          <FiltersMenu
            isOpen={openMenu === FILTERS_MENU}
            setIsOpen={setFiltersMenuIsOpen}
            activeTabId={selectedTabKey}
            activeTabLabel={TAB_TO_LABEL[selectedTabKey]}
          />

          <NavigationMenu
            isOpen={openMenu === NAVIGATION_MENU}
            setIsOpen={setNavigationMenuIsOpen}
            dimensions={allDimensions}
            workflows={workflows}
            setTabKey={navigateToTab}
          />
          <StaffingSettings isOpen={openMenu === STAFFING_SETTINGS} setIsOpen={setStaffingSettingsIsOpen} />
          <HoursSettings isOpen={openMenu === HOURS_SETTINGS} setIsOpen={setHoursSettingsIsOpen} />
          <InventorySettings isOpen={openMenu === INVENTORY_SETTINGS} setIsOpen={setInventorySettingsIsOpen} />
        </ProfitRoverTabContent>
      </Tab.Container>
    </HeaderAwarePage>
  );
};

const UnexpectedPageCrashError = new UnexpectedError();

const RevenueUpliftDashboardV2 = props => {
  const { location } = props;
  const { showCompletedGuidedSetupModal = false } = location?.state ?? {};

  const [showSetupCompletionModal, setShowCompletionModal] = React.useState(showCompletedGuidedSetupModal);
  const history = useHistory();

  return (
    <RevenueUpliftDashboardPage {...props}>
      {({ workflowId, setWorkflowTitle }) => (
        <>
          {showSetupCompletionModal && (
            <GuidedSetupCompleteModal
              onClose={() => {
                setShowCompletionModal(false);
                // Prevents confetti from replaying
                history.replace({ pathname: location.pathname, state: { showCompletedGuidedSetupModal: false } });
              }}
            />
          )}
          <ErrorBoundary
            fallbackRender={({ error, ...renderProps }) => (
              <RetryOrGoToFallback {...renderProps} error={isKnownError(error) ? error : UnexpectedPageCrashError} />
            )}
          >
            <DomainContextController
              workflowId={workflowId}
              Loading={GenericLoadingPage}
              setWorkflowTitle={setWorkflowTitle}
            >
              <DashboardContextContainer workflowId={workflowId}>
                {({
                  setPricingScenariosEnabled,
                  setStaffingEnabled,
                  setHoursEnabled,
                  setInventoryEnabled,
                  setPerformanceEnabled,
                }) => (
                  <RevenueUpliftDashboardLayout
                    setPricingScenariosEnabled={setPricingScenariosEnabled}
                    setStaffingEnabled={setStaffingEnabled}
                    setHoursEnabled={setHoursEnabled}
                    setInventoryEnabled={setInventoryEnabled}
                    setPerformanceEnabled={setPerformanceEnabled}
                    {...props}
                  />
                )}
              </DashboardContextContainer>
            </DomainContextController>
          </ErrorBoundary>
        </>
      )}
    </RevenueUpliftDashboardPage>
  );
};

export default RevenueUpliftDashboardV2;
